export interface SuccessResponse {
  state: string;
}

export interface ErrorResponse {
  state: string;
  message: string;
}

export interface ResetPasswordSuccessResponse extends SuccessResponse {
  expires_in: number;
}

export interface RefreshUserTokenSuccessResponse extends SuccessResponse {
  expires_in: number;
}

export type RefreshUserTokenErrorResponse = ErrorResponse;

export type ResetPasswordErrorResponse = ErrorResponse;

export type UserMatchSuccessResponse = SuccessResponse;

export type UserMatchErrorResponse = ErrorResponse;
