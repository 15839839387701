import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginRoutesEnum } from '@athome-myaccount/my-account/util-login';
import { FormsService } from '../services/forms.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  public form: FormGroup;
  public pageValues: any;
  public pageItems: any;
  public useValidation = false;

  @Input() data: any;
  @Output() formData: EventEmitter<any> = new EventEmitter<FormGroup>();
  @Output()
  customButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private formsService: FormsService, private router: Router) {}

  createForm(pageItems, pageValues) {
    this.form = new FormGroup(
      this.formsService.defineformControls(pageItems, pageValues)
    );
  }

  onCreateForm() {
    this.pageItems = this.data.meta;
    this.pageValues = this.data.formValues || {};
    this.createForm(this.pageItems, this.pageValues);
  }

  ngOnInit() {
    this.onCreateForm();
  }

  /**
   * return form and set default angular validation
   */
  onSubmit() {
    this.formData.emit(this.form);
    this.useValidation = true;
  }

  onForgotYourPasswordClick(): void {
    this.router.navigateByUrl(LoginRoutesEnum.FORGOT_PASSWORD_PAGE);
  }
}
