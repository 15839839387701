<div mat-dialog-action align="end" class="icon-end">
  <span [mat-dialog-close]="true" class="icon-close-modal">
    <svg class="icon icon-close">
      <use xlink:href="#icon-close"></use>
    </svg>
  </span>
</div>

<h1 mat-dialog-title>{{ 'Unsupported_Browser_Modal.header' | translate }}</h1>
<div
  mat-dialog-content
  [ngClass.xs]="'modal-wrapper-mobile'"
  [ngClass.gt-xs]="'modal-wrapper'"
>
  <div
    [ngClass.xs]="'modal-inner-wrapper-mobile'"
    [ngClass.gt-xs]="'modal-inner-wrapper-tablet'"
    [ngClass.gt-md]="'modal-inner-wrapper-desktop'"
  >
    <div fxLayout="row wrap">
      <div class="content-top">
        {{ 'Unsupported_Browser_Modal.body' | translate }}
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlexAlign="center" fxFlex="100">
        <div
          class="google-svg"
          [ngClass.xs]="'svg-size-mobile'"
          [ngClass.gt-xs]="'svg-size'"
        ></div>
        <div
          class="item-name"
          [ngClass.xs]="'item-name-mobile'"
          [ngClass.gt-xs]="'item-name-tablet'"
          [ngClass.gt-md]="'item-name-desktop'"
        >
          {{ 'Unsupported_Browser_Modal.browser_name_1' | translate }}
        </div>
      </div>
      <div fxFlexAlign="center" fxFlex="100">
        <div
          class="microsoft-svg"
          [ngClass.xs]="'svg-size-mobile'"
          [ngClass.gt-xs]="'svg-size'"
        ></div>
        <div
          class="item-name"
          [ngClass.xs]="'item-name-mobile'"
          [ngClass.gt-xs]="'item-name-tablet'"
          [ngClass.gt-md]="'item-name-desktop'"
        >
          {{ 'Unsupported_Browser_Modal.browser_name_2' | translate }}
        </div>
      </div>
      <div fxFlexAlign="center" fxFlex="100">
        <div
          class="apple-svg"
          [ngClass.xs]="'svg-size-mobile'"
          [ngClass.gt-xs]="'svg-size'"
        ></div>
        <div
          class="item-name"
          [ngClass.xs]="'item-name-mobile'"
          [ngClass.gt-xs]="'item-name-tablet'"
          [ngClass.gt-md]="'item-name-desktop'"
        >
          {{ 'Unsupported_Browser_Modal.browser_name_3' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
