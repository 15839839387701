<fieldset
  [attr.validate]="validate"
  [class]="classes"
  [attr.data]="data"
  [attr.group]="group"
>
  <ng-container *ngFor="let item of data">
    <app-form-elements
      [validate]="validate"
      [group]="group"
      [data]="item"
    ></app-form-elements>
  </ng-container>
  <ng-content></ng-content>
</fieldset>
