import { Injectable } from '@angular/core';
import {
  ModalDialogService,
  OpenUnsupportedModal,
} from '@athome-myaccount/my-account/util-core';

@Injectable({
  providedIn: 'root',
})
export class UnsupportedBrowserService {
  constructor(private modalService: ModalDialogService) {}

  setUnsupportedSessionStorage(): boolean {
    if (sessionStorage.unsupportedBrowserModalShown === undefined) {
      sessionStorage.setItem('unsupportedBrowserModalShown', 'true');
      return true;
    } else {
      return false;
    }
  }

  checkIfBrowserSupported(): void {
    const supportedBrowsers = ['Chrome', 'Safari', 'Edge'];
    const userBrowser = this.checkUserBrowser();

    if (!supportedBrowsers.includes(userBrowser)) {
      this.handleUnsupportedBrowser();
    }
  }

  checkUserBrowser(): string {
    if (
      navigator.userAgent.indexOf('Chrome') !== -1 &&
      !(navigator.userAgent.indexOf('YaBrowser') !== -1) &&
      !(navigator.userAgent.indexOf('OPR') !== -1)
    ) {
      return 'Chrome';
    } else if (
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Version') !== -1
    ) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Edge') !== -1) {
      return 'Edge';
    } else {
      return 'unknown';
    }
  }

  handleUnsupportedBrowser(): void {
    const sessionUnsupported = this.setUnsupportedSessionStorage();
    if (sessionUnsupported) {
      this.modalService.openModal(new OpenUnsupportedModal());
    }
  }
}
