import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import {
  RequestVerificationCodePayload,
  VerificationCodePayload,
} from '../common/models/payload';
import { UserMatchPayload } from '../common/models/payload';
import { User } from '../common/models/user';

export enum AuthLibActionTypes {
  LogIn = '[AuthLib Login Component] User Tries To Log In',
  LogInSuccess = '[AuthLib Login Component] User Log In Success',
  LogInFailure = '[AuthLib Login Component] User Log In Failure',

  CreateAccount = '[AuthLib CreateAccount Component] User Creates Account',
  CreateAccountSuccess = '[AuthLib CreateAccount Component] User Create Account Success',
  CreateAccountFailure = '[AuthLib CreateAccount Component] User Create Account Failure',
  CreateAccountClear = '[AuthLib CreateAccount Component] Clear Create Account Response',

  ForgotPassword = '[AuthLib ForgotPassword Component] User Requests Forgot Password',
  ForgotPasswordSuccess = '[AuthLib ForgotPassword Component] User Requests Forgot Password Success',
  ForgotPasswordFailure = '[AuthLib ForgotPassword Component] User Requests Forgot Password Failure',
  ForgotPasswordClear = '[AuthLib ForgotPassword Component] Clear Forgot Password Response',

  RequestVerificationCode = '[AuthLib RequestVerificationCode Component] User Requests New Verification Code',
  RequestVerificationCodeSuccess = '[AuthLib RequestVerificationCode Component] User Requests New Verification Code Success',
  RequestVerificationCodeFailure = '[AuthLib RequestVerificationCode Component] User Requests New Verification Code Failure',

  VerificationCode = '[AuthLib VerificationCode Component] User Requests Email Verification',
  VerificationCodeSuccess = '[AuthLib VerificationCode Component] User Requests Email Verification Success',
  VerificationCodeFailure = '[AuthLib VerificationCode Component] User Requests Email Verification Failure',

  ResetPassword = '[AuthLib ResetPassword Component] User Submits Reset Password Form',
  ResetPasswordSuccess = '[AuthLib ResetPassword Component] User Submits Reset Password Form Success',
  ResetPasswordFailure = '[AuthLib ResetPassword Component] User Submits Reset Password Form Failure',

  SignOut = '[AuthLib GLOBAL] User Signs Out',
  SignOutSuccess = '[AuthLib GLOBAL] User Signs Out Success',
  SignOutFailure = '[AuthLib GLOBAL] User Sign Out Failure',

  CheckUserSession = '[AuthLib GLOBAL] Checking If User Session Is Still Active',
  CheckUserSessionSuccess = '[AuthLib GLOBAL] Check User Session Success',
  CheckUserSessionFailure = '[AuthLib GLOBAL] Check User Session Failure',

  UserSessionExpired = '[AuthLib GLOBAL] User Session Expired',
  UserSessionActive = '[AuthLib GLOBAL] User Session Active',
  LogOut = '[AuthLib] User Logs Out',

  VerifyAddress = '[AuthLib] VerifyAddress',
  VerifyAddressSuccess = '[AuthLib] VerifyAddress Success',
  VerifyAddressFailure = '[AuthLib] VerifyAddress Failure',
  VerifyAddressClear = '[AuthLib] VerifyAddress Clear',

  RefreshUserToken = '[AuthLib] RefreshUserToken',
  RefreshUserTokenSuccess = '[AuthLib] RefreshUserToken Success',
  RefreshUserTokenFailure = '[AuthLib] RefreshUserToken Failure',

  ResetAuthState = '[AuthLib] Reset Auth State',

  ResetApiResponseState = '[AuthLib GLOBAL] Component Destruction Mandatory State Reset',
}

export class LogIn implements Action {
  readonly type = AuthLibActionTypes.LogIn;
  constructor(public payload: { email: string; password: string }) {}
}

export class LogInSuccess implements Action {
  readonly type = AuthLibActionTypes.LogInSuccess;
  constructor(public payload: User) {}
}

export class LogInFailure implements Action {
  readonly type = AuthLibActionTypes.LogInFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class ForgotPassword implements Action {
  readonly type = AuthLibActionTypes.ForgotPassword;
  constructor(public payload: { email: string; is_sign_up: boolean }) {}
}

export class ForgotPasswordSuccess implements Action {
  readonly type = AuthLibActionTypes.ForgotPasswordSuccess;
}

export class ForgotPasswordClear implements Action {
  readonly type = AuthLibActionTypes.ForgotPasswordClear;
}

export class ForgotPasswordFailure implements Action {
  readonly type = AuthLibActionTypes.ForgotPasswordFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class RequestVerificationCode implements Action {
  readonly type = AuthLibActionTypes.RequestVerificationCode;
  constructor(public payload: RequestVerificationCodePayload) {}
}

export class RequestVerificationCodeSuccess implements Action {
  readonly type = AuthLibActionTypes.RequestVerificationCodeSuccess;
}

export class RequestVerificationCodeFailure implements Action {
  readonly type = AuthLibActionTypes.RequestVerificationCodeFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class VerificationCode implements Action {
  readonly type = AuthLibActionTypes.VerificationCode;
  constructor(public payload: VerificationCodePayload) {}
}

export class VerificationCodeSuccess implements Action {
  readonly type = AuthLibActionTypes.VerificationCodeSuccess;
}

export class VerificationCodeFailure implements Action {
  readonly type = AuthLibActionTypes.VerificationCodeFailure;
  constructor(public payload: HttpErrorResponse) {}
}
export class ResetPassword implements Action {
  readonly type = AuthLibActionTypes.ResetPassword;
  constructor(
    public payload: {
      email: string;
      confirmation_code: string;
      is_sign_up: boolean;
      password?: string;
    }
  ) {}
}

export class ResetAuthState implements Action {
  readonly type = AuthLibActionTypes.ResetAuthState;
}
export class CreateAccount implements Action {
  readonly type = AuthLibActionTypes.CreateAccount;
  constructor(
    public payload: {
      name: string;
      surname: string;
      email: string;
      password: string;
    }
  ) {}
}

export class RefreshUserToken implements Action {
  readonly type = AuthLibActionTypes.RefreshUserToken;
  constructor(
    public payload: {
      refresh_token: string;
    }
  ) {}
}

export class ResetPasswordSuccess implements Action {
  readonly type = AuthLibActionTypes.ResetPasswordSuccess;
}

export class ResetPasswordFailure implements Action {
  readonly type = AuthLibActionTypes.ResetPasswordFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class RefreshUserTokenSuccess implements Action {
  readonly type = AuthLibActionTypes.RefreshUserTokenSuccess;
}

export class RefreshUserTokenFailure implements Action {
  readonly type = AuthLibActionTypes.RefreshUserTokenFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CreateAccountSuccess implements Action {
  readonly type = AuthLibActionTypes.CreateAccountSuccess;
}

export class CreateAccountFailure implements Action {
  readonly type = AuthLibActionTypes.CreateAccountFailure;
  constructor(public payload: HttpErrorResponse) {}
}
export class CreateAccountClear implements Action {
  readonly type = AuthLibActionTypes.CreateAccountClear;
}

export class LogOut implements Action {
  readonly type = AuthLibActionTypes.LogOut;
}

export class SignOut implements Action {
  readonly type = AuthLibActionTypes.SignOut;
}

export class SignOutSuccess implements Action {
  readonly type = AuthLibActionTypes.SignOutSuccess;
}

export class SignOutFailure implements Action {
  readonly type = AuthLibActionTypes.SignOutFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class CheckUserSession implements Action {
  readonly type = AuthLibActionTypes.CheckUserSession;
}

export class CheckUserSessionSuccess implements Action {
  readonly type = AuthLibActionTypes.CheckUserSessionSuccess;
}

export class CheckUserSessionFailure implements Action {
  readonly type = AuthLibActionTypes.CheckUserSessionFailure;
  constructor(public payload: HttpErrorResponse) {}
}

export class UserSessionExpired implements Action {
  readonly type = AuthLibActionTypes.UserSessionExpired;
}

export class UserSessionActive implements Action {
  readonly type = AuthLibActionTypes.UserSessionActive;
}

export class ResetApiResponseState implements Action {
  readonly type = AuthLibActionTypes.ResetApiResponseState;
}
export class VerifyAddress implements Action {
  readonly type = AuthLibActionTypes.VerifyAddress;
  constructor(
    public payload: {
      userMatch: UserMatchPayload;
    }
  ) {}
}
export class VerifyAddressSuccess implements Action {
  readonly type = AuthLibActionTypes.VerifyAddressSuccess;
}
export class VerifyAddressFailure implements Action {
  readonly type = AuthLibActionTypes.VerifyAddressFailure;
  constructor(public payload: HttpErrorResponse) {}
}
export class VerifyAddressClear implements Action {
  readonly type = AuthLibActionTypes.VerifyAddressClear;
}

export type Actions =
  | LogIn
  | LogInSuccess
  | LogInFailure
  | CreateAccount
  | CreateAccountSuccess
  | CreateAccountFailure
  | CreateAccountClear
  | ForgotPassword
  | ForgotPasswordSuccess
  | ForgotPasswordFailure
  | ForgotPasswordClear
  | RequestVerificationCode
  | RequestVerificationCodeSuccess
  | RequestVerificationCodeFailure
  | VerificationCode
  | VerificationCodeSuccess
  | VerificationCodeFailure
  | ResetPassword
  | ResetAuthState
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | RefreshUserToken
  | RefreshUserTokenSuccess
  | RefreshUserTokenFailure
  | LogOut
  | SignOut
  | SignOutSuccess
  | SignOutFailure
  | CheckUserSession
  | CheckUserSessionSuccess
  | CheckUserSessionFailure
  | UserSessionExpired
  | UserSessionActive
  | ResetApiResponseState
  | VerifyAddress
  | VerifyAddressSuccess
  | VerifyAddressFailure
  | VerifyAddressClear;
