import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';

export const selectUserState =
  createFeatureSelector<fromUser.UserState>('user');

export const selectAuthenticationResponse = createSelector(
  selectUserState,
  (userState) => userState.userAuthenticationResponse
);

export const selectLoginFormCredentials = createSelector(
  selectUserState,
  (userState) => userState.loginFormCredentials
);

export const selectCreateNewAccountFormCredentials = createSelector(
  selectUserState,
  (userState) => userState.createNewAccountFormCredentials
);

export const selectUserAddressMatchCredentials = createSelector(
  selectUserState,
  (userState) => userState.userMatch
);

export const selectLoadingState = createSelector(
  selectUserState,
  (userState) => userState.loading
);

export const selectApplicationUser = createSelector(
  selectUserState,
  (userState) => userState.user
);

export const selectVerifyAddressResponse = createSelector(
  selectUserState,
  (userState) => userState.verifyAddressResponse
);

export const selectVerifyPlanNumberResponse = createSelector(
  selectUserState,
  (userState) => userState.verifyPlanNumberResponse
);

export const selectVerificationCompleteStatus = createSelector(
  selectUserState,
  (userState) => userState.isUserVerificationComplete
);

export const selectVerifyUserEmailInboxResponse = createSelector(
  selectUserState,
  (userState) => userState.verifyUserEmailInboxResponse
);

export const selectVerificationCodeResponse = createSelector(
  selectUserState,
  (userState) => userState.requestVerificationCodeResponse
);

export const selectForgotPasswordResponse = createSelector(
  selectUserState,
  (userState) => userState.requestForgotPasswordResponse
);

export const selectPlanNumberVerificationResponse = createSelector(
  selectUserState,
  (userState) => userState.verifyPlanNumberResponse
);

export const selectResetPasswordResponse = createSelector(
  selectUserState,
  (userState) => userState.resetPasswordResponse
);

export const selectCreateNewAccountResponse = createSelector(
  selectUserState,
  (userState) => userState.createNewAccountResponse
);

export const isUserLoggedIn = createSelector(
  selectUserState,
  (userState) => userState.loggedIn
);

export const isUserLoggedOut = createSelector(
  isUserLoggedIn,
  (loggedIn) => !loggedIn
);

export const selectMyDetailsEditSectionsState = createSelector(
  selectUserState,
  (userState) => userState.myDetailsEditSectionsState
);

export const selectPersonalDetails = createSelector(
  selectUserState,
  (userState) => userState.personalDetails
);

export const selectSignUpAndMatchPayload = createSelector(
  selectUserState,
  (userState) => userState.signUpAndMatchPayload
);

export const selectSignUpAttempts = createSelector(
  selectUserState,
  (userState) => userState.signUpAttempts
);
