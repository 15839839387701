import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AuthLibFacade } from './+state/auth.facade';
import { authReducer } from './+state/auth.reducer';
import { UserAuthGuard } from './common/guards/auth.guard';
import { AuthenticationApiService } from './services/authentication-api.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('auth', authReducer)],
  providers: [UserAuthGuard, AuthenticationApiService, AuthLibFacade],
})
export class SharedFeatureAuthenticationNativescriptModule {}
