import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ControlType,
  DynamicFormbuilderService,
  FieldDef,
  FormGroupTyped,
  SubmitDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, skipWhile, takeWhile } from 'rxjs/operators';
import { AuthLibFacade } from '../../+state/auth.facade';
import { ApiResponseTypeEnum } from '../../common/enums/api-response-type.enum';
import { ResetPasswordPayload } from '../../common/models/payload';
interface ResetFormType {
  password: string;
}
@Component({
  selector: 'athome-myaccount-dfb-password-reset',
  templateUrl: './dfb-password-reset.component.html',
  styleUrls: ['./dfb-password-reset.component.scss'],
})
export class DfbPasswordResetComponent implements OnInit, OnDestroy {
  showValidation = false;
  isComponentAlive = true;
  passwordResetForm: FormGroup;
  fieldTextType: boolean;
  submitted = false;
  public confirmationCode: string;
  public customerEmail: string;
  public payload: ResetPasswordPayload;
  public resetPasswordResponse$: Observable<any> =
    this.facade.resetPasswordResponse$;
  loginResponse$: Observable<ApiResponseTypeEnum> = this.facade.logInResponse$;
  @Output() resetPasswordResponseSuccess = new EventEmitter();
  @Output() resetPasswordResponseError = new EventEmitter<any>();
  @Output() formValueChange = new EventEmitter<any>();
  passwordError: any;
  passwordValue: string;
  passwordStrengthChecker = {
    showLength: true,
    showUppercase: true,
    showLowercase: true,
    showNumber: true,
    showComplex: true,
  };

  myFormGroup!: FormGroupTyped<ResetFormType>;

  readonly submitDef: SubmitDef = {
    controlType: ControlType.SUBMIT,
    label: { text: 'Continue' },
    classes: 'btn btn--primary',
    excludeFromFormGroup: true, // required so Form Group Control is not created
    disabled: false,
  };

  readonly fieldset: FieldDef[] = [
    {
      controlType: ControlType.PASSWORD,
      controlName: 'password',
      label: { text: 'New Password' },
      validators: [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,128}$'),
      ],
      validationMessages: [
        { type: 'required', message: 'Password is required' },
        { type: 'pattern', message: 'Does not match the password pattern' },
      ],
      placeholder: 'Enter your new password here',
    },
    this.submitDef,
  ];

  constructor(
    public fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private facade: AuthLibFacade,
    private dfb: DynamicFormbuilderService
  ) {}

  ngOnInit() {
    this.onQueryParams();
    this.onLoginResponse();

    this.onResetPasswordResponse();

    this.myFormGroup = <FormGroupTyped<ResetFormType>>(
      this.dfb.generateFormGroup(this.fieldset)
    );
  }

  handleValidFormSubmission(formData: ResetFormType) {
    this.submitted = true;

    const resetPasswordPayload: ResetPasswordPayload = (this.payload = {
      password: formData.password,
      ...this.payload,
    });

    this.facade.requestResetPasswordForUser({
      ...resetPasswordPayload,
    });
  }

  onResetPasswordResponse(): void {
    combineLatest([this.resetPasswordResponse$, this.facade.error$])
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .pipe(
        skipWhile(
          ([responseStatus, error]: [ApiResponseTypeEnum, Error]) =>
            responseStatus === ApiResponseTypeEnum.NOT_CALLED
        )
      )
      .subscribe(([responseStatus, error]: [ApiResponseTypeEnum, Error]) => {
        if (responseStatus === ApiResponseTypeEnum.SUCCESS) {
          this.facade.resetAuthState();
          this.facade.logInUser({
            email: this.payload.email,
            password: this.payload.password,
          });
        }
        if (responseStatus === ApiResponseTypeEnum.ERROR && error) {
          this.resetPasswordResponseError.emit(error);
        }
      });
  }

  onLoginResponse(): void {
    combineLatest([this.loginResponse$, this.facade.error$])
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .pipe(
        skipWhile(
          ([responseStatus, error]: [ApiResponseTypeEnum, Error]) =>
            responseStatus === ApiResponseTypeEnum.NOT_CALLED
        )
      )
      .subscribe(([responseStatus, error]: [ApiResponseTypeEnum, Error]) => {
        if (responseStatus === ApiResponseTypeEnum.SUCCESS) {
          this.resetPasswordResponseSuccess.emit();
        }
        if (responseStatus === ApiResponseTypeEnum.ERROR && error) {
          this.resetPasswordResponseError.emit(error);
        }
      });
  }

  onQueryParams(): void {
    this._route.queryParams
      .pipe(takeWhile(() => this.isComponentAlive))
      .subscribe((params) => {
        this.payload = {
          email: params.email,
          confirmation_code: params.confirmation_code,
          is_sign_up: params.is_sign_up?.toLowerCase() === 'true',
        };
      });
  }

  ngOnDestroy(): void {
    this.isComponentAlive = false;
  }
}
