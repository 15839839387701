import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';

@Component({
  selector: 'app-validation-error-formcontrol',
  templateUrl: './validation-error-formcontrol.component.html',
})
export class ValidationErrorFormControlComponent {
  @Input() group: FormGroup;
  @Input() control;
  @Input() validators;

  error: any;

  constructor(private _analytics: AnalyticsService) {}

  getFieldError(control) {
    if (this.group.get(control).errors) {
      const error = Object.keys(this.group.get(control).errors)[0];
      this.error = error;

      if (error !== this.error) {
        this.triggerGA(error);
      }

      return Object.keys(this.group.get(control).errors)[0];
    }
  }
  // TODO: FIX AND REFACTOR
  triggerGA(error: any) {
    this._analytics.trigger('ClaimsGenericGAEvent', {
      eventClaims: 'validation-error',
      eventCategory: 'claimsRequest-dgx',
      eventAction: 'validation-error-dgx',
      eventLabel: error, // Short form of the error text (eg 'insert phone number', 'insert model number', 'answer all questions', etc)
    });
  }
}
