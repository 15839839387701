export enum AccountApiType {
  GetPlans = '/get-all-plans', // WL: Dev tested
  GetPlanDetail = '/get-plan', // WL: Dev tested
  CancelClaim = '/cancel-claim', // TODO WL: Needs testing
  ResendEmail = '/email-plan-document', // TODO WL: Needs testing
  ClaimBundle = '/start-claim', // WL: Dev tested
  RebookClaimBundle = '/rebook-claim', // TODO WL: Needs testing
  ReportIssue = '/plans/issuereports', // TODO WL: which one is this? Seems to be missing
  SearchDocumentKey = '/search-plan-document', // TODO WL: Needs testing
  DownloadPlanDocument = '/plans/{planNumber}/plan-document/download',
  UpdateAutoRenewal = 'update-auto-renewal',
}
