import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-radiobutton',
  templateUrl: './radiobutton.component.html',
})
export class RadiobuttonComponent implements AfterViewInit {
  @Input() group: FormGroup;
  @Input() idPrefix = 'rb';
  @Input() name = 'rb';
  @Input() items;
  @Input() classes;
  @Input() control;
  @Input() validate;
  @Input() showValidation;
  @Input() validationError;
  @Input() validators;
  @Input() elementsPerPage = 2;
  @Input() test;

  Xpos = 0;
  step: number;
  slides = [];
  resizeTimeout;
  active = 0;

  public rambOn: null | number = null; // set mousedown / touchstart (user interaction - enter)
  public rambOff: null | number = null; // set mouseup / touchend (user interaction - exit)
  public radioHint: boolean | string = false;

  constructor(public renderer: Renderer2, private cdr: ChangeDetectorRef) {}

  @ViewChild('page', { static: false }) page: ElementRef;
  @ViewChild('slider', { static: false }) slider: ElementRef;

  @HostListener('window:resize')
  onWindowResize() {
    // listen for resize and check checkIfMobile
    // debounce resize, wait for resize to finish before doing stuff - delay of 100
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        this.checkIfMobile(this.slider.nativeElement);
      }).bind(this),
      100
    );
  }

  /**
   * ngAfterViewInit
   * Checking if it is mobile device
   */
  ngAfterViewInit() {
    this.checkIfMobile(this.slider.nativeElement);
    this.cdr.detectChanges();
  }

  /**
   * setActiveSlide
   * @param slide
   * Removing all previews selections
   * Getting active slide number and applying class 'active' to active slide
   */
  setActiveSlide(slide: number) {
    const slideBullets = this.page.nativeElement.querySelectorAll('li');
    for (const el of slideBullets) {
      this.renderer.removeClass(el, 'active');
    }
    if (slideBullets[slide] !== null) {
      this.renderer.addClass(slideBullets[slide], 'active');
    }
  }

  /**
   * createSlider
   * Initializing Slider if mobile device detected
   * Splitting data by slides based on elementsPerPage variable
   */
  createSlider() {
    let pages = this.items.length / this.elementsPerPage;
    if (this.items.length % this.elementsPerPage === 0) {
      pages--;
    } else {
      // eslint-disable-next-line no-bitwise
      pages = ~~pages;
    }
    for (let page = 0; page <= pages; page++) {
      this.makeSlide(page);
    }
    return true;
  }

  /**
   * makeSlide
   * @param page
   * Creating slide with number of items and pushing to slides array to render
   */
  makeSlide(page: number) {
    const indexMin = page * this.elementsPerPage;
    const indexMax = indexMin + this.elementsPerPage;
    this.slides.push(
      this.items.filter((x, index) => index >= indexMin && index < indexMax)
    );
  }

  /**
   * resetSlider
   * @param tag
   * Disabling and Resetting slider to desktop view
   */
  resetSlider(tag) {
    this.slides.length = 0;
    this.slides[0] = this.items;
    tag.removeAttribute('style', 'true');
  }

  /**
   * checkIfMobile
   * @param tag
   * Reading 'content' css property which define mobile or desktop device type
   * Detection based on CSS media query breakpoint
   * Initialize Mobile or Desktop view
   */
  checkIfMobile(tag) {
    this.slides = [];

    if (tag !== null) {
      const style = window.getComputedStyle(tag);
      const content = style.getPropertyValue('content');
      const state = content.replace(/["']/g, ''); // removing all quotes,
      if (state === 'mobile') {
        // If items less than 1 just show one radio button in the middle

        if (this.items.length <= 1) {
          this.resetSlider(tag);
        } else {
          this.createSlider();
          this.setCarouselWidth();
          setTimeout(() => {
            this.setActiveSlide(this.active);
          }, 100);
        }
      } else {
        // Reset data to desktop mode if switched from mobile back to desktop

        this.resetSlider(tag);
      }
      return state;
    }
  }

  /**
   * setCarouselWidth
   * Setting Slider width based on number of slides calculated in percent
   * Setting step to scroll in percent
   */
  setCarouselWidth() {
    this.slider.nativeElement.style.width = 100 * this.slides.length + '%';
    this.step = 100 / this.slides.length;
  }

  /**
   * unify the touch and click cases - conditional returns different object
   */
  public unify(event: any) {
    return event.changedTouches ? event.changedTouches[0] : event;
  }

  public lock(event) {
    this.rambOn = this.unify(event).clientX;
  }

  /**
   * updates the count conditionally on the value of 'step'
   * 1. check to see if lock (user interaction has been fired)
   * 2. conditionally set direction to 1 (Left) or -1 (Right) depending on direction of swipe / interaction
   * 3. if there is an item to switch to, run handleSelectDate
   */
  public move(event: any) {
    // 1.
    if (this.rambOn || this.rambOn === 0) {
      // 2.
      this.rambOff = this.unify(event).clientX - this.rambOn;
      const direction = Math.sign(this.rambOff);
      if (direction < 0) {
        // 3. Scroll Left
        this.active++;
        if (this.active >= this.slides.length) {
          this.active = this.slides.length - 1;
        }
        this.Xpos = direction * (this.step * this.active);
        this.setActiveSlide(this.active);
        this.renderer.setStyle(
          this.slider.nativeElement,
          'transform',
          `translate3d(${this.Xpos}%, 0, 0)`
        );
      }
      if (direction > 0) {
        // 3. Scroll Right
        this.active--;
        if (this.active <= 0) {
          this.active = 0;
        }
        this.Xpos = -1 * this.step * this.active;
        this.setActiveSlide(this.active);
        this.renderer.setStyle(
          this.slider.nativeElement,
          'transform',
          `translate3d(${this.Xpos}%, 0, 0)`
        );
      }
      this.rambOn = null;
    }
  }
}
