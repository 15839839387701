export interface PlanDocumentKey {
  key: string;
  hash: string;
}

export interface PlanDocumentKeyResult {
  hash: string;
  key: string;
}

export interface PlanDocumentKeyResponse {
  status: string;
  result: PlanDocumentKeyResult;
}
