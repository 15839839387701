import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() classes: string;
  @Input() type: string;
  @Input() control: string;
  @Input() text: string;
  @Input() stretch: string | boolean;
  @Input() disabled: boolean;
  @Input() submitted: boolean;

  @HostListener('click', ['$event']) onClick($event: Event) {
    if (this.disabled) {
      $event.preventDefault();
    }
  }
}
