<div class="holding-page" fxLayout="column" fxLayoutAlign="center center">
  <div class="container">
    <svg class="icon icon-blue">
      <use xlink:href="#icon-stopwatch"></use>
    </svg>
    <h1>{{ 'Holidng_Page.heading' | translate }}</h1>
    <p>{{ 'Holidng_Page.body_1' | translate }}</p>
    <p>{{ 'Holidng_Page.body_2' | translate }}</p>
  </div>
</div>
