import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ModalConfirmData } from '@athome-myaccount/my-account/util-shared';
@Component({
  selector: 'app-modal-dialog-confirm',
  templateUrl: './modal-dialog-confirm.component.html',
  styleUrls: ['./modal-dialog-confirm.component.scss'],
})
export class ModalDialogConfirmComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ModalConfirmData
  ) {}
}
