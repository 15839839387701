export interface CorrespondenceAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  addressLine4: string;
  postalCode: string;
  countryCode: string;
}

export interface MarketingPreferences {
  post: boolean;
  email: boolean;
  telephone: boolean;
  clientCode?: string;
  clientCodeDescription?: string;
  clientGroupCode?: string;
  clientGroupCodeDescription?: string;
  isEdited?: boolean;
  isShown?: boolean;
}

export interface MarketingPreferenceUpdate {
  clientCode: string;
  clientGroupCode: string;
  marketingPreferences: {
    post: boolean;
    email: boolean;
    telephone: boolean;
  };
}

export interface Contact {
  mobilePhoneNumber: string;
  landlinePhoneNumber: string;
  email: string;
}

export interface PersonalDetails {
  initial: string;
  firstName: string;
  surname: string;
  title: string;
  gender: string;
}

export interface Person {
  isLandlord: boolean;
  correspondenceAddress: CorrespondenceAddress;
  marketingPreferences: MarketingPreferences;
  contact: Contact;
  personalDetails: PersonalDetails;
  docSendEmailAddr?: string;
  docSendPreference?: boolean;
}

export interface Result {
  person: Person;
}

export interface MarketingResult {
  marketingPreferences: MarketingPreferences[];
}

export interface GetPersonalDetailsResponse {
  status: string;
  result: Result;
}

export interface GetMarketingPreferenceResponse {
  status: string;
  result: MarketingResult;
}
