import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserAuthGuard } from '@athome-myaccount/my-account/feature-shared';
import {
  GenericErrorComponent,
  HoldingPageComponent,
  PageNotFoundComponent,
  ResetStateComponent,
  ServerErrorComponent,
  UnAuthorizedErrorComponent,
} from '@athome-myaccount/my-account/feature-shared';
import {
  ErrorRoutesEnum,
  MyAccountFeatureShellModulesRoutesEnum,
} from '@athome-myaccount/my-account/util-login';

const routes: Routes = [
  {
    path: MyAccountFeatureShellModulesRoutesEnum.MY_ACCOUNT_LOGIN_MODULE_ROUTE,
    loadChildren: () =>
      import('@athome-myaccount/my-account/feature-login').then(
        (m) => m.MyAccountFeatureLoginModule
      ),
  },
  {
    path: MyAccountFeatureShellModulesRoutesEnum.MY_ACCOUNT_MOBILE_TOKEN_ROUTE,
    loadChildren: () =>
      import('@athome-myaccount/my-account/feature-login').then(
        (m) => m.MyAccountFeatureLoginModule
      ),
  },
  {
    path: MyAccountFeatureShellModulesRoutesEnum.DASHBOARD_MODULE_ROUTE,
    loadChildren: () =>
      import('@athome-myaccount/my-account/feature-dashboard').then(
        (m) => m.MyAccountFeatureDashboardModule
      ),
    canActivate: [UserAuthGuard],
  },
  {
    path: MyAccountFeatureShellModulesRoutesEnum.MY_ACCOUNT_DETAILS_MODULE_ROUTE,
    loadChildren: () =>
      import('@athome-myaccount/my-account/feature-login').then(
        (m) => m.MyAccountFeatureLoginModule
      ),
    canActivate: [UserAuthGuard],
  },
  {
    path: MyAccountFeatureShellModulesRoutesEnum.MAINTENANCE_MODULE_ROUTE,
    loadChildren: () =>
      import('@athome-myaccount/my-account/feature-maintenance').then(
        (m) => m.MyAccountFeatureMaintenanceModule
      ),
    canActivate: [UserAuthGuard],
  },
  { path: ErrorRoutesEnum.RESET_STATE_ROUTE, component: ResetStateComponent },
  {
    path: ErrorRoutesEnum.GENERIC_ERROR_ROUTE,
    component: GenericErrorComponent,
  },
  {
    path: ErrorRoutesEnum.NOTFOUND_ERROR_ROUTE,
    component: PageNotFoundComponent,
  },
  {
    path: ErrorRoutesEnum.NOT_AUTHORIZED_ERROR_ROUTE,
    component: UnAuthorizedErrorComponent,
  },
  { path: ErrorRoutesEnum.SERVER_ERROR_ROUTE, component: ServerErrorComponent },
  { path: ErrorRoutesEnum.HOLDING_PAGE_ROUTE, component: HoldingPageComponent },
  { path: ErrorRoutesEnum.WILDCARD_ROUTE, redirectTo: 'my-account' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AoDmaFeatureShellRoutingModule {}
