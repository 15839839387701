import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAuthenticated
);

export const selectUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);

export const selectError = createSelector(
  selectAuthState,
  (state: AuthState) => state.error
);

export const selectLogInResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.logInResponse
);

export const selectForgotPasswordResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.forgotPasswordResponse
);

export const selectResetPasswordResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.resetPasswordResponse
);

export const selectSignOutResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.signOutResponse
);

export const selectCheckUserSessionResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.signOutResponse
);

export const selectRequestVerificationResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.requestVerificationCodeResponse
);

export const selectVerificationCodeResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.verificationCodeResponse
);

export const selectCreateNewAccountResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.createAccountResponse
);

export const selectVerifyAddressResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.verifyAddressResponse
);

export const selectRefreshUserTokenResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.refreshUserTokenResponse
);

export const selectClearVerifyAddressResponse = createSelector(
  selectAuthState,
  (state: AuthState) => state.clearVerifyAddressResponse
);
