import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[routeTransformer]',
})
export class RouteTransformerDirective {
  constructor(private el: ElementRef, private router: Router) {}

  @HostListener('click', ['$event'])
  public onClick(event) {
    event.preventDefault();
    if (event.target.tagName === 'A') {
      if (!(event.target.getAttribute('href').indexOf('http') > -1)) {
        this.router.navigate([
          event.target.getAttribute('href').replace('/my-account', ''),
        ]);
      } else {
        window.open(event.target.getAttribute('href'), '_blank');
      }
    } else {
      return;
    }
  }
}
