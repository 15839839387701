export enum MyAccountFeatureShellModulesRoutesEnum {
  MY_ACCOUNT_LOGIN_MODULE_ROUTE = 'my-account',
  MY_ACCOUNT_MOBILE_TOKEN_ROUTE = 'my-account/token-exchange/:awsacc/:awsidt',
  DASHBOARD_MODULE_ROUTE = 'my-plans',
  MY_ACCOUNT_DETAILS_MODULE_ROUTE = 'my-account/my-details',
  MAINTENANCE_MODULE_ROUTE = 'maintenance',
}

export enum ErrorRoutesEnum {
  RESET_STATE_ROUTE = 'reset-state',
  SERVER_ERROR_ROUTE = 'server-error',
  GENERIC_ERROR_ROUTE = 'generic-error',
  NOTFOUND_ERROR_ROUTE = 'page-not-found',
  NOT_AUTHORIZED_ERROR_ROUTE = 'unauthorized',
  DASHBOARD_ROUTE = 'my-plans',
  HOLDING_PAGE_ROUTE = 'holding-page',
  WILDCARD_ROUTE = '**',
}
