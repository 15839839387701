import { OpenUsernameExistsModal } from './../models/global-modals.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CloseModal,
  OpenAlertModal,
  OpenConfirmModal,
  OpenUnsupportedModal,
} from '../models/global-modals.model';

@Injectable({
  providedIn: 'root',
})
export class ModalDialogService {
  private openModalSubject: BehaviorSubject<
    | OpenAlertModal
    | OpenConfirmModal
    | OpenUnsupportedModal
    | CloseModal
    | OpenUsernameExistsModal
  > = new BehaviorSubject<
    | OpenAlertModal
    | OpenConfirmModal
    | OpenUnsupportedModal
    | CloseModal
    | OpenUsernameExistsModal
  >(null);

  getModal(): Observable<
    | OpenAlertModal
    | OpenConfirmModal
    | OpenUnsupportedModal
    | CloseModal
    | OpenUsernameExistsModal
  > {
    return this.openModalSubject.asObservable();
  }

  openModal(
    modal:
      | OpenAlertModal
      | OpenConfirmModal
      | OpenUnsupportedModal
      | CloseModal
      | OpenUsernameExistsModal
  ): void {
    this.openModalSubject.next(modal);
  }

  closeModal(): void {
    this.openModalSubject.next(new CloseModal());
  }
}
