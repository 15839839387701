import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginRoutesEnum } from '@athome-myaccount/my-account/util-login';

@Component({
  selector: 'athome-myaccount-username-already-exists',
  templateUrl: './modal-dialog-username-already-exists.component.html',
  styleUrls: ['./modal-dialog-username-already-exists.component.scss'],
})
export class ModalDialogUsernameAlreadyExistsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private dialogRef: MatDialogRef<ModalDialogUsernameAlreadyExistsComponent>,
    private router: Router
  ) {}

  handleBackToLogin(): void {
    this.router.navigateByUrl(LoginRoutesEnum.LOGIN_PAGE);
    this.dialogRef.close();
  }

  handleResetPassword(): void {
    this.router.navigateByUrl(LoginRoutesEnum.FORGOT_PASSWORD_PAGE);
    this.dialogRef.close();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
