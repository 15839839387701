export const PLANS = [
  {
    plan_number: 'L8Y0066494',
    plan_state: 'COVERED',
    plan_start: '2017-05-09',
    plan_expiry: '2018-05-09',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    item: {
      manufacturer: 'CANDY',
      model_number: 'AR 24DL/TB/1',
      code: 'FF',
      code_description: 'FRIDGE FREEZER',
    },
  },
  {
    plan_number: 'RIS0000304',
    plan_state: 'RENEWAL',
    plan_start: '2018-07-09',
    plan_expiry: '2019-07-09',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    item: {
      manufacturer: 'BEKO',
      model_number: 'RB711NS',
      code: 'FF',
      code_description: 'FRIDGE FREEZER',
    },
  },
  {
    plan_number: 'G2L0002796',
    plan_state: 'LAPSED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002586',
    parent_plan_state: 'LAPSED',
    item: {
      manufacturer: 'SAMET',
      model_number: 'MB-SM1-FREEZER',
      code: 'FZ',
      code_description: 'FREEZER',
    },
  },
  {
    plan_number: 'G2L0002797',
    plan_state: 'CANCELLED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002586',
    parent_plan_state: 'CANCELLED',
    item: {
      manufacturer: 'BOSCH',
      code: 'CL',
      code_description: 'VACUUM CLEANER',
    },
  },
  {
    plan_number: 'G2L0002798',
    plan_state: 'RENEWAL',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002586',
    parent_plan_state: 'RENEWAL',
    item: {
      manufacturer: 'ZANUSSI',
      model_number: 'ZRT27101WA',
      code: 'FF',
      code_description: 'FRIDGE FREEZER',
    },
  },
  {
    plan_number: 'G2L0002799',
    plan_state: 'COVERED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002586',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'BAUKNECHT',
      code: 'OV',
      code_description: 'OVEN',
    },
  },
];

export const UNPROTECTED_PLANS = PLANS.filter(
  (plan) => plan.plan_state === 'LAPSED' || plan.plan_state === 'CANCELLED'
);

export const PROTECTED_PLANS = PLANS.filter(
  (plan) => plan.plan_state === 'COVERED'
);

export const RENEWAL_PLANS = PLANS.filter(
  (plan) => plan.plan_state === 'RENEWAL'
);

export const UNSORTED_PLANS = [
  {
    plan_number: 'G2L0002780',
    plan_state: 'CANCELLED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    item: {
      manufacturer: 'BAUKNECHT',
      code: 'OV',
      code_description: 'OVEN',
    },
  },
  {
    plan_number: 'G2L0002797',
    plan_state: 'RENEWAL',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002586',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'BOSCH',
      code: 'CL',
      code_description: 'VACUUM CLEANER',
    },
  },
  {
    plan_number: 'G2L0002781',
    plan_state: 'LAPSED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    item: {
      manufacturer: 'BAUKNECHT',
      code: 'OV',
      code_description: 'OVEN',
    },
  },
  {
    plan_number: 'RIS0000304',
    plan_state: 'RENEWAL',
    plan_start: '2018-07-09',
    plan_expiry: '2019-07-09',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    item: {
      manufacturer: 'BEKO',
      model_number: 'RB711NS',
      code: 'FF',
      code_description: 'FRIDGE FREEZER',
    },
  },
  {
    plan_number: 'G2L0002798',
    plan_state: 'LAPSED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002586',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'ZANUSSI',
      model_number: 'ZRT27101WA',
      code: 'FF',
      code_description: 'FRIDGE FREEZER',
    },
  },
  {
    plan_number: 'L8Y0066494',
    plan_state: 'COVERED',
    plan_start: '2017-05-09',
    plan_expiry: '2018-05-09',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    item: {
      manufacturer: 'CANDY',
      model_number: 'AR 24DL/TB/1',
      code: 'FF',
      code_description: 'FRIDGE FREEZER',
    },
  },
  {
    plan_number: 'G2L0002796',
    plan_state: 'COVERED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002586',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'SAMET',
      model_number: 'MB-SM1-FREEZER',
      code: 'FZ',
      code_description: 'FREEZER',
    },
  },
  {
    plan_number: 'G2L0002799',
    plan_state: 'CANCELLED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002586',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'BAUKNECHT',
      code: 'OV',
      code_description: 'OVEN',
    },
  },
  {
    plan_number: 'G2L0002775',
    plan_state: 'LAPSED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002587',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'SAMET',
      model_number: 'MB-SM1-FREEZER',
      code: 'FZ',
      code_description: 'FREEZER',
    },
  },
  {
    plan_number: 'G2L0002776',
    plan_state: 'COVERED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'G2K0002587',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'SAMET',
      model_number: 'MB-SM1-FREEZER',
      code: 'FZ',
      code_description: 'FREEZER',
    },
  },
  {
    plan_number: 'AK0002588',
    plan_state: 'LAPSED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'AK0002588',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'SAMET',
      model_number: 'MB-SM1-FREEZER',
      code: 'FZ',
      code_description: 'FREEZER',
    },
  },
  {
    plan_number: 'AK0002589',
    plan_state: 'COVERED',
    plan_start: '2018-07-11',
    plan_expiry: '2019-07-11',
    plan_contract_type: 'FT',
    plan_owner_type: 'CUSTOMER',
    parent_plan_number: 'AK0002588',
    parent_plan_state: 'COVERED',
    item: {
      manufacturer: 'SAMET',
      model_number: 'MB-SM1-FREEZER',
      code: 'FZ',
      code_description: 'FREEZER',
    },
  },
];

export const SORTED_PLAN_NUMBERS = [
  'L8Y0066494',
  'RIS0000304',
  'AK0002589',
  'AK0002588',
  'G2L0002796',
  'G2L0002797',
  'G2L0002798',
  'G2L0002799',
  'G2L0002776',
  'G2L0002775',
  'G2L0002781',
  'G2L0002780',
];
