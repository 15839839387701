import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-inputs',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, OnDestroy {
  @Input() group: FormGroup;
  @Input() maxlength: number;
  @Input() item;
  @Input() label;
  @Input() hint;
  @Input() labelFor;
  @Input() showValidation;
  @Input() validators;
  @Input() tooltip: string;
  name;
  id;

  public passwordValue = '';
  public passwordError = false;
  public isComponentActive = true;

  ngOnInit(): void {
    if (this.item.PasswordStrengthChecker) {
      this.group.controls[this.labelFor].valueChanges.subscribe((v: string) => {
        this.passwordError = this.group.controls[this.labelFor].valid;
        this.passwordValue = v;
      });
    }
  }

  handleFocusOutEvent(control: FormControl): void {
    if (
      this.group.get(this.item.control).invalid ||
      this.group.get(this.item.control).touched ||
      this.group.get(this.item.control).dirty
    ) {
      this.showValidation = true;
      this.group.get(this.item.control).markAsTouched();
    } else {
      this.showValidation = false;
    }
  }

  isNameOrSurname(formControlName: string): boolean {
    return formControlName === 'name' || formControlName === 'surname'
      ? true
      : false;
  }

  ngOnDestroy(): void {
    this.isComponentActive = false;
  }
}
