import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONFIG, Environment } from '@athome-myaccount/my-account/util-core';
import { CookieService } from 'ngx-cookie-service';
import { NEVER, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UnAuthorizedErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    @Inject(CONFIG) private config: Environment
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((errorResponse: Error) => {
        if (
          errorResponse instanceof HttpErrorResponse &&
          (errorResponse.status === 401 || errorResponse.status === 403) &&
          this.shouldHandleUnauthorized(request.url.toLowerCase())
        ) {
          this.cookieService.delete('AWS_IDT');
          this.router.navigateByUrl('/reset-state');
          return NEVER;
        }
        return throwError(errorResponse);
      })
    );
  }

  private shouldHandleUnauthorized(url: string): boolean {
    if (this.config.notAllowedUrlsForUnauthorizedRedirect) {
      const urlMatchesNoRedirectUrl =
        this.config.notAllowedUrlsForUnauthorizedRedirect.find(
          (u) => url.indexOf(u) > 0
        );
      return !urlMatchesNoRedirectUrl;
    }
    return true;
  }
}
