import {
  Directive,
  DoCheck,
  ElementRef,
  Optional,
  HostListener,
  HostBinding,
} from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appInputText]',
})
export class InputTextDirective implements DoCheck {
  filled: boolean;
  @HostBinding('class.someclass') someClass = true;

  constructor(public el: ElementRef, @Optional() public ngModel: NgModel) {}

  @HostListener('input', ['$event'])
  onInput(e) {
    this.updateFilledState();
  }

  ngDoCheck() {
    this.updateFilledState();
  }

  updateFilledState() {
    this.filled =
      (this.el.nativeElement.value && this.el.nativeElement.value.length) ||
      (this.ngModel && this.ngModel.model);
  }
}
