<div class="row row--padding">
  <div class="col no-padding">
    <div
      class="d-flex bg--page row--padding-large radio-hint justify-content-center"
    >
      <div class="col-md-10 text--center">
        <p>
          <ng-content></ng-content>
        </p>
      </div>
    </div>
  </div>
</div>
