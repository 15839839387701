// export interface CorrespondenceAddress {
//   addressLine1: string;
//   addressLine2?: string;
//   addressLine3?: string;
//   addressLine4?: string;
//   postalCode: string;
//   countryCode: string;
// }

export interface UpdateCorrespondenceAddressPayload {
  correspondenceAddress: {
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    addressLine4?: string;
    postalCode: string;
    countryCode: string;
  };
}
