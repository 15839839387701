import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PersonalDetailsState } from './../reducers/personal.details.reducer';

export const selectPersonalDetailsState =
  createFeatureSelector<PersonalDetailsState>('personalDetails');

export const selectGetPersonalDetailsResponse = createSelector(
  selectPersonalDetailsState,
  (personalDetailsState) => personalDetailsState.getPersonDetailsResponse
);

export const selectUpdateContactDetailsResponse = createSelector(
  selectPersonalDetailsState,
  (personalDetailsState) => personalDetailsState.updateContactDetailsResponse
);

export const selectUpdateAddressCorrespondenceResponse = createSelector(
  selectPersonalDetailsState,
  (personalDetailsState) => personalDetailsState.updateCorrespondenceAddress
);

export const selectUpdatePasswordResponse = createSelector(
  selectPersonalDetailsState,
  (personalDetailsState) => personalDetailsState.updatePassword
);

export const selectGetMarketingPreferenceResponse = createSelector(
  selectPersonalDetailsState,
  (personalDetailsState) => personalDetailsState.getMarketingPreferenceResponse
);

export const selectUpdateMarketingPreferenceResponse = createSelector(
  selectPersonalDetailsState,
  (personalDetailsState) => personalDetailsState.updateMarketingPreference
);

export const selectUpdateDocumentPreferenceResponse = createSelector(
  selectPersonalDetailsState,
  (personalDetailsState) => personalDetailsState.updateDocPreferenceResponse
);
