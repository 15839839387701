import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@athome-myaccount/my-account/data-access-login';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';
import { ErrorBaseComponent } from '../error-container-base.component';

@Component({
  selector: 'app-unauthorized.component',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnAuthorizedErrorComponent
  extends ErrorBaseComponent
  implements OnInit {
  constructor(
    router: Router,
    @Inject(DOCUMENT)
    document: Document,
    @Inject(CONFIG) config: AppConfig,
    loginService: LoginService,
    analyticsService: AnalyticsService
  ) {
    super(router, document, config, loginService, analyticsService);
  }

  ngOnInit(): void {
    this.analyticsService.push({
      pagename: 'unauthorized-error',
      genericpagename: 'error-page',
      category: 'error-page',
      event: 'laEvent',
      laEvent: 'error-page',
    });
  }
}
