import {
  Component,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  ChangeDetectorRef,
  AfterContentInit,
  OnDestroy,
} from '@angular/core';
import { AccordionTabComponent } from './accordion-tab/accordion-tab.component';
import { Subscription } from 'rxjs';

export interface BlockableUI {
  getBlockableElement(): HTMLElement;
}

import { forwardRef } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterContentInit, OnDestroy {
  @Input() multiple: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onOpen: EventEmitter<any> = new EventEmitter();

  @Input() style: any;

  @Input() styleClass: string;

  @Input() expandIcon = '';

  @Input() collapseIcon = '';

  @ContentChildren(forwardRef(() => AccordionTabComponent))
  private tabList: QueryList<AccordionTabComponent>;

  tabListSubscription: Subscription;

  private _activeIndex: any;

  public tabs: AccordionTabComponent[] = [];

  constructor(
    public el: ElementRef,
    public changeDetector: ChangeDetectorRef
  ) {}

  ngAfterContentInit() {
    this.initTabs();

    this.tabListSubscription = this.tabList.changes.subscribe((_) => {
      this.initTabs();
      this.changeDetector.markForCheck();
    });
  }

  initTabs(): any {
    this.tabs = this.tabList.toArray();
    this.updateSelectionState();
  }

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }

  @Input() get activeIndex(): any {
    return this._activeIndex;
  }

  set activeIndex(val: any) {
    this._activeIndex = val;
    this.updateSelectionState();
  }

  updateSelectionState() {
    if (this.tabs && this.tabs.length && this._activeIndex != null) {
      for (let i = 0; i < this.tabs.length; i++) {
        const selected = this.multiple
          ? this._activeIndex.includes(i)
          : i === this._activeIndex;
        const changed = selected !== this.tabs[i].selected;

        if (changed) {
          this.tabs[i].animating = true;
        }

        this.tabs[i].selected = selected;
        this.tabs[i].selectedChange.emit(selected);
      }
    }
  }

  ngOnDestroy() {
    if (this.tabListSubscription) {
      this.tabListSubscription.unsubscribe();
    }
  }
}
