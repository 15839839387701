import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '@athome-myaccount/my-account/data-access-shared';
import { GetMarketingPreferenceResponse } from '@athome-myaccount/my-account/util-login';
import {
  ModalDialogService,
  OpenAlertModal,
} from '@athome-myaccount/my-account/util-core';
import {
  ErrorRoutesEnum,
  GetPersonalDetailsResponse,
} from '@athome-myaccount/my-account/util-login';
import {
  AlertType,
  ModalAlertData,
} from '@athome-myaccount/my-account/util-shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mapTo, tap } from 'rxjs/operators';
import { PersonalDetailsHttpService } from './../../services/personal-details-http.service';
import * as PersonalDetailsActions from './../actions/personal-details.actions';
import { PersonalDetailsActionTypes } from './../actions/personal-details.actions';
import { PersonalDetailsState } from './../reducers/personal.details.reducer';

@Injectable()
export class PersonalDetailsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<PersonalDetailsState>,
    private personalDetailsHttpService: PersonalDetailsHttpService,
    private accountService: AccountService,
    private router: Router,
    private modalDialogService: ModalDialogService
  ) {}

  getPersonalDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.GetPersonDetails>(
        PersonalDetailsActionTypes.GetPersonDetails
      ),
      exhaustMap(() => {
        const personDetails =
          this.personalDetailsHttpService.getPersonDetails();

        return personDetails.pipe(
          map((response: GetPersonalDetailsResponse) => {
            return new PersonalDetailsActions.GetPersonDetailsSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              new PersonalDetailsActions.GetPersonDetailsFailure(error)
            );
          })
        );
      })
    )
  );

  getPersonalDetailsFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.GetPersonDetailsFailure>(
          PersonalDetailsActionTypes.GetPersonDetailsFailure
        ),
        tap((error) => {
          this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
        })
      ),
    { dispatch: false }
  );
  getMarketingPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.GetMarketingPreference>(
        PersonalDetailsActionTypes.GetMarketingPreference
      ),
      exhaustMap(() => {
        const personMarketingPreference =
          this.personalDetailsHttpService.getPersonMarketingPreference();

        return personMarketingPreference.pipe(
          map((response: GetMarketingPreferenceResponse) => {
            return new PersonalDetailsActions.GetMarketingPreferenceSuccess({
              response,
            });
          }),
          catchError((error) => {
            return of(
              new PersonalDetailsActions.GetMarketingPreferenceFailure(error)
            );
          })
        );
      })
    )
  );

  updateContactDetails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateContactDetails>(
        PersonalDetailsActionTypes.UpdateContactDetails
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateContactDetails) =>
        this.personalDetailsHttpService
          .updateContactDetails(action.payload)
          .pipe(
            map(
              (response: any) =>
                new PersonalDetailsActions.UpdateContactDetailsSuccess({
                  response,
                })
            ),
            catchError((error) => {
              return of(
                new PersonalDetailsActions.UpdateContactDetailsFailure(error)
              );
            })
          )
      )
    )
  );

  updateContactDetailsSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateContactDetailsSuccess>(
          PersonalDetailsActionTypes.UpdateContactDetailsSuccess
        ),
        tap((error) => {
          const modalDataSuccess: ModalAlertData = {
            title: 'Success',
            content: { content: 'Personal details updated' },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalDataSuccess, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateContactDetailsFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateContactDetailsFailure>(
          PersonalDetailsActionTypes.UpdateContactDetailsFailure
        ),
        tap((error: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { status, message } = error?.payload?.error;
          if (error?.payload?.status === 400 && status && message) {
            const modalError: ModalAlertData = {
              title: 'Something went wrong',
              content: message,
              alertType: AlertType.ERROR,
              closeButtonLabel: 'Close',
            };
            this.modalDialogService.openModal(
              new OpenAlertModal(modalError, '600px', '200px', true)
            );
          } else {
            this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
          }
        })
      ),
    { dispatch: false }
  );

  updateCorrespondenceAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateCorrespondenceAddress>(
        PersonalDetailsActionTypes.UpdateCorrespondenceAddress
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateCorrespondenceAddress) =>
        this.personalDetailsHttpService
          .updateCorrespondenceAddress(action.payload)
          .pipe(
            map(
              (response: any) =>
                new PersonalDetailsActions.UpdateCorrespondenceAddressSuccess({
                  response,
                })
            ),
            catchError((error) => {
              return of(
                new PersonalDetailsActions.UpdateCorrespondenceAddressFailure(
                  error
                )
              );
            })
          )
      )
    )
  );

  updateCorrespondenceAddressSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateCorrespondenceAddressSuccess>(
          PersonalDetailsActionTypes.UpdateCorrespondenceAddressSuccess
        ),
        tap((error) => {
          const modalDataSuccess: ModalAlertData = {
            title: 'Success',
            content: { content: 'Personal address updated successfully' },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };

          this.modalDialogService.openModal(
            new OpenAlertModal(modalDataSuccess, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateCorrespondenceAddressFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateContactDetailsFailure>(
          PersonalDetailsActionTypes.UpdateCorrespondenceAddressFailure
        ),
        tap((error) => {
          this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
        })
      ),
    { dispatch: false }
  );

  updatePassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdatePassword>(
        PersonalDetailsActionTypes.UpdatePassword
      ),
      exhaustMap((action: PersonalDetailsActions.UpdatePassword) =>
        this.accountService.updatePassword(action.payload.password).pipe(
          mapTo(new PersonalDetailsActions.UpdatePasswordSuccess()),
          catchError((error) =>
            of(new PersonalDetailsActions.UpdatePasswordFailure(error))
          )
        )
      )
    )
  );

  updateMarketingPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateMarketingPreference>(
        PersonalDetailsActionTypes.UpdateMarketingPreference
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateMarketingPreference) =>
        this.personalDetailsHttpService
          .updateMarketingPreference(action.payload.preference)
          .pipe(
            mapTo(
              new PersonalDetailsActions.UpdateMarketingPreferenceSuccess({
                response: action.payload.preference,
              })
            ),
            catchError((error) => {
              return of(
                new PersonalDetailsActions.UpdateMarketingPreferenceFailure(
                  error
                )
              );
            })
          )
      )
    )
  );

  updatePasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdatePasswordFailure>(
          PersonalDetailsActionTypes.UpdatePasswordFailure
        ),
        tap(({ payload }) => {
          const modalData: ModalAlertData = {
            title: 'Something went wrong',
            content: { content: 'Your request could not be processed.' },
            alertType: AlertType.ERROR,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updatePasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdatePasswordSuccess>(
          PersonalDetailsActionTypes.UpdatePasswordSuccess
        ),
        tap(() => {
          const modalData: ModalAlertData = {
            title: 'Success',
            content: {
              content: 'Your password has been successfully changed.',
            },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateMarketingPreferenceFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateMarketingPreferenceFailure>(
          PersonalDetailsActionTypes.UpdateMarketingPreferenceFailure
        ),
        tap(({ payload }) => {
          const modalData: ModalAlertData = {
            title: 'Something went wrong',
            content: { content: 'Your request could not be processed.' },
            alertType: AlertType.ERROR,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateMarketingPreferenceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateMarketingPreferenceSuccess>(
          PersonalDetailsActionTypes.UpdateMarketingPreferenceSuccess
        ),
        tap(() => {
          const modalData: ModalAlertData = {
            title: 'Success',
            content: {
              content:
                'Your marketing preference has been successfully changed.',
            },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );

          this.store.dispatch(new PersonalDetailsActions.GetPersonDetails());
          this.store.dispatch(
            new PersonalDetailsActions.GetMarketingPreference()
          );
        })
      ),
    { dispatch: false }
  );

  updateDocumentPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<PersonalDetailsActions.UpdateDocPreference>(
        PersonalDetailsActionTypes.UpdateDocPreference
      ),
      exhaustMap((action: PersonalDetailsActions.UpdateDocPreference) =>
        this.personalDetailsHttpService
          .updateDocPreference(action.payload)
          .pipe(
            map(
              (response: any) =>
                new PersonalDetailsActions.UpdateDocPreferenceSuccess({
                  response,
                })
            ),
            catchError((error) => {
              return of(
                new PersonalDetailsActions.UpdateDocPreferenceFailure(error)
              );
            })
          )
      )
    )
  );

  updateDocumentPreferenceSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateDocPreferenceSuccess>(
          PersonalDetailsActionTypes.UpdateDocPreferenceSuccess
        ),
        tap((error) => {
          const modalDataSuccess: ModalAlertData = {
            title: 'Success',
            content: { content: 'Document Preference updated' },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalDataSuccess, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  updateDocumentPreferenceFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<PersonalDetailsActions.UpdateDocPreferenceFailure>(
          PersonalDetailsActionTypes.UpdateDocPreferenceFailure
        ),
        tap((error: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { status, message } = error?.payload?.error;
          if (error?.payload?.status === 400 && status && message) {
            const modalError: ModalAlertData = {
              title: 'Something went wrong',
              content: message,
              alertType: AlertType.ERROR,
              closeButtonLabel: 'Close',
            };
            this.modalDialogService.openModal(
              new OpenAlertModal(modalError, '600px', '200px', true)
            );
          } else {
            this.router.navigate([`/${ErrorRoutesEnum.GENERIC_ERROR_ROUTE}`]);
          }
        })
      ),
    { dispatch: false }
  );
}
