import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DgxQmModule } from '@domgen/dgx-fe-qm';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { ModalDialogUsernameAlreadyExistsComponent } from './modals/modal-dialog-username-already-exists/modal-dialog-username-already-exists.component';
// helpers
import { FormValidationHelper } from './helpers/form-validation.helper';
import { ModalDialogAlertComponent } from './modals/modal-dialog-alert/modal-dialog-alert.component';
import { ModalDialogConfirmComponent } from './modals/modal-dialog-confirm/modal-dialog-confirm.component';
import { ModalDialogUnsupportedBrowsersComponent } from './modals/modal-dialog-unsupported-browsers/modal-dialog-unsupported-browsers.component';
import { FormBuilderModule } from './modules/formbuilder/form-builder.module';
import { MaterialModule } from './modules/material/material.module';
// pipes
import { RouteTransformerDirective } from './pipes/routeTransform';
import { SanitizeHtmlPipe } from './pipes/sanitizeHtml';
import { ServicesModule } from './services/services.module';
@NgModule({
  declarations: [
    ModalDialogAlertComponent,
    ModalDialogConfirmComponent,
    ...fromComponents.components,
    ...fromDirectives.directives,
    RouteTransformerDirective,
    SanitizeHtmlPipe,
    ModalDialogUnsupportedBrowsersComponent,
    ModalDialogUsernameAlreadyExistsComponent,
  ],
  exports: [
    ...fromComponents.components,
    ...fromDirectives.directives,
    RouteTransformerDirective,
    SanitizeHtmlPipe,
    TranslateModule,
    MaterialModule,
    ModalDialogUsernameAlreadyExistsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ServicesModule.forRoot(),
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    FormBuilderModule,
    AngularSvgIconModule,
    DgxQmModule.forRoot(),
  ],
  providers: [FormValidationHelper],
})
export class MyAccountFeatureSharedModule {}
