<ng-container>
  <div
    class="container-absolute"
    (mousedown)="lock($event)"
    (touchstart)="lock($event)"
    (mouseup)="move($event)"
    (touchend)="move($event)"
  >
    <div #slider [class]="classes" [formGroup]="group">
      <ng-container *ngFor="let slide of slides; let i = index">
        <div class="slide" id="s{{ i }}">
          <div class="row">
            <ng-container *ngFor="let data of slide; let j = index">
              <div [class]="data.classes">
                <div
                  class="dg-radios__item"
                  [ngClass]="{
                    error: !group.get(control).valid && showValidation,
                    active: group.get(control).value === data.value
                  }"
                >
                  <input
                    class="dg-radios__input"
                    id="{{ control + data.value }}"
                    [formControlName]="control"
                    [attr.data-automation]="control + data.value"
                    type="radio"
                    [value]="data.value"
                  />
                  <label
                    class="dg-label dg-radios__label"
                    for="{{ control + data.value }}"
                  >
                    <svg class="icon" *ngIf="data.icon">
                      <use [attr.xlink:href]="'#' + 'icon-' + data.icon"></use>
                    </svg>

                    <br class="wrap-text" *ngIf="data.icon" />
                    {{ data.text }}
                  </label>
                  <ul *ngIf="data.list" [class]="'dg-list' + data.list.classes">
                    <li *ngFor="let item of data.list.text">{{ item }}</li>
                  </ul>
                  <app-hint *ngIf="data.hint" [classes]="data.hint.classes">
                    {{ data.hint.text }}
                  </app-hint>
                </div>
                <div
                  class="dg-form-group"
                  *ngIf="data.groups && group.value[control] === data.value"
                >
                  <div class="">
                    <ng-container *ngFor="let subgroup of data.groups">
                      <app-fieldset
                        *ngIf="subgroup.fieldset"
                        [classes]="'dg-fieldset'"
                        [group]="group"
                        [data]="subgroup.fieldset"
                        [validate]="showValidation"
                      >
                      </app-fieldset>
                      <app-form-elements
                        [validate]="showValidation"
                        [group]="group"
                        [data]="subgroup"
                      ></app-form-elements>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <ul
      class="pages d-sm-none"
      [ngClass]="{ 'd-none': slides.length <= 1 }"
      #page
    >
      <li *ngFor="let slide of slides; let i = index" id="{{ i }}"></li>
    </ul>
    <div class="col">
      <div class="col">
        <!-- conditional radioHint -->
        <app-radiobutton-hint *ngIf="radioHint">
          {{ radioHint }}
        </app-radiobutton-hint>
        <ng-container *ngIf="validators && showValidation">
          <app-validation-error-formcontrol
            [group]="group"
            [validators]="validators"
            [control]="control"
          >
          </app-validation-error-formcontrol>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
