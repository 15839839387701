import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { RUNTIME_CONFIG } from '@athome-myaccount/my-account/util-core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * Hammerjs must be imported for gestures
 */
import 'hammerjs';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

/*
  This code fetches the runtime config that is changed per deployed environment by Jenkins
  Once the runtime config is fetched, we provide it as a token to merge with the config
  represented by environment.ts or environment.prod.ts
  I am using XMLHttpRequest for IE11
*/
let configXhr = null;
configXhr = new XMLHttpRequest();
configXhr.open('GET', '/config.json');
configXhr.send();
configXhr.onreadystatechange = function () {
  if (this.status === 200 && this.readyState === 4) {
    const config = JSON.parse(configXhr.responseText);
    // initializeCookiePro(config);
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic([
      {
        provide: RUNTIME_CONFIG,
        useValue: config,
      },
      { provide: RECAPTCHA_V3_SITE_KEY, useValue: config.recaptchaSiteKey },
    ])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  }
};

export function initializeCookiePro(config: any) {
  if (!config?.cookieProSrc && !config?.cookieProDomainScript) {
    return config;
  }
  const scriptElement = document.createElement('script');
  const head = document.getElementsByTagName('head')[0];
  scriptElement.src = config.cookieProSrc;
  scriptElement.setAttribute(
    'data-domain-script',
    config.cookieProDomainScript
  );
  // Write CookiePro script to top of head
  head.insertBefore(scriptElement, head.childNodes[0]);
  return config;
}
