<div
  [formGroup]="group"
  class="form-input"
  [ngClass]="{
    'is-invalid':
      !group.controls[item.control].valid &&
      group.controls[item.control]?.touched,
    'is-valid':
      group.controls[item.control]?.valid &&
      group.controls[item.control]?.touched
  }"
>
  <app-label
    *ngIf="label"
    [label]="label"
    [class]="label.classes"
    [forElement]="labelFor"
    >{{ label.text }}
  </app-label>
  <app-hint *ngIf="hint" [classes]="hint.classes"> {{ hint.text }} </app-hint>
  <div class="form-control">
    <input
      [class]="item.classes"
      [formControlName]="item.control"
      [attr.data-automation]="item.control"
      [placeholder]="item.placeholder"
      [id]="item.control"
      [name]="item.control"
      [maxlength]="maxLength"
      type="text"
      (keydown)="typeAhead($event)"
      (keyup)="keyUp()"
      (blur)="handleOnBlur()"
      (focus)="handleOnFocus()"
    />
    <ul
      *ngIf="filtered.length > 0 && lookupMatch === false"
      class="autocomplete-results"
    >
      <li
        *ngFor="let result of filtered; index as i"
        [innerHTML]="result.value"
        [ngClass]="{ focus: result.focused }"
        (mousedown)="selectResult(i)"
        (mouseenter)="typeAhead($event, i)"
      ></li>
    </ul>
    <i class="icon icon-tick-circle"></i>
    <i class="icon icon-error-circle"></i>
  </div>
  <ng-container
    *ngIf="
      validators && showValidation && group.controls[item.control]?.touched
    "
  >
    <app-validation-error-formcontrol
      [group]="group"
      [validators]="validators"
      [control]="item.control"
    ></app-validation-error-formcontrol>
  </ng-container>
</div>
