<div
  class="cta-container"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutGap="40px"
>
  <div
    [ngClass.xs]="'cta-container-button-mobile'"
    [ngClass.gt-xs]="'cta-container-button'"
  >
    <app-button
      [text]="'Unauthorized_Page.cta_1' | translate"
      classes="btn btn--secondary"
      (click)="handleContactUsClick()"
    ></app-button>
  </div>

  <div
    [ngClass.xs]="'cta-container-button-mobile'"
    [ngClass.gt-xs]="'cta-container-button'"
  >
    <app-button
      [text]="'Unauthorized_Page.cta_2' | translate"
      classes="btn btn--primary"
      (click)="handleHomeClick()"
    ></app-button>
  </div>
</div>
