import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig, CONFIG } from '@athome-myaccount/my-account/util-core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-reset-state',
  templateUrl: './reset-state.component.html',
  styleUrls: ['./reset-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetStateComponent implements OnInit {
  domesticAndGeneralUrl = this.config.domesticAndGeneralUrl;
  constructor(
    private readonly store: Store<any>,
    private router: Router,
    @Inject(CONFIG) private config: AppConfig
  ) {}

  ngOnInit(): void {
    this.store.dispatch({
      type: '[Account] Reset Account State',
    });
    this.store.dispatch({
      type: '[Login] Reset User State',
    });
    this.router.navigateByUrl('/my-account');
  }
}
