<div fxLayout="column" fxFill>
  <div fxLayout fxFlex>
    <div fxFlex fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutAlign="space-around center">
        <span class="title">{{ data.title }}</span>
        <span class="content">{{ data.content }}</span>
        <mat-dialog-actions align="center" stlye="width: 100%;">
          <div
            fxFlexFill
            fxLayout.xs="column"
            fxLayoutAlign="center"
            fxLayoutGap="40px grid"
          >
            <div fxFlex="50" fxFlex.gt-xs="100">
              <button
                class="btn btn--secondary"
                style="min-width: 210px"
                [mat-dialog-close]="true"
              >
                {{ data.confirmButtonLabel }}
              </button>
            </div>
            <div fxFlex="50" fxFlex.gt-xs="100">
              <button
                *ngIf="data?.closeButtonLabel"
                class="btn btn--secondary"
                style="min-width: 210px"
                [mat-dialog-close]="false"
              >
                {{ data.closeButtonLabel }}
              </button>
            </div>
          </div>
        </mat-dialog-actions>
      </div>
    </div>
  </div>
</div>
