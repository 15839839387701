import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select2',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() label: string;
  @Input() options: any[];

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output()
  inputChange: EventEmitter<Event> = new EventEmitter<Event>();
}
