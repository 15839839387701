import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class PcaService {
  AppConfig = {
    postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
    postcodeServiceFind:
      'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
    postcodeServiceRetrieve:
      'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  };

  constructor(private http: HttpClient) {}

  public pcaFind(input: any, id: any = ''): Observable<any> {
    return this.http
      .get(
        `${this.AppConfig.postcodeServiceFind}?key=${this.AppConfig.postcodeServiceKey}&Text=${input}&Container=${id}&Origin=&IsMiddleware=false&Countries=GB&Limit=&Language=`
      )
      .pipe(
        tap((response: Response) => response),
        catchError((error: any) => throwError(error))
      );
  }

  public pcaRetrieve(id: any): Observable<any> {
    return this.http
      .get(
        `${this.AppConfig.postcodeServiceRetrieve}?key=${this.AppConfig.postcodeServiceKey}&Id=${id}`
      )
      .pipe(
        tap((response: Response) => response),
        catchError((error: any) => throwError(error))
      );
  }
}
