import * as faker from 'faker';

export interface MarketingPreferenceDetails {
  clientCode: string;
  clientCodeDescription: string;
  clientGroupCode: string;
  clientGroupCodeDescription: string;
  email: boolean | null;
  telephone: boolean | null;
  post: boolean | null;
  isEdited?: boolean;
}

export interface PersonDetails {
  title: string;
  name: string;
  surname: string;
  email: string;
  mobile: string;
  landline: string;
  address: string;
  marketingPreferences: MarketingPreferenceDetails;
}

export const generateRandomPersonalDetails = (): PersonDetails => {
  return {
    title: faker.random.arrayElement(['Mr', 'Mrs', 'Miss', 'Ms', 'Sir', 'Dr']),
    name: faker.name.firstName(),
    surname: faker.name.lastName(),
    email: faker.internet.email(),
    mobile: faker.phone.phoneNumber(),
    landline: faker.phone.phoneNumber(),
    address: faker.address.streetAddress(),
    marketingPreferences: generateRandomMarketingPreferences(),
  };
};

export const generateRandomMarketingPreferences =
  (): MarketingPreferenceDetails => {
    return {
      clientCode: faker.random.word(),
      clientCodeDescription: faker.random.word(),
      clientGroupCode: faker.random.word(),
      clientGroupCodeDescription: faker.random.word(),
      email: faker.random.arrayElement([true, false, null]),
      telephone: faker.random.arrayElement([true, false, null]),
      post: faker.random.arrayElement([true, false, null]),
      isEdited: false,
    };
  };
