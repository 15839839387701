export enum UserRegistrationStatusEnum {
  MATCH_REQ = 'MATCH_REQ',
  PASSWORD_RESET_REQ = 'PASSWORD_RESET_REQ',
  CONFIRMATION_REQ = 'CONFIRMATION_REQ',
  CONFIRMATION_NOT_REQUIRED = 'CONFIRMATION_NOT_REQUIRED',
  ADDRESS_CHECK_REQ = 'ADDRESS_CHECK_REQ',
  PLAN_CHECK_REQ = 'PLAN_CHECK_REQ',
  COMPLETE = 'COMPLETE',
  LOCKED = 'LOCKED',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  BAD_CREDENTIALS = 'BAD_CREDENTIALS',
  CODE_EXPIRED = 'CODE_EXPIRED',
  CODE_INCORRECT = 'CODE_INCORRECT',
  PASSWORD_ILLEGAL = 'PASSWORD_ILLEGAL',
  USERNAME_EXISTS = 'USERNAME_EXISTS',
}
