<div class="login-form">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div
      class="form-group"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
    >
      <label>{{ forms.loginForm.emailLabel }}</label>
      <input type="text" formControlName="email" class="form-control" />
      <div *ngIf="submitted && f.email.errors" class="form-input-feedback">
        <div *ngIf="f.email.errors.required">
          {{ forms.loginForm.requiredEmailValidation }}
        </div>
        <div *ngIf="f.email.errors.email">
          {{ forms.loginForm.incorrectEmailFormatValidation }}
        </div>
      </div>
    </div>

    <div
      class="form-group"
      [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
    >
      <label> {{ forms.loginForm.passwordLabel }}</label>
      <a class="show-password-link" (click)="toggleFieldTextType()"
        ><span class="auth-icon-eye">
          <ng-container
            [ngTemplateOutlet]="svgIconTemplate"
          ></ng-container> </span
        ><span
          class="link link-underline password-link"
          *ngIf="!fieldTextType"
          >{{ forms.loginForm.showPasswordLabel }}</span
        >
        <span class="link link-underline password-link" *ngIf="fieldTextType">{{
          forms.loginForm.hidePasswordLabel
        }}</span>
      </a>
      <input
        [type]="fieldTextType ? 'text' : 'password'"
        formControlName="password"
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
      />
      <div *ngIf="submitted && f.password.errors" class="form-input-feedback">
        <div *ngIf="f.password.errors.required">
          {{ forms.loginForm.requiredPasswordValidation }}
        </div>
      </div>
    </div>
    <div
      (mousedown)="onForgotYourPasswordClick()"
      class="d-flex align-items-end flex-column forgot-password-container"
    >
      <a class="link link-underline forgot-password-link" tabindex="0">
        {{ forms.loginForm.forgotPasswordLinkLabel }}</a
      >
    </div>

    <div class="form-group">
      <button class="btn btn--primary" type="submit">
        {{ forms.loginForm.logInButtonLabel }}
      </button>
    </div>
  </form>
</div>

<ng-template #svgIconTemplate>
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="eye"
    class="svg-inline--fa fa-eye fa-w-18"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
  >
    <path
      fill="currentColor"
      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
    ></path>
  </svg>
</ng-template>
