import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ApiResponseTypeEnum } from '../common/enums/api-response-type.enum';
import {
  LoginPayload,
  RequestPasswordPayload,
  VerificationCodePayload,
  ResetPasswordPayload,
  CreateAccountPayload,
  RefreshUserTokenPayload,
  RequestVerificationCodePayload,
} from '../common/models/payload';
import { User } from '../common/models/user';
import * as AuthActions from './auth.actions';
import * as AuthReducer from './auth.reducer';
import * as AuthSelectors from './auth.selectors';

@Injectable()
export class AuthLibFacade {
  user$: Observable<User> = this.store.pipe(select(AuthSelectors.selectUser));
  error$: Observable<Error> = this.store.pipe(
    select(AuthSelectors.selectError)
  );
  isAuthenticated$: Observable<boolean> = this.store.pipe(
    select(AuthSelectors.selectIsAuthenticated)
  );

  // Observables to subscribe to API status response to handle UI accordinglly: possiblle states: NOT_CALLED, SUCCESS, ERROR, LOADING
  logInResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectLogInResponse)
  );
  signOutResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectSignOutResponse)
  );
  forgotPasswordResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectForgotPasswordResponse)
  );
  requestVerificationCodeResponse$: Observable<ApiResponseTypeEnum> =
    this.store.pipe(select(AuthSelectors.selectRequestVerificationResponse));
  verificationCodeResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectVerificationCodeResponse)
  );
  resetPasswordResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectResetPasswordResponse)
  );
  createAccountResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectCreateNewAccountResponse)
  );
  checkUserSessionResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectCheckUserSessionResponse)
  );
  clearVerifyAddressResponse$: Observable<boolean> = this.store.pipe(
    select(AuthSelectors.selectClearVerifyAddressResponse)
  );
  verifyAddressResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectVerifyAddressResponse)
  );
  refreshUserTokenResponse$: Observable<ApiResponseTypeEnum> = this.store.pipe(
    select(AuthSelectors.selectRefreshUserTokenResponse)
  );

  constructor(private store: Store<AuthReducer.AuthState>) {}

  public checkUserSession(): void {
    this.store.dispatch(new AuthActions.CheckUserSession());
  }

  public logInUser(payload: LoginPayload): void {
    this.store.dispatch(new AuthActions.LogIn(payload));
  }

  public signOutUser(): void {
    this.store.dispatch(new AuthActions.SignOut());
  }

  public requestVerificationCode(
    payload: RequestVerificationCodePayload
  ): void {
    this.store.dispatch(new AuthActions.RequestVerificationCode(payload));
  }

  public verifyCodeForUser(payload: VerificationCodePayload): void {
    this.store.dispatch(new AuthActions.VerificationCode(payload));
  }

  public createAccountForUser(payload: CreateAccountPayload): void {
    this.store.dispatch(new AuthActions.CreateAccount(payload));
  }

  public requestForgotPasswordForUser(payload: RequestPasswordPayload): void {
    this.store.dispatch(new AuthActions.ForgotPassword(payload));
  }

  public requestResetPasswordForUser(payload: ResetPasswordPayload): void {
    this.store.dispatch(new AuthActions.ResetPassword(payload));
  }

  public resetPasswordForUser(payload: ResetPasswordPayload): void {
    this.store.dispatch(new AuthActions.ResetPassword(payload));
  }

  public refreshUserToken(payload: RefreshUserTokenPayload): void {
    this.store.dispatch(new AuthActions.RefreshUserToken(payload));
  }

  public resetApiResponseState(): void {
    this.store.dispatch(new AuthActions.ResetApiResponseState());
  }

  public clearForgottenPasswordResponse(): void {
    this.store.dispatch(new AuthActions.ForgotPasswordClear());
  }

  public clearCreateAccountResponse(): void {
    this.store.dispatch(new AuthActions.CreateAccountClear());
  }

  public verifyAddress(payload: any): void {
    this.store.dispatch(new AuthActions.VerifyAddress(payload));
  }

  public clearVerifyAddressResponse(): void {
    this.store.dispatch(new AuthActions.VerifyAddressClear());
  }

  public resetAuthState(): void {
    this.store.dispatch(new AuthActions.ResetAuthState());
  }
}
