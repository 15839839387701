import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

import {
  AlertType,
  ModalAlertData,
} from '@athome-myaccount/my-account/util-shared';

@Component({
  selector: 'app-modal-dialog-alert',
  templateUrl: './modal-dialog-alert.component.html',
  styleUrls: ['./modal-dialog-alert.component.scss'],
})
export class ModalDialogAlertComponent {
  public AlertType = AlertType;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ModalAlertData,
    private dialogRef: MatDialogRef<ModalDialogAlertComponent>
  ) {}

  handleClose() {
    this.dialogRef.close();
  }
}
