export interface RuntimeConfig {
  api: string;
  commonPersonServiceUrl: string;
  claimsAppHost: string;
  homepageAppHost?: string;
  salesAppHost: string;
  faqsUrl: string;
  debugMode: string;
  contactUsUrl: string;
  cookiesPolicyUrl?: string;
  domesticAndGeneralUrl: string;
  reportIssueUrl?: string;
  cookieProSrc: string;
  cookieProDomainScript: string;
  recaptchaSiteKey: string;
}
