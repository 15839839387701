import { TranslateService } from '@ngx-translate/core';

export enum RegexFormValidationsEnum {
  PLAN_NUMBER_VALIDATION = '^[a-zA-Z0-9 ]{3}[0-9]{7}$',
  ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED = "^[A-Za-z]+([ A-Za-z'()-]+)*$",
  USER_PASSWORD_VALIDATION = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,128}$)[^\\s]+(\\s+[^\\s]+)*$',
  OLD_ACCOUNTS_EMAIL_VALIDATION = '^.+@[^.].*.[A-Za-z0-9]{2,}$',
  EMAIL_VALIDATION = '^[a-zA-Z0-9]{1}[a-zA-Z0-9.+-_]*@[a-zA-Z0-9]{1}[a-zA-Z0-9.-]*[a-zA-Z0-9]{1}[.][a-zA-Z]{2,}$',
  MOBILE_NUMBER_VALIDATION = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
  LANDLINE_NUMBER_VALIDATION = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
  UK_MOBILE_NUMBER = '^(?:07|\\+?44)(?:\\d\\s?){9,10}$',
  UK_MOBILE_AND_LANDLINE_NUMBER = '^(?:0|\\+?44)(?:\\d\\s?){9,10}$',
}

export const regexOneComplexCharacterWhitespacesOnlyInMiddle =
  /^(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?])\S+(\s?\S+)*$/;

export enum FormFieldTypeEnum {
  REQUIRED = 'required',
  PATTERN = 'pattern',
  MINLENGTH = 'minlength',
  MAXLENGTH = 'maxlength',
}

export const EMAIL_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: translateService.instant(validationMessage),
    regexp: RegexFormValidationsEnum.EMAIL_VALIDATION,
  };
};

export const EMAIL_VALIDATION_PERSONAL_DETAILS = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: translateService.instant(validationMessage),
    regexp: RegexFormValidationsEnum.EMAIL_VALIDATION,
  };
};

export const OLD_ACCOUNTS_EMAIL_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: translateService.instant(validationMessage),
    regexp: RegexFormValidationsEnum.OLD_ACCOUNTS_EMAIL_VALIDATION,
  };
};

export const EMAIL_MAX_LENGTH_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.MAXLENGTH,
    message: translateService.instant(validationMessage),
    value: 50,
  };
};

export const EMAIL_MIN_LENGTH_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.MINLENGTH,
    message: translateService.instant(validationMessage),
    value: 10,
  };
};

export const NAME_MIN_LENGTH_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.MINLENGTH,
    message: translateService.instant(validationMessage),
    value: 1,
  };
};

export const NAME_MAX_LENGTH_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.MAXLENGTH,
    message: translateService.instant(validationMessage),
    value: 40,
  };
};

export const SURNAME_MIN_LENGTH_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.MINLENGTH,
    message: translateService.instant(validationMessage),
    value: 1,
  };
};

export const SURNAME_MAX_LENGTH_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.MAXLENGTH,
    message: translateService.instant(validationMessage),
    value: 40,
  };
};

export const NAME_SURNAME_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: translateService.instant(validationMessage),
    regexp: RegexFormValidationsEnum.ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED,
  };
};

export const PLAN_NUMBER_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: translateService.instant(validationMessage),
    regexp: RegexFormValidationsEnum.PLAN_NUMBER_VALIDATION,
  };
};

export const PASSWORD_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: translateService.instant(validationMessage),
    regexp: RegexFormValidationsEnum.USER_PASSWORD_VALIDATION,
  };
};

export const REQUIRED_FIELD_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.REQUIRED,
    message: translateService.instant(validationMessage),
  };
};

export const MOBILE_NUMBER_REGEX_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: translateService.instant(validationMessage),
    regexp: RegexFormValidationsEnum.MOBILE_NUMBER_VALIDATION,
  };
};

export const LANDLINE_NUMBER_REGEX_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: translateService.instant(validationMessage),
    regexp: RegexFormValidationsEnum.LANDLINE_NUMBER_VALIDATION,
  };
};

export const LANDLINE_MIN_LENGTH_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.MINLENGTH,
    message: translateService.instant(validationMessage),
    value: 11,
  };
};

export const LANDLINE_MAX_LENGTH_VALIDATION = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.MAXLENGTH,
    message: translateService.instant(validationMessage),
    value: 13,
  };
};

export const UK_MOBILE_NUMBER = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message:
      'Only valid UK mobile numbers starting with 07 or +447 are allowed',
    regexp: RegexFormValidationsEnum.UK_MOBILE_NUMBER,
  };
};

export const UK_MOBILE_AND_LANDLINE_NUMBER = (
  translateService: TranslateService,
  validationMessage: string
) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: 'Only valid UK mobile or landline numbers are allowed',
    regexp: RegexFormValidationsEnum.UK_MOBILE_AND_LANDLINE_NUMBER,
  };
};
