import {
  AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges,
  OnInit, Output
} from '@angular/core';

import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
})
export class InputAutoCompleteComponent implements OnInit, AfterViewChecked, OnChanges {
  constructor(private cdRef: ChangeDetectorRef) {}

  @Input() group: FormGroup;
  @Input() item;
  @Input() maxLength;
  @Input() label;
  @Input() hint;
  @Input() labelFor;
  @Input() showValidation;
  @Input() validators;
  @Input() results = [];
  @Input() triggerLookUpCheck: boolean;
  @Input() startSearchFromCharacter = 1;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onKeyUp: EventEmitter<any> = new EventEmitter();

  public id;
  public lookupMatch = false;
  public userEntered: string;
  public filtered = [];
  public found = []; // holds filtered data but not HTML formatted.
  public isTyping = false;
  public supressKeyUp: boolean;
  public selected: string;
  public index = 0;
  public isValid;
  state: string | null = null;

  /**
   * ChangeDetectorRef is using to prevent angular exception error
   * Expression has changed after it was checked. Previous value: xxx. Current value: yyy
   * DO NOT DELETE OR MODIFY THIS CODE!!!
   */
  ngAfterViewChecked() {
    if (this.state !== null) {
      this.setErrorState(this.state);
    }
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.userEntered = this.group.controls[this.item.control].value;
  }

  ngOnChanges() {
    this.waitResults();
  }
  /**
   * waitResults
   * it takes sometime to bind results of search api from parent component
   * we have to wait results before call filterResults method
   */
  waitResults() {
    if (this.results !== undefined) {
      this.filterResults(this.results);
      if (this.userEntered !== '' || this.userEntered !== undefined) {
        this.lookupMatch = false;
      } else {
        this.lookupMatch = true;
      }
    }
  }

  /**
   * filterResults
   * @param results[]
   * filtering results from API and dynamically highlighting user typed in blue
   * setting first matched element of drop down to hover state
   */
  filterResults(results) {
    this.filtered.length = 0;
    this.found.length = 0;

    if (
      results &&
      this.userEntered !== undefined &&
      this.userEntered !== null &&
      this.userEntered.length >= this.startSearchFromCharacter
    ) {
      for (let i = 0; i < results.length; i++) {
        // if substring matching show results
        if (results[i].indexOf(this.userEntered) !== -1) {
          const re = new RegExp(this.userEntered, 'g');
          this.filtered.push({
            focused: false,
            value: results[i].replace(
              re,
              `<strong class="autocomplete__highlight">${this.userEntered}</strong>`
            ),
          });
        }
      }
      this.found = results;
      // if there is no direct match
      // When user typing without spaces
      // We should not highlight text user typing
      if (this.found.length >= 1 && this.filtered.length === 0) {
        for (let i = 0; i < results.length; i++) {
          this.filtered.push({
            focused: false,
            value: results[i],
          });
        }
      }

      if (this.found.length === 0) {
        this.filtered.push({
          nomatch: true,
          focused: false,
          value: 'No match found',
        });
      }
      // if (this.found.length === 1) {
      //   // CHeck for strict match if one result
      //   this.lookUpCheck();
      // }
      setTimeout(() => {
        this.index = 0;
        this.focusResult(this.index);
      });
    } else {
      // No match found error
      this.lookUpCheck();
    }
  }

  /**
   * focusResult
   * @param index
   * simulate onHover state by adding flag to selected element
   */
  public focusResult(index: number): void {
    this.filtered.map((el) => {
      if (el.focused === true) {
        el.focused = false;
      }
    });
    if (this.filtered[index]) {
      this.filtered[index].focused = true;
    }
  }

  /**
   * selectResult
   * @param index
   * filter data from html tags and emits selected data to parent component
   * re-validates user entered data again
   * re-set all searched settings to initial state
   */
  public selectResult(index: number): void {
    if (this.filtered[0].nomatch !== true) {
      this.resetErrorState();
      const openTag = new RegExp(
        '<strong class="autocomplete__highlight">',
        'g'
      );
      const closeTag = new RegExp('</strong>', 'g');
      let selected;
      if (this.filtered[index]) {
        selected = this.filtered[index].value
          .replace(openTag, '')
          .replace(closeTag, '');
      }
      this.isTyping = false;
      this.lookupMatch = true;
      this.state = null;
      this.isValid = true;
      this.group.controls[this.item.control].patchValue(selected);
      this.onSelect.emit({
        q: selected,
        api: this.item.control,
      });
    }
  }

  /**
   * lookUpCheck
   * manage validators required and invalid based on user entered and filtered results
   */
  public lookUpCheck() {
    if (this.isValid === true) {
      this.lookupMatch = true;
    }

    const setErrors = () => {
      this.setErrorState(this.state);
      this.lookupMatch = true;
    };

    if (this.userEntered === undefined || this.userEntered === '') {
      this.state = 'required';
      setErrors();
      return false;
    }
    if (
      this.isValid !== undefined &&
      this.found.length === 0 &&
      (this.userEntered !== undefined || this.userEntered !== '')
    ) {
      this.state = 'invalid';
      setErrors();
      return false;
    }
    // For strict match
    if (this.filtered.length === 1 && this.userEntered === this.found[0]) {
      this.isValid = true;
      this.state = null;
      setErrors();
      return false;
    }
    if (
      this.isValid !== true &&
      this.filtered.length >= 1 &&
      this.lookupMatch === true &&
      this.isTyping === false
    ) {
      this.state = 'invalid';
      setErrors();
      return false;
    }
  }

  /**
   * typeAhead on KeyDown event
   * @param event
   * enabled keyboard interaction with filtered results navigation and selection for accessibility
   * manage hover state coupled to cursor key selection
   */
  public typeAhead(event, index?: number): void {
    this.supressKeyUp = false;
    if (event.key === 'ArrowLeft' || event.key === 'Left') {
      this.supressKeyUp = true;
      return;
    }
    if (event.key === 'ArrowRight' || event.key === 'Right') {
      this.supressKeyUp = true;
      return;
    }
    if (event.key === 'ArrowDown' || event.key === 'Down') {
      this.supressKeyUp = true;
      if (this.index < this.filtered.length - 1) {
        this.index++;
        this.focusResult(this.index);
        return;
      }
    }
    if (event.key === 'ArrowUp' || event.key === 'Up') {
      this.supressKeyUp = true;
      if (this.index >= 1) {
        this.index--;
        this.focusResult(this.index);
        return;
      }
    }
    if (event.key === 'Enter') {
      this.selectResult(this.index);
      this.supressKeyUp = true;
      return;
    }
    if (event.type === 'mouseenter') {
      this.supressKeyUp = true;
      this.index = index;
      this.focusResult(this.index);
    }
  }

  /**
   * keyUp on KeyUp event
   * passing user entered data to parent component which is calling the search api
   */
  public keyUp() {
    if (this.supressKeyUp === false) {
      this.userEntered =
        this.group.controls[this.item.control].value.toUpperCase();

      this.isValid = false;

      // Put fix here for too many requests issue DGX-1962
      if (this.userEntered) {
        this.onKeyUp.emit({
          q: this.userEntered,
          category: this.item.DataCategory,
          type: this.item.DataType,
        });
      }
    }
  }

  /**
   * resetErrorState
   * @param state
   * set validation errors
   */
  public resetErrorState() {
    this.group.get(this.item.control).setErrors(null);
  }
  /**
   * setErrorState
   * @param state
   * set validation errors
   */
  public setErrorState(state) {
    this.resetErrorState();
    this.showValidation = true;
    // No match found error
    if (state === 'invalid') {
      this.group.get(this.item.control).setErrors({
        invalid: true,
      });
    }
    // field is required error
    if (state === 'required') {
      this.group.get(this.item.control).setErrors({
        required: true,
      });
    }
  }

  /**
   * handleOnBlur
   */
  public handleOnBlur() {
    this.isTyping = false;
    this.lookupMatch = true;
    if (this.isValid !== true) {
      this.lookUpCheck();
    }
  }

  /**
   * handleOnFocus
   */
  handleOnFocus() {
    this.isTyping = true;
    this.lookupMatch = false;
    this.group.controls[this.item.control].markAsUntouched();
  }
}
