import { Validators, FormBuilder } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class FormValidationHelper {
  /**
   * on a truthy validator value return Validator.required
   */
  public validateRequired(value) {
    return value.required ? Validators.required : Validators.nullValidator;
  }

  /**
   * on a truthy validator value - pass in the regex property and return the value
   */
  public validatePattern(value) {
    // Validators.pattern('^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\\D*\\d)[A-Za-z\\d!$%@#£€*?&]{8,}$');

    if (value.regex && value.regex !== null) {
      // build regex from string value
      const re = /^\/(.*)\/(.*)$/;
      const result = re.exec(value.regex);
      const newRegExp = new RegExp(result[1], result[2]);
      return Validators.pattern(newRegExp);
    } else {
      return Validators.nullValidator;
    }
  }

  /**
   * on a truthy validator set the max length on null - ignore
   */
  public validateMaxLength(value) {
    return value.maxLength
      ? Validators.maxLength(value.maxLength)
      : Validators.nullValidator;
  }

  /**
   * accepts optional second param only used in sort code due to 00-00-00
   * format (backEnd check is for 6 but FE actual length is 8 so + num)
   * on a truthy validator set the min length on null - ignore
   */
  public validateMinLength(value: any, num: any = 0) {
    return value.minLength
      ? Validators.minLength((value.minLength as number) + num)
      : Validators.nullValidator;
  }

  public getFields(fields: any, inputList: any) {
    return inputList.reduce((init, input) => {
      init[input] = fields[input];
      return init;
    }, {});
  }

  public createForm(f: {
    formBuilder: FormBuilder;
    inputList: string[];
    fields: Input;
  }) {
    const form = f.formBuilder.group({});

    f.inputList.forEach((field: string) => {
      form.addControl(
        field,
        f.formBuilder.control('', [
          this.validateRequired(f.fields[field]),
          this.validatePattern(f.fields[field]),
          this.validateMinLength(f.fields[field]),
          this.validateMaxLength(f.fields[field]),
        ])
      );
    });

    return form;
  }

  public getValidationMessages(fields: unknown) {
    return Object.keys(fields).reduce((prev, key) => {
      prev[key] = fields[key].validationMessages;
      return prev;
    }, {});
  }
}

interface Input {
  label: string;
  placeholder: string;
  required: boolean;
  displayed?: any | null;
  checked?: any;
  maxLength: number;
  validator: string;
  regex: string;
  validationMessages: Validation;
}

interface Validation {
  minLength?: string;
  invalid?: string;
  maxLength?: string;
  required?: string;
}
