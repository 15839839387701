import * as faker from 'faker';
import {
  LoginPayload,
  LoginSuccessResponse,
  LoginErrorResponse,
  UserMatchPayload,
  UserMatchSuccessResponse,
  UserMatchErrorResponse,
  CreateAccountPayload,
  CreateAccountErrorResponse,
  RequestPasswordPayload,
  RequestPasswordErrorResponse,
  RequestVerificationCodePayload,
  ResetPasswordPayload,
  ResetPasswordErrorResponse,
} from '../interfaces/login.interface';
import { UserStateTypeEnum, UserStateErrorTypeEnum } from './userStateTypeEnum';

export interface User {
  sub?: string | null; // User id
  'custom:user_state': string; // is completed, re password etc
  iss: string; // issuer (cognito user pool)
  'cognito:username': string; // email
  aud: string; // api to target with IGNORE
  event_id: string; // IGNORE internal
  token_use: string; // identifies as type of access token
  auth_time: number; // when loggin happened from server time
  'custom:digital_id'; // digital ID
  name: string; // user name
  exp: number; // cookie expeiry time unix seconds
  iat: number; // issued cookie from this time
  family_name: string; // user surname
  email: string; // email
  'custom:wcs_created_at'?; // if present the user is a migrated one else its a new user
}

export interface INativescriptTokenEvent {
  domain: string;
  awsACCTokenValue: string;
  awsIDTTokenValue: string;
}

export interface CookieDef {
  name: string;
  value: string;
  expires: number;
  path: string;
  domain: string;
  secure: boolean;
}

export const generateRandomUser = (): User => {
  return {
    sub: faker.random.word(),
    iss: faker.random.word(),
    aud: faker.random.word(),
    event_id: faker.random.word(),
    token_use: faker.random.word(),
    auth_time: faker.random.number(),
    name: faker.random.word(),
    exp: faker.random.number(),
    iat: faker.random.number(),
    family_name: faker.random.word(),
    email: faker.random.word(),
    'custom:wcs_created_at': faker.random.word('test'),
    'custom:digital_id': faker.random.word(),
    'custom:user_state': faker.random.word(),
    'cognito:username': faker.random.word(),
  };
};

export const generateRandomLoginPayload = (): LoginPayload => {
  return {
    email: faker.internet.email(),
    password: faker.internet.password(),
  };
};

export const generateRandomLoginSuccessResponse = (): LoginSuccessResponse => {
  return {
    successful: faker.random.boolean(),
    state: faker.random.arrayElement(Object.values(UserStateTypeEnum)),
  };
};

export const generateRandomLoginErrorResponse = (): LoginErrorResponse => {
  return {
    successful: faker.random.boolean(),
    error_message: faker.random.word(),
    error_type: faker.random.arrayElement(
      Object.values(UserStateErrorTypeEnum)
    ),
  };
};

export const generateRandomUserMatchPayload = (): UserMatchPayload => {
  return {
    address_line_1: faker.address.streetAddress(),
    address_line_2: faker.address.streetAddress(),
    postcode: faker.address.zipCode(),
    surname: faker.name.lastName(),
    plan_number: faker.finance.account().toString(),
  };
};

export const generateRandomUserMatchSuccessResponse =
  (): UserMatchSuccessResponse => {
    return {
      successful: faker.random.boolean(),
      state: faker.random.arrayElement(Object.values(UserStateTypeEnum)),
    };
  };

export const generateRandomUserMatchErrorResponse =
  (): UserMatchErrorResponse => {
    return {
      successful: faker.random.boolean(),
      error_message: faker.random.word(),
      error_type: faker.random.arrayElement(
        Object.values(UserStateErrorTypeEnum)
      ),
    };
  };

export const generateRandomCreateAccountPayload = (): CreateAccountPayload => {
  return {
    name: faker.name.firstName(),
    surname: faker.name.lastName(),
    email: faker.internet.email(),
    password: faker.internet.password(),
  };
};

export const generateRandomCreateAccountErrorResponse =
  (): CreateAccountErrorResponse => {
    return {
      successful: faker.random.boolean(),
      error_message: faker.random.word(),
      error_type: faker.random.arrayElement(
        Object.values(UserStateErrorTypeEnum)
      ),
    };
  };

export const generateRandomRequestPasswordPayload =
  (): RequestPasswordPayload => {
    return {
      email: faker.internet.email(),
      is_sign_up: faker.random.boolean(),
    };
  };

export const generateRandomRequestPasswordErrorResponse =
  (): RequestPasswordErrorResponse => {
    return {
      successful: faker.random.boolean(),
      error_message: faker.random.word(),
      error_type: faker.random.arrayElement(
        Object.values(UserStateErrorTypeEnum)
      ),
    };
  };

export const generateRandomRequestVerificationCodePayload =
  (): RequestVerificationCodePayload => {
    return {
      email: faker.internet.email(),
    };
  };

export const generateRandomResetPasswordPayload = (): ResetPasswordPayload => {
  return {
    email: faker.internet.email(),
    confirmation_code: faker.finance.currencyCode(),
    is_sign_up: true,
    password: faker.internet.password(),
  };
};

export const generateRandomResetPasswordErrorResponse =
  (): ResetPasswordErrorResponse => {
    return {
      successful: faker.random.boolean(),
      error_message: faker.random.word(),
      error_type: faker.random.arrayElement(
        Object.values(UserStateErrorTypeEnum)
      ),
    };
  };
