import { Injectable, Inject } from '@angular/core';
import { GAClientConfig } from '../interfaces';
import { GA_CLIENT } from '../tokens';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type GtmData = { [key: string]: any };

export interface CustomWindow extends Window {
  dataLayer: any;
  optimizely: any;
  Trustpilot: any;
}

declare let window: CustomWindow;

@Injectable()
export class AnalyticsService {
  constructor(@Inject(GA_CLIENT) protected gaClient: GAClientConfig) {
    // Make sure the dataLayer property exists on the window object,
    // or we will not be able to communicate with GTM.
    /* istanbul ignore if */
    if (!Object.hasOwnProperty.call(window, 'dataLayer')) {
      throw new Error('`window.dataLayer` is not defined.');
    }
  }

  public push(data: GtmData) {
    if (window['dataLayer']) {
      window['dataLayer'].push({ clientName: this.gaClient.name, ...data });
    }
  }

  public trigger(event: string, data?: GtmData) {
    this.push({ event, clientName: this.gaClient.name, ...data });
  }

  public getClient(): string {
    return this.gaClient.name;
  }
}
