export enum AuthenticationApiEnum {
  CREATE_ACCOUNT_URL = '/signup',
  LOGIN_USER_URL = '/signin',
  REQUEST_PASSWORD_URL = '/password/forgot',
  RESET_PASSWORD_URL = '/password/confirmation',
  REQUEST_VERIFICATION_CODE_URL = '/verification/resendcode',
  VERIFICATION_CODE_URL = '/verification/confirmcode',
  PASSWORD_UPDATE = '/password/update',
  USER_MATCH_URL = '/user/match',
  SIGNOUT = '/signout',
  REFRESH = '/refresh',
}
