import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface ToastEvent {
  type: ToastEventType;
  value?: any;
}

export enum ToastEventType {
  ADD,
  CLEAR,
  CLEAR_ALL,
}

export interface ToastData {
  id?: number;
  title?: string;
  msg: any;
  icon?: string;
  theme: string;
  showClose?: boolean;
  timeout?: number;
  onRemove?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
}

@Injectable({ providedIn: 'root' })
export class NotifyToastService {
  private eventSource: Subject<ToastEvent> = new Subject<ToastEvent>();

  public events: Observable<ToastEvent> = this.eventSource.asObservable();
  public toastCounter = 0;

  defaultTimer = 5000;
  defaultTheme = 'default';
  defaultShowClose = false;

  create(options: ToastData) {
    this.clearAll();
    this.add(options);
  }

  // Add a new toast item
  private add(options: ToastData | string | number) {
    let toastOptions: ToastData;

    if (
      (typeof options === 'string' && options !== '') ||
      typeof options === 'number'
    ) {
      toastOptions = <ToastData>{ msg: options.toString() };
    } else {
      toastOptions = <ToastData>options;
    }

    if (!toastOptions || !toastOptions.msg) {
      throw new Error('No message supplied!');
    }

    // Set a unique counter for an id
    this.toastCounter++;

    const toast: ToastData = <ToastData>{
      id: this.toastCounter,
      msg: toastOptions.msg,
      title: toastOptions.title,
      icon: toastOptions.icon,
      theme: 'toast-theme-' + toastOptions.theme,
      showClose: toastOptions.showClose
        ? toastOptions.showClose
        : this.defaultShowClose,
      onRemove:
        toastOptions.onRemove && typeof toastOptions.onRemove === 'function'
          ? toastOptions.onRemove
          : null,
    };

    if (toastOptions.timeout) {
      // If there's a timeout individually or globally, set the toast to timeout
      toast.timeout = Object.prototype.hasOwnProperty.call(
        toastOptions,
        'timeout'
      )
        ? toastOptions.timeout
        : this.defaultTimer;
    }

    // Push up a new toast item
    this.emitEvent({
      type: ToastEventType.ADD,
      value: toast,
    });
  }

  // Clear all toasts
  public clearAll() {
    this.emitEvent({ type: ToastEventType.CLEAR_ALL });
  }

  // Clear the specific one
  clear(id: number) {
    this.emitEvent({
      type: ToastEventType.CLEAR,
      value: id,
    });
  }

  private emitEvent(event: ToastEvent) {
    if (this.eventSource) {
      this.eventSource.next(event);
    }
  }
}
