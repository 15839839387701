<div
  class="accordion-header"
  [ngClass]="{
    'state-active': selected,
    'state-disabled': disabled
  }"
>
  <a
    tabindex="0"
    [attr.id]="id"
    [attr.aria-controls]="id + '-content'"
    role="tab"
    [attr.aria-expanded]="selected"
    (click)="toggle($event)"
    (keydown)="onKeydown($event)"
  >
    <span class="accordion-header-text" *ngIf="!hasHeaderFacet">
      {{ header }}
    </span>
    <ng-content select="accordion-header" *ngIf="hasHeaderFacet"></ng-content>
  </a>
</div>

<div
  [attr.id]="id + '-content'"
  class="accordion-content-wrapper"
  [@showHide]="
    selected
      ? {
          value: 'visible',
          params: { transitionParams: transitionOptions }
        }
      : { value: 'hidden', params: { transitionParams: transitionOptions } }
  "
  (@showHide.done)="onToggleDone($event)"
  [ngClass]="{
    'accordion-content-wrapper-overflown': !selected || animating
  }"
  role="tabpanel"
  [attr.aria-hidden]="!selected"
  [attr.aria-labelledby]="id"
>
  <div class="accordion-content">
    <ng-content></ng-content>
    <ng-container *ngIf="contentTemplate && (cache ? loaded : selected)">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </ng-container>
  </div>
</div>
