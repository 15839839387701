<div
  class="lds-ring"
  [ngClass]="{
    'overlay-existing-content': overlayOnTopOfExistingContent,
    'overlay-fixed-middle-of-the-page': overlayFixedPositionMiddleOfPage
  }"
>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
