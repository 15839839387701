import { Environment } from '@athome-myaccount/my-account/util-core';
import { Footer } from '@athome-myaccount/shared/util-models';
import {
  NavigationLink,
  NavigationLinks,
} from '@domgen/dgx-fe-business-models';

const headerNavigationLinks = (basePath: string): NavigationLinks => {
  return {
    home: {
      href: 'https://www.ao-care.com',
      label: '',
      isHidden: false,
    },
    products: {
      label: 'Get protected',
      href: 'https://www.sales.ao-care.com/',
      isHidden: false,
    },
    repair: {
      href: `${basePath}/RepairClaimPageView`,
      label: 'Book a repair',
      isHidden: false,
    },
    faq: {
      label: 'Help and advice',
      href: `${basePath}/content/ao-care-help-advice/ao-faqs`,
      isHidden: false,
    },
    profile: {
      label: 'My account',
      icon: 'my-account',
      routerPath: '',
      isHidden: false,
    },
    myDetails: {
      routerPath: '/my-account/my-details',
      label: 'My details',
      icon: '',
      isHidden: false,
    },
    logout: {
      label: 'Logout',
      icon: '',
      routerPath: '',
      isHidden: false,
    },
    basket: {
      href: '/#',
      icon: 'basket',
      label: 'Basket',
      isHidden: true,
    },
    myAccountHome: {
      href: '/my-plans',
      icon: '',
      label: 'My plans',
      isHidden: false,
    },
    poweredBy: {
      href: 'https://www.domesticandgeneral.com',
      label: '',
      isHidden: false,
    },
  };
};

const footerProtectLinks = (basePath: string): NavigationLink[] => [
  {
    section: 'protect',
    label: 'Household appliances',
    href: `${basePath}/products-5/appliances-household`,
    newWindow: true,
  },
];

const footerUsefulLinks = (basePath: string): NavigationLink[] => [
  {
    section: 'useful',
    label: 'Help and advice',
    href: `${basePath}/content/ao-care-help-advice/ao-faqs`,
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Contact us',
    href: `${basePath}/content/contact-us`,
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'ao.com',
    href: 'https://ao.com/',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Regulatory information',
    href: `${basePath}/company-information-legal`,
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Modern Slavery Statement',
    href: `${basePath}/modern-slavery`,
    newWindow: true,
  },
];

const footerLegalLinks = (basePath: string): NavigationLink[] => [
  {
    section: 'legal',
    label: 'Website Terms and Conditions',
    href: `${basePath}/web-terms-conditions`,
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Privacy and Cookies Policy',
    href: `${basePath}/privacy-cookies-policy`,
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Accessibility Policy',
    href: `${basePath}/accessibility`,
    newWindow: true,
  },
];

export const DEFAULT_ENVIRONMENT: Environment = {
  claimRedirectQueryParams: 'athome_aws_bundle=[BUNDLE]',
  accountLoginUrl: '/my-account/my-plans',
  externalMaintenanceUrl: 'https://domesticandgeneral.tmtx.co.uk/en.html',
  apiVersion: 'v1',
  notAllowedUrlsForUnauthorizedRedirect: [
    'signin',
    'email/resend',
    'plans/issuereports',
    '/cancel',
    'signup-and-match',
  ],
  debugCookieName: 'ATHOME_DEBUG',
  footerLinksBuilder: (basePath: string): Footer => {
    return {
      protectLinks: footerProtectLinks(basePath),
      usefulLinks: footerUsefulLinks(basePath),
      legalLinks: footerLegalLinks(basePath),
    };
  },
  headerLinksBuilder: (basePath: string): NavigationLinks =>
    headerNavigationLinks(basePath),
  cleanLastUsedEmail: true,
  vokeraBrands: ['vokera', 'pro-combi', 'sabre'],
  requestSource: 'AOCARE',
  wlClient: 'AOCARE',
};
