<div [attr.validate]="validate" [attr.data]="data" [attr.group]="group">
  <!--JUI FORM builder component -->
  <app-legend *ngIf="data?.legend" [classes]="data.legend.classes">
    {{ data.legend.text }}
  </app-legend>
  <app-hint *ngIf="data.hint" [classes]="data.hint.classes">
    {{ data.hint.text }}
  </app-hint>
  <app-input-autocomplete
    *ngIf="data.autocomplete"
    [results]="results"
    [item]="data.autocomplete"
    [showValidation]="validate"
    [validators]="data.autocomplete.validators"
    [group]="group"
    [hint]="data.autocomplete.hint"
    [label]="data.autocomplete.label"
    [labelFor]="data.autocomplete.control"
  ></app-input-autocomplete>
  <app-inputs
    *ngIf="data.input"
    [item]="data.input"
    [attr.data-automation]="data.input.control"
    [maxlength]="data.input.maxlength"
    [showValidation]="validate"
    [validators]="data.input.validators"
    [maxLength]="data.input.maxLength"
    [group]="group"
    [hint]="data.input.hint"
    [label]="data.input.label"
    [labelFor]="data.input.control"
    [tooltip]="data.input.tooltip"
  ></app-inputs>
  <app-select
    *ngIf="data.select"
    [item]="data.select"
    [showValidation]="validate"
    [validators]="data.select.validators"
    [group]="group"
    [hint]="data.select.hint"
    [label]="data.select.label"
    [attr.data-automation]="data.select.control"
    [labelFor]="data.select.control"
  ></app-select>
  <app-radiobutton
    *ngIf="data.radios"
    [classes]="'dg-radios ' + data.radios.classes"
    [elementsPerPage]="data.radios.elementsPerPage"
    [items]="data.radios.radioGroup"
    [group]="group"
    [control]="data.radios.control"
    [attr.data-automation]="data.radios.control"
    [idPrefix]="data.radios.control"
    [validators]="data.radios.validators"
    [showValidation]="validate"
    [validationError]="data.radios.validationError"
  >
  </app-radiobutton>
  <div *ngIf="data.checkbox" class="dg-checkboxes">
    <app-checkbox
      [group]="group"
      [validate]="validate"
      [idPrefix]="data.checkbox.control"
      [attr.data-automation]="data.checkbox.control"
      [validators]="data.checkbox.validators"
      [items]="data.checkbox"
      [showValidation]="validate"
      [classes]="'dg-checkboxes__item'"
      [labelClasses]="'dg-label dg-checkboxes__label'"
    >
      <div class="dg-form-group">
        <div
          *ngIf="
            data.checkbox.groups && group.controls[data.checkbox.control].value
          "
          class="dg-checkboxes__conditional"
          [ngClass]="{ 'dg-checkboxes__invalid': validate && group.invalid }"
        >
          <ng-container *ngFor="let subgroup of data.checkbox.groups">
            <app-fieldset
              *ngIf="subgroup.fieldset"
              [validate]="validate"
              [classes]="'dg-fieldset'"
              [group]="group"
              [data]="subgroup.fieldset"
            >
            </app-fieldset>
            <app-form-elements
              [validate]="validate"
              [group]="group"
              [data]="subgroup"
            ></app-form-elements>
          </ng-container>
        </div>
      </div>
    </app-checkbox>
  </div>
  <app-textarea
    *ngIf="data.textarea"
    [classes]="'dg-textarea'"
    [showValidation]="validate"
    [attr.data-automation]="data.textarea.control"
    [validationError]="data.textarea.validationError"
    [validators]="data.textarea.validators"
    [group]="group"
    [control]="data.textarea.control"
    [placeholder]="data.textarea.placeholder"
    [label]="data.textarea.label"
    [labelFor]="data.textarea.control"
    [chrLimit]="data.textarea.chrLimit"
    [rows]="5"
  >
  </app-textarea>

  <ng-container *ngIf="data.button">
    <app-button
      *ngFor="let btn of data.button"
      class="btn.classes"
      [classes]="btn.classes"
      [type]="btn.type"
      [disabled]="btn.disabled"
      [stretch]="btn.stretch"
      [submitted]="btn.submitted"
      [attr.data-automation]="btn.control"
      [control]="btn.control"
      [eventName]="btn.eventName"
      [text]="btn.text"
    >
    </app-button>
  </ng-container>
  <!--JUI FORM builder component -->
</div>
