<div class="login-form">
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
    <div
      class="form-group"
      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
    >
      <label>{{ forms.forgotPasswordForm.emailLabel }}</label>
      <input type="text" formControlName="email" class="form-control" />
      <div *ngIf="submitted && f.email.errors" class="form-input-feedback">
        <div *ngIf="f.email.errors.required">
          {{ forms.forgotPasswordForm.requiredEmailValidation }}
        </div>
        <div *ngIf="f.email.errors.email">
          {{ forms.forgotPasswordForm.incorrectEmailFormatValidation }}
        </div>
      </div>
    </div>
    <div class="form-group">
      <button class="btn btn--primary" type="submit">
        {{ forms.forgotPasswordForm.submitButtonLabel }}
      </button>
    </div>
  </form>
  <div class="form-group">
    <button
      class="btn btn--secondary"
      *ngIf="forms.forgotPasswordForm.showBackToLoginButton"
      (click)="backToLoginClick()"
    >
      {{ forms.forgotPasswordForm.backToLoginButtonLabel }}
    </button>
  </div>
</div>
