<div
  [formGroup]="group"
  class="form-input legacy-form-builder"
  [ngClass]="{
    'is-invalid':
      !group.controls[item.control].valid &&
      group.controls[item.control]?.touched,
    'is-valid':
      group.controls[item.control]?.valid &&
      group.controls[item.control]?.touched
  }"
>
  <app-label
    *ngIf="label"
    [label]="label"
    [class]="label.classes"
    [forElement]="labelFor"
    >{{ label.text }}
  </app-label>
  <app-hint *ngIf="hint" [classes]="hint.classes">
    {{ hint.text }}
  </app-hint>
  <div class="form-control">
    <select
      [formControlName]="item.control"
      [id]="item.control"
      [class]="item.classes"
      (blur)="showValidation = true"
      [value]="initValueIndex"
    >
      <option value="0" disabled selected>Select something</option>
      <option *ngFor="let o of item.options" [value]="o.id">
        {{ o.name }} {{ o.selected }}
      </option>
    </select>
    <i class="icon icon-tick-circle"></i>
    <i class="icon icon-error-circle"></i>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <app-validation-error-formcontrol
      *ngIf="showValidation"
      [group]="group"
      [validators]="validators"
      [control]="item.control"
    ></app-validation-error-formcontrol>
  </ng-container>
</div>
