import { inject, InjectionToken } from '@angular/core';

import { NAVIGATOR } from './navigator.token';

export const USERAGENT: InjectionToken<string> = new InjectionToken(
  'User agent string',
  {
    factory: (): string => inject(NAVIGATOR).userAgent,
    providedIn: 'root',
  }
);
