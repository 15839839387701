import { HttpErrorResponse } from '@angular/common/http';
import { ApiResponseTypeEnum } from '../common/enums/api-response-type.enum';
import { User } from '../common/models/user';
import * as UserActions from './auth.actions';

export interface AuthState {
  isAuthenticated: boolean;
  createAccountResponse: ApiResponseTypeEnum;
  logInResponse: ApiResponseTypeEnum;
  forgotPasswordResponse: ApiResponseTypeEnum;
  resetPasswordResponse: ApiResponseTypeEnum;
  signOutResponse: ApiResponseTypeEnum;
  checkUserSessionResponse: ApiResponseTypeEnum;
  requestVerificationCodeResponse: ApiResponseTypeEnum;
  verificationCodeResponse: ApiResponseTypeEnum;
  verifyAddressResponse: ApiResponseTypeEnum;
  refreshUserTokenResponse: ApiResponseTypeEnum;
  clearVerifyAddressResponse: boolean;
  user: User;
  error: HttpErrorResponse;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  createAccountResponse: ApiResponseTypeEnum.NOT_CALLED,
  logInResponse: ApiResponseTypeEnum.NOT_CALLED,
  forgotPasswordResponse: ApiResponseTypeEnum.NOT_CALLED,
  resetPasswordResponse: ApiResponseTypeEnum.NOT_CALLED,
  signOutResponse: ApiResponseTypeEnum.NOT_CALLED,
  checkUserSessionResponse: ApiResponseTypeEnum.NOT_CALLED,
  requestVerificationCodeResponse: ApiResponseTypeEnum.NOT_CALLED,
  verificationCodeResponse: ApiResponseTypeEnum.NOT_CALLED,
  verifyAddressResponse: ApiResponseTypeEnum.NOT_CALLED,
  refreshUserTokenResponse: ApiResponseTypeEnum.NOT_CALLED,
  clearVerifyAddressResponse: false,
  user: null,
  error: null,
};

export function authReducer(
  state: AuthState = initialAuthState,
  action: UserActions.Actions
) {
  switch (action.type) {
    case UserActions.AuthLibActionTypes.CreateAccount:
      return {
        ...state,
        error: null,
        createAccountResponse: ApiResponseTypeEnum.LOADING,
      };
    case UserActions.AuthLibActionTypes.CreateAccountSuccess:
      return {
        ...state,
        error: null,
        createAccountResponse: ApiResponseTypeEnum.SUCCESS,
      };

    case UserActions.AuthLibActionTypes.CreateAccountFailure:
      return {
        ...state,
        error: action.payload,
        createAccountResponse: ApiResponseTypeEnum.ERROR,
      };
    case UserActions.AuthLibActionTypes.CreateAccountClear:
      return { ...state, error: null, createAccountResponse: null };

    case UserActions.AuthLibActionTypes.LogIn:
      return {
        ...state,
        error: null,
        logInResponse: ApiResponseTypeEnum.LOADING,
      };
    case UserActions.AuthLibActionTypes.LogInSuccess:
      return {
        ...state,
        error: null,
        isAuthenticated: true,
        logInResponse: ApiResponseTypeEnum.SUCCESS,
        user: action.payload,
      };
    case UserActions.AuthLibActionTypes.LogInFailure:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        logInResponse: ApiResponseTypeEnum.ERROR,
      };

    case UserActions.AuthLibActionTypes.ForgotPassword:
      return {
        ...state,
        error: null,
        forgotPasswordResponse: ApiResponseTypeEnum.LOADING,
      };
    case UserActions.AuthLibActionTypes.ForgotPasswordSuccess:
      return {
        ...state,
        error: null,
        forgotPasswordResponse: ApiResponseTypeEnum.SUCCESS,
      };
    case UserActions.AuthLibActionTypes.ForgotPasswordFailure:
      return {
        ...state,
        error: action.payload,
        forgotPasswordResponse: ApiResponseTypeEnum.ERROR,
      };
    case UserActions.AuthLibActionTypes.ForgotPasswordClear:
      return { ...state, error: null, forgotPasswordResponse: null };

    case UserActions.AuthLibActionTypes.RequestVerificationCode:
      return {
        ...state,
        error: null,
        requestVerificationCodeResponse: ApiResponseTypeEnum.LOADING,
      };
    case UserActions.AuthLibActionTypes.RequestVerificationCodeSuccess:
      return {
        ...state,
        error: null,
        requestVerificationCodeResponse: ApiResponseTypeEnum.SUCCESS,
      };
    case UserActions.AuthLibActionTypes.RequestVerificationCodeFailure:
      return {
        ...state,
        error: action.payload,
        requestVerificationCodeResponse: ApiResponseTypeEnum.ERROR,
      };

    case UserActions.AuthLibActionTypes.VerificationCode:
      return {
        ...state,
        error: null,
        verificationCodeResponse: ApiResponseTypeEnum.LOADING,
      };
    case UserActions.AuthLibActionTypes.VerificationCodeSuccess:
      return {
        ...state,
        error: null,
        verificationCodeResponse: ApiResponseTypeEnum.SUCCESS,
      };
    case UserActions.AuthLibActionTypes.VerificationCodeFailure:
      return {
        ...state,
        error: action.payload,
        verificationCodeResponse: ApiResponseTypeEnum.ERROR,
      };

    case UserActions.AuthLibActionTypes.SignOut:
      return {
        ...state,
        error: null,
        signOutResponse: ApiResponseTypeEnum.LOADING,
      };

    case UserActions.AuthLibActionTypes.ResetPassword:
      return {
        ...state,
        error: null,
        resetPasswordResponse: ApiResponseTypeEnum.LOADING,
      };
    case UserActions.AuthLibActionTypes.ResetPasswordSuccess:
      return {
        ...state,
        error: null,
        resetPasswordResponse: ApiResponseTypeEnum.SUCCESS,
      };
    case UserActions.AuthLibActionTypes.ResetPasswordFailure:
      return {
        ...state,
        error: action.payload,
        resetPasswordResponse: ApiResponseTypeEnum.ERROR,
      };

    case UserActions.AuthLibActionTypes.SignOutSuccess:
      return {
        ...state,
        error: null,
        signOutResponse: ApiResponseTypeEnum.SUCCESS,
        isAuthenticated: false,
        user: null,
      };
    case UserActions.AuthLibActionTypes.SignOutFailure:
      return {
        ...state,
        error: action.payload,
        signOutResponse: ApiResponseTypeEnum.ERROR,
      };

    case UserActions.AuthLibActionTypes.CheckUserSession:
      return {
        ...state,
        error: null,
        checkUserSessionResponse: ApiResponseTypeEnum.LOADING,
      };
    case UserActions.AuthLibActionTypes.CheckUserSessionSuccess:
      return {
        ...state,
        error: null,
        checkUserSessionResponse: ApiResponseTypeEnum.SUCCESS,
      };
    case UserActions.AuthLibActionTypes.CheckUserSessionFailure:
      return {
        ...state,
        error: action.payload,
        checkUserSessionResponse: ApiResponseTypeEnum.ERROR,
      };

    case UserActions.AuthLibActionTypes.UserSessionExpired:
      return { ...state, isAuthenticated: false };

    case UserActions.AuthLibActionTypes.UserSessionActive:
      return { ...state, isAuthenticated: true };

    case UserActions.AuthLibActionTypes.ResetApiResponseState:
      return {
        ...state,
        logInResponse: ApiResponseTypeEnum.NOT_CALLED,
        forgotPasswordResponse: ApiResponseTypeEnum.NOT_CALLED,
        resetPasswordResponse: ApiResponseTypeEnum.NOT_CALLED,
        signOutResponse: ApiResponseTypeEnum.NOT_CALLED,
        checkUserSessionResponse: ApiResponseTypeEnum.NOT_CALLED,
        verifyAddressResponse: ApiResponseTypeEnum.NOT_CALLED,
      };
    case UserActions.AuthLibActionTypes.VerifyAddress:
      return {
        ...state,
        error: null,
        verifyAddressResponse: ApiResponseTypeEnum.LOADING,
        clearVerifyAddressResponse: false,
      };
    case UserActions.AuthLibActionTypes.VerifyAddressSuccess:
      return {
        ...state,
        error: null,
        verifyAddressResponse: ApiResponseTypeEnum.SUCCESS,
        clearVerifyAddressResponse: false,
      };
    case UserActions.AuthLibActionTypes.VerifyAddressFailure:
      return {
        ...state,
        error: action.payload,
        verifyAddressResponse: ApiResponseTypeEnum.ERROR,
        clearVerifyAddressResponse: false,
      };
    case UserActions.AuthLibActionTypes.VerifyAddressClear:
      return {
        ...state,
        error: null,
        verifyAddressResponse: null,
        clearVerifyAddressResponse: true,
      };

    case UserActions.AuthLibActionTypes.RefreshUserToken:
      return {
        ...state,
        error: null,
        refreshUserTokenResponse: ApiResponseTypeEnum.LOADING,
      };
    case UserActions.AuthLibActionTypes.RefreshUserTokenSuccess:
      return {
        ...state,
        error: null,
        refreshUserTokenResponse: ApiResponseTypeEnum.SUCCESS,
      };
    case UserActions.AuthLibActionTypes.RefreshUserTokenFailure:
      return {
        ...state,
        error: action.payload,
        refreshUserTokenResponse: ApiResponseTypeEnum.ERROR,
      };

    default:
      return state;
  }
}
