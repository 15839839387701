import { Action } from '@ngrx/store';
import {
  GetMarketingPreferenceResponse,
  MarketingPreferences,
  MarketingPreferenceUpdate,
  UpdateContactDetailsPayload,
  UpdateDocPreferencePayload,
} from '@athome-myaccount/my-account/util-login';
import { UpdateCorrespondenceAddressPayload } from '@athome-myaccount/my-account/util-login';
import { GetPersonalDetailsResponse } from '@athome-myaccount/my-account/util-login';

export enum PersonalDetailsActionTypes {
  GetPersonDetails = '[Personal Details] Get Person Details',
  GetPersonDetailsSuccess = '[Personal Details] Get Person Details Success',
  GetPersonDetailsFailure = '[Personal Details] Get Person Details Failure',

  UpdateContactDetails = '[Personal Details] Update Contact Details',
  UpdateContactDetailsSuccess = '[Personal Details] Update Contact Details Success',
  UpdateContactDetailsFailure = '[Personal Details] Update Contact Details Failure',

  UpdateCorrespondenceAddress = '[Personal Details] Update Correspondence Address',
  UpdateCorrespondenceAddressSuccess = '[Personal Details] Update Correspondence Address Success',
  UpdateCorrespondenceAddressFailure = '[Personal Details] Update Correspondence Address Failure',

  UpdatePassword = '[Personal Details] Update user password',
  UpdatePasswordSuccess = '[Personal Details] Update user password success',
  UpdatePasswordFailure = '[Personal Details] Update user password failure',

  GetMarketingPreference = '[Personal Marketing Preference] Get Person Marketing Preference',
  GetMarketingPreferenceSuccess = '[Personal Marketing Preference] Get Person Marketing Preference Success',
  GetMarketingPreferenceFailure = '[Personal Marketing Preference] Get Person Marketing Preference Failure',

  UpdateMarketingPreference = '[Personal Details] Update user MarketingPreference',
  UpdateMarketingPreferenceSuccess = '[Personal Details] Update user MarketingPreference success',
  UpdateMarketingPreferenceFailure = '[Personal Details] Update user MarketingPreference failure',

  ResetPersonalDetailsState = '[Personal Details] ResetPersonalDetailsState',

  UpdateDocPreference = '[Personal Details] Update Document Preference',
  UpdateDocPreferenceSuccess = '[Personal Details] Update Document Preference Success',
  UpdateDocPreferenceFailure = '[Personal Details] Update Document Preference Failure',
}

export class GetPersonDetails implements Action {
  readonly type = PersonalDetailsActionTypes.GetPersonDetails;
}

export class GetPersonDetailsSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.GetPersonDetailsSuccess;
  constructor(public payload: { response: GetPersonalDetailsResponse }) {}
}

export class GetPersonDetailsFailure implements Action {
  readonly type = PersonalDetailsActionTypes.GetPersonDetailsFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateContactDetails implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateContactDetails;
  constructor(public payload: UpdateContactDetailsPayload) {}
}

export class UpdateContactDetailsSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateContactDetailsSuccess;
  constructor(public payload: { response: any }) {}
}

export class UpdateContactDetailsFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateContactDetailsFailure;
  constructor(public payload: { error: any }) {}
}

export class UpdateCorrespondenceAddress implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateCorrespondenceAddress;
  constructor(public payload: UpdateCorrespondenceAddressPayload) {}
}

export class UpdateCorrespondenceAddressSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateCorrespondenceAddressSuccess;
  constructor(public payload: { response: any }) {}
}

export class UpdateCorrespondenceAddressFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateCorrespondenceAddressFailure;
  constructor(public payload: { error: any }) {}
}

export class ResetPersonalDetailsState implements Action {
  readonly type = PersonalDetailsActionTypes.ResetPersonalDetailsState;
}

export class UpdatePassword implements Action {
  readonly type = PersonalDetailsActionTypes.UpdatePassword;
  constructor(public payload: { password: string }) {}
}

export class UpdatePasswordSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdatePasswordSuccess;
}

export class UpdatePasswordFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdatePasswordFailure;
  constructor(public payload: Error) {}
}

export class GetMarketingPreference implements Action {
  readonly type = PersonalDetailsActionTypes.GetMarketingPreference;
}
export class GetMarketingPreferenceSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.GetMarketingPreferenceSuccess;
  constructor(public payload: { response: GetMarketingPreferenceResponse }) {}
}

export class GetMarketingPreferenceFailure implements Action {
  readonly type = PersonalDetailsActionTypes.GetMarketingPreferenceFailure;
  constructor(public payload: Error) {}
}

export class UpdateMarketingPreference implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateMarketingPreference;
  constructor(public payload: { preference: MarketingPreferenceUpdate }) {}
}

export class UpdateMarketingPreferenceSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateMarketingPreferenceSuccess;
  constructor(public payload: { response: MarketingPreferenceUpdate }) {}
}

export class UpdateMarketingPreferenceFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateMarketingPreferenceFailure;
  constructor(public payload: Error) {}
}

export class UpdateDocPreference implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateDocPreference;
  constructor(public payload: UpdateDocPreferencePayload) {}
}

export class UpdateDocPreferenceSuccess implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateDocPreferenceSuccess;
  constructor(public payload: { response: any }) {}
}

export class UpdateDocPreferenceFailure implements Action {
  readonly type = PersonalDetailsActionTypes.UpdateDocPreferenceFailure;
  constructor(public payload: { error: any }) {}
}

export type PersonalDetailsActions =
  | GetPersonDetails
  | GetPersonDetailsSuccess
  | GetPersonDetailsFailure
  | UpdateContactDetailsSuccess
  | UpdateContactDetailsFailure
  | UpdateCorrespondenceAddressSuccess
  | UpdateCorrespondenceAddressFailure
  | UpdateContactDetails
  | UpdateCorrespondenceAddress
  | ResetPersonalDetailsState
  | UpdatePassword
  | UpdatePasswordFailure
  | UpdatePasswordSuccess
  | GetMarketingPreference
  | GetMarketingPreferenceSuccess
  | GetMarketingPreferenceFailure
  | UpdateMarketingPreference
  | UpdateMarketingPreferenceSuccess
  | UpdateMarketingPreferenceFailure
  | UpdateDocPreferenceSuccess
  | UpdateDocPreferenceFailure
  | UpdateDocPreference;
