export enum AlertType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum VerificationModeType {
  HOME_ADDRESS = 'HOME_ADDRESS',
  PLAN_NUMBER = 'PLAN_NUMBER',
  NONE = 'NONE',
}

export class ModalAlertData {
  title: string;
  alertType: AlertType;
  closeButtonLabel: string;
  content: ModalContent;

  constructor(data?) {
    if (data) {
      this.title = data.title;
      this.content = data.content;
      this.alertType = data.alertType;
      this.closeButtonLabel = data.closeButtonLabel;
    }
  }
}

export class ModalContent {
  content: string;
  html?: boolean;
}

export class ModalConfirmData {
  title: string;
  content: string;
  confirmButtonLabel: string;
  closeButtonLabel: string;

  constructor(data?) {
    if (data) {
      this.title = data.title;
      this.content = data.content;
      this.confirmButtonLabel = data.confirmButtonLabel;
      this.closeButtonLabel = data.closeButtonLabel;
    }
  }
}
