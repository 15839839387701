import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthService {
  emailAddress: string | null;
  token: string;

  constructor() {
    if (localStorage && localStorage.getItem('emailAddress') !== null) {
      this.emailAddress = localStorage.getItem('emailAddress');
    }
  }
}
