import { HeaderComponent } from './header/header.component';
import { AccordionComponent } from './accordion/accordion.component';
import { FooterComponent } from './footer/footer.component';
import { HeroComponent } from './hero/hero.component';
import { LoaderComponent } from './loader/loader.component';
import { SelectComponent } from './select/select.component';
import { SvgComponent } from './svg/svg.component';

// child components
import {
  AccordionTemplateDirective,
  AccordionHeaderComponent,
  AccordionTabComponent,
} from './accordion/accordion-tab/accordion-tab.component';

export const components: any[] = [
  AccordionComponent,
  AccordionTemplateDirective,
  AccordionHeaderComponent,
  AccordionTabComponent,
  FooterComponent,
  HeroComponent,
  HeaderComponent,
  LoaderComponent,
  SelectComponent,
  SvgComponent,
];

export * from './accordion/accordion.component';
export * from './accordion/accordion-tab/accordion-tab.component';
export * from './footer/footer.component';
export * from './hero/hero.component';
export * from './loader/loader.component';
export * from './select/select.component';
export * from './header/header.component';
export * from './svg/svg.component';
