import { inject, InjectionToken } from '@angular/core';

import { USERAGENT } from './user-agent.token';

export const MOBILE_DEVICE: InjectionToken<boolean> = new InjectionToken(
  'Safari Browser flag',
  {
    factory: (): boolean =>
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        inject(USERAGENT)
      ),
    providedIn: 'root',
  }
);
