import {AfterViewInit,Component,Input,OnChanges} from '@angular/core';
import {
  passwordDefaults,
  validatePassword
} from '@athome-myaccount/shared/util';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-password-strength-checker',
  templateUrl: './password-strength-checker.component.html',
  styleUrls: ['./password-strength-checker.component.scss'],
})
export class PasswordStrengthCheckerComponent implements OnChanges, AfterViewInit {
  passwordValidator = passwordDefaults;

  @Input() value = '';

  @Input() showLength = false;
  @Input() showUppercase = false;
  @Input() showLowercase = false;
  @Input() showNumber = false;
  @Input() showComplex = false;

  @Input() lengthError = false;
  @Input() upperCaseError = false;
  @Input() lowerCaseError = false;
  @Input() numberError = false;
  @Input() showComplexError = false;
  @Input() submitted = false;

  public translations = {
    passwordComplexCaseLabel: this.translateService.instant(
      'IAM_Migration_And_Login_v0.6.Password_Reset_Page.5e'
    ),
    passwordNumber: this.translateService.instant(
      'IAM_Migration_And_Login_v0.6.Password_Reset_Page.5d'
    ),
    passwordLowerCase: this.translateService.instant(
      'IAM_Migration_And_Login_v0.6.Password_Reset_Page.5c'
    ),
    passwordUpperCase: this.translateService.instant(
      'IAM_Migration_And_Login_v0.6.Password_Reset_Page.5b'
    ),
    passwordLength: this.translateService.instant(
      'IAM_Migration_And_Login_v0.6.Password_Reset_Page.5a'
    ),
  };

  constructor(private translateService: TranslateService) {}

  ngAfterViewInit(): void {
    this.checkValue();
  }

  ngOnChanges() {
    this.checkValue();
  }

  checkValue() {
    const result = validatePassword(this.value);
    this.passwordValidator = {
      passwordLength: result.passwordLength,
      passwordLowerCase: result.passwordLowerCase,
      passwordUpperCase: result.passwordUpperCase,
      passwordNumber: result.passwordNumber,
      passwordComplexCase: result.passwordComplexCase,
    };
  }
}
