<mat-dialog-content>
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="20px">
      <div
        class="success-container"
        [ngClass.xs]="'success-container-mobile'"
        fxLayout="row"
        fxLayoutAlign="start stretch"
        fxLayoutGap="20px"
        fxLayoutGap.xs="15px"
      >
        <div
          class="logo-section"
          fxLayout="column"
          fxLayoutAlign="start center"
          [ngSwitch]="data.alertType"
        >
          <img
            *ngSwitchCase="AlertType.ERROR"
            src="./assets/images/warning-icon.png"
            alt="There was an error completing the task"
          />
          <img
            *ngSwitchCase="AlertType.SUCCESS"
            src="./assets/images/success-icon.png"
            alt="Task completed successfully"
          />
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          fxFlex="1 1 0%"
        >
          <div class="text-section__title typog-body-large-bold">
            {{ data.title }}
          </div>
          <div class="text-section__content">
            <span *ngIf="!data.content.html">{{ data.content.content }}</span>
            <span
              *ngIf="data.content.html"
              [innerHTML]="data.content.content"
            ></span>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            fxShow.gt-xs
            fxHide
            *ngIf="data?.closeButtonLabel"
          >
            <div class="cta-container-button">
              <app-button
                [text]="data.closeButtonLabel"
                classes="btn btn--secondary"
                (click)="handleClose()"
              ></app-button>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="1 1 0%">
        <div
          class="close-icon"
          fxFlexAlign="end"
          (click)="handleClose()"
          fxShow
          fxHide.xs="true"
        ></div>
      </div>
    </div>
    <div
      fxLayout="column"
      fxLayoutAlign="center stretch"
      fxShow.xs
      fxHide
      *ngIf="data?.closeButtonLabel"
    >
      <div class="cta-container-button">
        <app-button
          [text]="data.closeButtonLabel"
          classes="btn btn--secondary"
          (click)="handleClose()"
        ></app-button>
      </div>
    </div>
  </div>
</mat-dialog-content>
