import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { UserRegistrationStatusEnum } from '../enums/user-registration-status.enum';
import { User } from '../models/user';

@Injectable()
export class UserAuthGuard implements CanActivate {
  constructor(private cookieService: CookieService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const redirectUrl = next.data['redirectUrl'] as string;
    const user: User | null = jwt_decode(this.cookieService.get('AWS_IDT'));
    return this.canActivateUser(user, redirectUrl);
  }

  private canActivateUser(user: User, redirectUrl: string): any {
    return user &&
      user['custom:user_state'] === UserRegistrationStatusEnum.COMPLETE
      ? true
      : this.router.createUrlTree([redirectUrl]);
  }
}
