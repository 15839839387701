import { Action } from '@ngrx/store';
import {
  CrossSell,
  Plan,
  ProcessedPlan,
  PlanDetailApiResponse,
} from '../../../../../util-account/src/index';

export enum AccountActionTypes {
  LoadPlans = '[Account] Load Plans',
  LoadPlansSuccess = '[Account] Load Plans Success',
  LoadPlansFailure = '[Account] Load Plans Failure',
  ProcessPlan = '[Account] Process Plan',
  SetSelectedPlan = '[Plan Detail Resolver] Select Plan',
  LoadPlanDetail = '[Plan Detail Resolver] Load selected plan detail',
  SelectPlan = '[Plan Detail Guard] Select Plan',
  LoadPlanDetailSuccess = '[Plan API] Plan detail loaded successfully',
  LoadPlanDetailFailure = '[Plan API] Error loading plan detail',
  CancelRepair = '[Plan Details Page] Cancel an open repair',
  CancelRepairSuccess = '[Plan API] Open repair cancelled successfully',
  CancelRepairFailure = '[Plan API] Cancelling an open repair failed',
  CancelService = '[Plan Details Page] Cancel an open service',
  CancelServiceSuccess = '[Plan API] Open service cancelled successfully',
  CancelServiceFailure = '[Plan API] Cancelling an open service failed',
  ExitingPlanDetailsPage = '[Plan Details Page] Exiting Plan Details',
  ResendPlanEmail = '[Plan Details Page] Resend Plan Email',
  ResendPlanEmailSuccess = '[Plan API] Plan email sent successfully',
  ResendPlanEmailFailure = '[Plan API] Resending plan email failed',
  RepairBundle = '[Request Repair CTA] Request repair bundle for claims journey',
  RepairBundleReset = '[Request Repair CTA] Reset repair claim bundle state',
  CompleteFormRepairBundle = '[Continue Form CTA] Request repair bundle for claims journey',
  CompleteFormRepairBundleReset = '[Continue Form CTA] Reset repair claim bundle state',
  ContinueRepairBundle = '[Book Engineer CTA] Request repair bundle for claims journey',
  ContinueRepairBundleReset = '[Book Engineer CTA] Reset repair claim bundle state',
  RepairBundleSuccess = '[Plan API] Bundle for repair claim fetched successfully',
  RepairBundleFailure = '[Plan API] Bundle request for repair claim failed',
  RebookRepairBundle = '[Track Repair CTA] Request repair bundle for re-booking repair',
  RebookRepairBundleSuccess = '[Plan API] Bundle for re-booking repair fetched successfully',
  RebookRepairBundleFailure = '[Plan API] Bundle fetch for repair re-booking failed',
  RebookRepairBundleReset = '[Track Repair CTA] Reset state repair re-booking',
  ResetAccountState = '[Account] Reset Account State',
  MaintenanceTipClicked = '[Plan Details Page] Maintenance tip clicked',
  ReportIssue = '[Dashboard Page] Report an issue',
  ReportIssueSuccess = '[Plan API] Issue reported successfully',
  ReportIssueFailure = '[Plan API] Issue reporting failed',
  ResetReportIssue = '[Dashboard Page] Reset report issue state',
}

export class ResetAccountState implements Action {
  readonly type = AccountActionTypes.ResetAccountState;
}

export class LoadPlans implements Action {
  readonly type = AccountActionTypes.LoadPlans;
}

export class LoadPlansSuccess implements Action {
  readonly type = AccountActionTypes.LoadPlansSuccess;
  constructor(public payload: { plans: Plan[]; crossSell: CrossSell }) {}
}

export class LoadPlansFailure implements Action {
  readonly type = AccountActionTypes.LoadPlansFailure;
  constructor(public payload: { error: any }) {}
}

export class ProcessPlan implements Action {
  readonly type = AccountActionTypes.ProcessPlan;
  constructor(
    public payload: {
      processedPlan: ProcessedPlan;
    }
  ) {}
}

export class LoadPlanDetail implements Action {
  readonly type = AccountActionTypes.LoadPlanDetail;
}

export class SelectPlan implements Action {
  readonly type = AccountActionTypes.SelectPlan;
  constructor(public payload: { planNumber: string }) {}
}

export class LoadPlanDetailSuccess implements Action {
  readonly type = AccountActionTypes.LoadPlanDetailSuccess;
  constructor(public planDetail: PlanDetailApiResponse) {}
}

export class LoadPlanDetailFailure implements Action {
  readonly type = AccountActionTypes.LoadPlanDetailFailure;
  constructor(public payload: Error) {}
}

export class ReportIssue implements Action {
  readonly type = AccountActionTypes.ReportIssue;
  constructor(public issue: string) {}
}

export class ResetReportIssue implements Action {
  readonly type = AccountActionTypes.ResetReportIssue;
}

export class ReportIssueSuccess implements Action {
  readonly type = AccountActionTypes.ReportIssueSuccess;
}

export class ReportIssueFailure implements Action {
  readonly type = AccountActionTypes.ReportIssueFailure;
  constructor(public payload: Error) {}
}

export class ResendPlanEmail implements Action {
  readonly type = AccountActionTypes.ResendPlanEmail;
  constructor(public email: string) {}
}

export class ResendPlanEmailSuccess implements Action {
  readonly type = AccountActionTypes.ResendPlanEmailSuccess;
  constructor(public email: string) {}
}

export class ResendPlanEmailFailure implements Action {
  readonly type = AccountActionTypes.ResendPlanEmailFailure;
  constructor(public payload: Error) {}
}

export class CancelRepair implements Action {
  readonly type = AccountActionTypes.CancelRepair;
  constructor(public openRepairId: string) {}
}

export class CancelRepairSuccess implements Action {
  readonly type = AccountActionTypes.CancelRepairSuccess;
  constructor(public planDetail: PlanDetailApiResponse) {}
}

export class CancelRepairFailure implements Action {
  readonly type = AccountActionTypes.CancelRepairFailure;
  constructor(public payload: Error) {}
}

export class CancelService implements Action {
  readonly type = AccountActionTypes.CancelService;
  constructor(public openServiceId: string) {}
}

export class CancelServiceSuccess implements Action {
  readonly type = AccountActionTypes.CancelServiceSuccess;
  constructor(public planDetail: PlanDetailApiResponse) {}
}

export class CancelServiceFailure implements Action {
  readonly type = AccountActionTypes.CancelServiceFailure;
  constructor(public payload: Error) {}
}

export class ExitingPlanDetailsPage implements Action {
  readonly type = AccountActionTypes.ExitingPlanDetailsPage;
}

export class RepairBundle implements Action {
  readonly type = AccountActionTypes.RepairBundle;
}

export class RepairBundleReset implements Action {
  readonly type = AccountActionTypes.RepairBundleReset;
}

export class CompleteFormRepairBundle implements Action {
  readonly type = AccountActionTypes.CompleteFormRepairBundle;
}

export class CompleteFormRepairBundleReset implements Action {
  readonly type = AccountActionTypes.CompleteFormRepairBundleReset;
}

export class ContinueRepairBundle implements Action {
  readonly type = AccountActionTypes.ContinueRepairBundle;
}

export class ContinueRepairBundleReset implements Action {
  readonly type = AccountActionTypes.ContinueRepairBundleReset;
}

export class RepairBundleSuccess implements Action {
  readonly type = AccountActionTypes.RepairBundleSuccess;
  constructor(public bundle: string, public redirectUrl: string) {}
}

export class RepairBundleFailure implements Action {
  readonly type = AccountActionTypes.RepairBundleFailure;
  constructor(public payload: Error) {}
}

export class RebookRepairBundle implements Action {
  readonly type = AccountActionTypes.RebookRepairBundle;
  constructor(public claimId: string) {}
}

export class RebookRepairBundleSuccess implements Action {
  readonly type = AccountActionTypes.RebookRepairBundleSuccess;
  constructor(public bundle: string, public redirectUrl: string) {}
}

export class RebookRepairBundleFailure implements Action {
  readonly type = AccountActionTypes.RebookRepairBundleFailure;
  constructor(public payload: Error) {}
}

export class RebookRepairBundleReset implements Action {
  readonly type = AccountActionTypes.RebookRepairBundleReset;
}

export class MaintenanceTipClicked implements Action {
  readonly type = AccountActionTypes.MaintenanceTipClicked;
}

export type AccountActions =
  | LoadPlans
  | LoadPlansSuccess
  | LoadPlansFailure
  | ProcessPlan
  | LoadPlanDetailSuccess
  | LoadPlanDetailFailure
  | LoadPlanDetail
  | CancelService
  | CancelServiceSuccess
  | CancelServiceFailure
  | CancelRepair
  | CancelRepairSuccess
  | CancelRepairFailure
  | ResendPlanEmail
  | ResendPlanEmailSuccess
  | ResendPlanEmailFailure
  | ExitingPlanDetailsPage
  | RebookRepairBundle
  | RebookRepairBundleSuccess
  | RebookRepairBundleFailure
  | RebookRepairBundleReset
  | RepairBundle
  | ContinueRepairBundle
  | CompleteFormRepairBundle
  | RepairBundleReset
  | ContinueRepairBundleReset
  | CompleteFormRepairBundleReset
  | RepairBundleSuccess
  | RepairBundleFailure
  | ResetAccountState
  | MaintenanceTipClicked
  | SelectPlan
  | ReportIssue
  | ReportIssueSuccess
  | ReportIssueFailure
  | ResetReportIssue;
