import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordResetComponent } from './password-reset.component';
import { PasswordStrengthCheckerComponent } from './password-strength-checker/password-strength-checker.component';

@NgModule({
  declarations: [PasswordResetComponent, PasswordStrengthCheckerComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [PasswordResetComponent, PasswordStrengthCheckerComponent],
})
export class PasswordResetModule {}
