<mat-dialog-content style="width: 600px">
  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap.gt-xs="20px">
      <div
        class="success-container"
        [ngClass.xs]="'success-container-mobile'"
        fxLayout="row"
        fxLayoutAlign="start stretch"
        fxLayoutGap="20px"
        fxLayoutGap.xs="15px"
      >
        <div
          class="logo-section"
          fxLayout="column"
          fxLayoutAlign="start center"
        >
          <img
            src="./assets/images/warning-icon.png"
            alt="There was an error completing the task"
          />
        </div>
        <div
          class="text-section"
          fxLayout="column"
          fxLayoutAlign="center stretch"
          fxFlex="1 1 0%"
        >
          <div class="text-section__title typog-body-large-bold">
            That email address has an account
            <svg-icon
              name="modal-close-icon"
              class="close-icon"
              (click)="closeModal()"
            ></svg-icon>
            <p class="mt-1">
              It looks like you've already got an account with this email
              address. If you know your password, you can log in. If not, let's
              reset your password.
            </p>
          </div>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap.gt-xs="20px"
          >
            <app-button
              [text]="'Back to login'"
              classes="btn btn--primary mt-1"
              (click)="handleBackToLogin()"
            ></app-button>

            <app-button
              (click)="handleResetPassword()"
              [text]="'Reset password'"
              classes="btn btn--secondary"
            ></app-button>
          </div>
        </div>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap.gt-xs="20px"
    ></div>
  </div>
</mat-dialog-content>
