import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserStateTypeEnum } from '@athome-myaccount/my-account/util-shared';
import { LoginService } from '@athome-myaccount/my-account/data-access-login';
import { CONFIG } from '@athome-myaccount/my-account/util-core';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import { AppConfig } from '@athome-myaccount/my-account/util-core';

@Component({
  selector: 'app-error-base',
  template: '',
})
export class ErrorBaseComponent {
  domesticAndGeneralUrl = this.config.domesticAndGeneralUrl;
  constructor(
    private router: Router,
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(CONFIG) private readonly config: AppConfig,
    private loginService: LoginService,
    protected analyticsService: AnalyticsService
  ) {}

  handleHomeClick() {
    const user = this.loginService.getApplicationUser();
    const isUserVerificationComplete =
      this.loginService.getUserVerificationCompleteStatus();

    if (isUserVerificationComplete) {
      // if user has successfully logged in for the first time redirect to dashboard
      this.router.navigate(['/my-plans']);
    } else if (
      user &&
      user['custom:user_state'] === UserStateTypeEnum.COMPLETE
    ) {
      // if user has completed the registration process redirect to dashboard
      this.router.navigate(['/my-plans']);
    } else {
      // if user has not completed the registration process redirect to WL home or D&G home page
      window.location.href =
        this.config.homepageAppHost || this.config.domesticAndGeneralUrl;
    }
  }

  handleContactUsClick() {
    this.document.location.href = this.config.contactUsUrl;
  }
}
