import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-error-cta',
  templateUrl: './error-cta.component.html',
  styleUrls: ['./error-cta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorCtaComponent {
  @Output() contactUsClicked = new EventEmitter<any>();
  @Output() homeClicked = new EventEmitter<any>();

  handleHomeClick() {
    this.contactUsClicked.emit();
  }

  handleContactUsClick() {
    this.homeClicked.emit();
  }
}
