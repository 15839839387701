import * as faker from 'faker';

import {
  APPLIANCES_CODE_IMAGES,
  APPLIANCES_ICONS,
} from '../constants/appliance-images';
import {
  ApplianceTypeEnum,
  PlanActionButtonTypeEnum,
  PlanStateTypeEnum,
} from '../enums/applianceTypeEnum';
import { PlanType } from '../state/plan-detail.types';

export interface ApplianceViewModel {
  type: string;
  code?: string;
  createdOn: string;
  brand: string;
  model: string;
  modelNumber: string;
  icon: string;
  image: string;
  planNo: string;
  actionButtons: Array<PlanActionButtonTypeEnum>;
  planState: string;
  parentPlanNumber?: string;
  orderByPlanStateComparerValue: number;
}

export interface ProcessedAppliance {
  appliance: ApplianceViewModel;
  selectedAction: PlanActionButtonTypeEnum;
}

export interface ProcessedPlan {
  plan: Plan;
  selectedAction: PlanActionButtonTypeEnum;
}

export interface Claim {
  id: string;
  status: string;
  date: string;
}

export interface Plan {
  plan_number: string;
  plan_state: string;
  plan_note?: string;
  plan_start: string;
  plan_expiry: string;
  plan_owner_type: PlanType;
  plan_contract_type: string;
  parent_plan_number?: string;
  parent_plan_state?: string;
  repair_action: string;
  service_action: string;
  open_repair_id?: string;
  open_service_id?: string;
  // in_warranty_contact_name?: string, // TODO WL: These were missing from the getPlans API response so I added them here uncommented for now
  // in_warranty_contact_tel?: string,
  item: Item;
}

export interface CrossSell {
  decision?: boolean;
  store?: string;
  target_request_action?: string;
  target_request_source?: string;
}

export interface GetPlansResponse {
  plans: Plan[];
  cross_sell?: CrossSell;
}

export interface Item {
  manufacturer: string;
  model: string;
  model_number: string;
  code: string;
  code_description: string;
}

export interface ApiResponse<R> {
  // TODO WL: Duplicate interface, might want to move this to util-core
  status: string;
  result: R;
}

export const generateRandomItem = (): Item => {
  return {
    manufacturer: faker.random.arrayElement(['HOTPOINT', 'BEKO', 'APPLE']),
    model: faker.random.arrayElement(['', faker.finance.account()]),
    code: null,
    code_description: faker.random.objectElement(APPLIANCES_CODE_IMAGES),
    model_number: faker.random.arrayElement(['', faker.finance.account()]),
  };
};

export const generateRandomClaim = (): Claim => {
  return {
    id: faker.random.number().toString(),
    status: faker.random.arrayElement([
      'Booking in progress',
      'Repair request cancelled',
      'Repair in progress',
      'Repair booking needed',
      'Manual Referral',
      'Active Repair',
    ]),
    date: faker.date.past(1).toISOString().split('T')[0],
  };
};

export const generateRandomClaims = (): Array<Claim> => {
  return Array.from(
    Array(faker.random.number({ min: 1, max: 6 })),
    (x, index) => generateRandomClaim()
  );
};

export const generateRandomPlan = (): Plan => {
  return {
    plan_number: faker.finance.account(),
    plan_state: faker.random.arrayElement(
      Object.keys(PlanStateTypeEnum).filter(
        (obj) => obj !== PlanStateTypeEnum.ALL
      )
    ),
    parent_plan_number: faker.random.arrayElement([null, '1234']),
    plan_expiry: faker.date.past(1).toISOString().split('T')[0],
    item: generateRandomItem(),
    repair_action: 'REQUEST_REPAIR',
    service_action: 'NO_ACTION',
    plan_contract_type: '',
    plan_owner_type: 'CUSTOMER',
    plan_start: faker.date.past(10).toISOString().split('T')[0],
    plan_note: '',
  };
};

export const generateGetPlansResponse = (): ApiResponse<GetPlansResponse> => {
  return {
    status: 'OK',
    result: {
      plans: Array.from(
        Array(faker.random.number({ min: 60, max: 120 })),
        (x, index) => generateRandomPlan()
      ),
      cross_sell: {
        decision: false,
        target_request_action: 'DandGUK',
        target_request_source: 'QandB',
      },
    },
  };
};

export const generateRandomAppliance = (): ApplianceViewModel => {
  return {
    type: faker.random.arrayElement(Object.values(ApplianceTypeEnum)),
    code: 'U2', // PD changes
    createdOn: faker.date.past(1).toISOString().split('T')[0],
    brand: faker.random.arrayElement(['HOTPOINT', 'BEKO', 'APPLE']),
    model: faker.random.arrayElement(['', faker.finance.account()]),
    modelNumber: faker.random.arrayElement(['', faker.finance.account()]),
    icon: faker.random.arrayElement(APPLIANCES_ICONS),
    image: faker.random.objectElement(APPLIANCES_CODE_IMAGES),
    planNo: faker.random.arrayElement(['', faker.finance.account()]),
    actionButtons: faker.random.arrayElement([
      Object.values(PlanActionButtonTypeEnum),
    ]),
    planState: null,
    orderByPlanStateComparerValue: 0,
    parentPlanNumber: '',
  };
};
