export type PaymentMethod = 'CREDIT_CARD' | 'DIRECT_DEBIT';

export type RepairAction =
  | 'REQUEST_REPAIR'
  | 'BOOK_ENGINEER'
  | 'INCOMPLETE_FORM'
  | 'TRACK_REPAIR'
  | 'ON_HOLD'
  | 'REFERRED'
  | 'DIRECT_CALL_CENTRE'
  | 'VIEW_OFFER'
  | 'IN_WAIT'
  | 'IN_WARRANTY'
  | 'UNKNOWN'
  | 'NO_ACTION'
  | 'DIRECT_REFERRAL_ONLY';

export type ClaimType = 'SERVICE' | 'REPAIR';

// eslint-disable-next-line max-len
export type ServiceAction =
  | 'REQUEST_SERVICE'
  | 'INCOMPLETE_FORM'
  | 'AMEND_SERVICE'
  | 'BOOK_ENGINEER'
  | 'DIRECT_CALL_CENTRE'
  | 'NO_ACTION'
  | 'IN_WAIT'
  | 'IN_WARRANTY'
  | 'UNKNOWN'
  | 'DIRECT_REFERRAL_ONLY';

export type PlanState = 'COVERED' | 'LAPSED' | 'CANCELLED' | 'RENEWAL';

export type PlanType = 'CUSTOMER' | 'LANDLORD';

export type ClaimStatus = 'OPEN' | 'CLOSED';

export type RepairPayment = 'REQUIRED' | 'OPTIONAL' | 'NO';

export type RepairPaymentType = 'EXCESS' | 'CALLOUT';
