import { inject, InjectionToken } from '@angular/core';

import { USERAGENT } from './user-agent.token';

export const IE11: InjectionToken<boolean> = new InjectionToken(
  'Internet Explorer 11 flag',
  {
    factory: (): boolean => /Trident\/7\.0.+rv:11\.0/.test(inject(USERAGENT)),
    providedIn: 'root',
  }
);
