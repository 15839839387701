// export interface Contact {
//   mobilePhoneNumber?: string;
//   landlinePhoneNumber?: string;
//   email?: string;
// }

export interface UpdateContactDetailsPayload {
  contact: {
    mobilePhoneNumber?: string;
    landlinePhoneNumber?: string;
    email?: string;
  };
}
