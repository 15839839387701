import { Injectable } from '@angular/core';

export enum LocalStorageKeys {
  LastUsedEmailAddress = 'D&GLastUsedEmailAddress',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageManagerService {
  getLastUsedEmailAddress(): string {
    return localStorage.getItem(LocalStorageKeys.LastUsedEmailAddress);
  }

  setLastUsedEmailAddress(emailAddress: string): void {
    localStorage.setItem(LocalStorageKeys.LastUsedEmailAddress, emailAddress);
  }
}
