import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  SharedAoComponentsModule,
  HeaderModule,
} from '@domgen/dgx-ui-whitelabel-components';
import {
  MyAccountFeatureSharedModule,
  UserAuthGuard,
} from '@athome-myaccount/my-account/feature-shared';
import {
  // AppComponent,
  AoDmaFeatureShellModule,
  AoDmaFeatureShellRoutingModule,
} from '@athome-myaccount/ao-dma/feature-shell';
import { SharedFeatureAuthenticationModule } from '@dgx-auth-lib';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxSpinnerModule } from 'ngx-spinner';
import { gaTagsConfig } from './ga-tags-config/ga-tags-config';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { RecaptchaV3Module } from 'ng-recaptcha';

const authLibConfig: any = {
  urlsConfigJsonPath: '/config.json',
  forms: {
    loginForm: {
      emailLabel: 'Email',
      requiredEmailValidation: 'Email is required',
      incorrectEmailFormatValidation: 'You must provide a valid email address',
      passwordLabel: 'Password',
      showPasswordLabel: 'Show',
      hidePasswordLabel: 'Hide',
      requiredPasswordValidation: 'Password is required',
      logInButtonLabel: 'Log in',
      forgotPasswordLinkLabel: 'Forgot your password?',
    },
    forgotPasswordForm: {
      emailLabel: 'Email',
      requiredEmailValidation: 'Email is required',
      incorrectEmailFormatValidation: 'You must provide a valid email address',
      emailPlaceholderLabel: '',
      submitButtonLabel: 'Submit',
      showBackToLoginButton: true,
      backToLoginButtonLabel: 'Back To Login',
    },
  },
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AoDmaFeatureShellRoutingModule,
    MyAccountFeatureSharedModule,
    SharedAoComponentsModule,
    HeaderModule,
    AoDmaFeatureShellModule.forRoot(environment, { gaTagsConfig }),
    StoreDevtoolsModule.instrument({
      name: 'Domestic & General',
      maxAge: 25,
      logOnly: environment.production,
    }),
    // Turned off for now
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: environment.production
    // }),
    SharedFeatureAuthenticationModule.forRoot(authLibConfig),
    RecaptchaV3Module,
    NgxSpinnerModule,
  ],
  providers: [UserAuthGuard],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
