import { Injectable } from '@angular/core';
import {
  MarketingPreferenceUpdate,
  UpdateDocPreferencePayload,
} from '@athome-myaccount/my-account/util-login';
import { select, Store } from '@ngrx/store';
import { RemoteData } from 'ngx-remotedata';
import { Observable } from 'rxjs';

import {
  UpdateDocPreference,
  UpdateMarketingPreference,
} from './actions/personal-details.actions';
import { PersonalDetailsState } from './reducers/personal.details.reducer';
import {
  selectUpdateDocumentPreferenceResponse,
  selectUpdateMarketingPreferenceResponse,
} from './selectors/personal-details.selectors';

@Injectable({
  providedIn: 'root',
})
export class PersonalDetailsFacadeService {
  updateDocumentPreference$: Observable<RemoteData<any, Error>> =
    this.store.pipe(select(selectUpdateDocumentPreferenceResponse));

  updateMarketingPreference$: Observable<RemoteData<any, Error>> =
    this.store.pipe(select(selectUpdateMarketingPreferenceResponse));

  constructor(private store: Store<PersonalDetailsState>) {}

  updateDocPreference(updateDocPreference: UpdateDocPreferencePayload): void {
    this.store.dispatch(new UpdateDocPreference(updateDocPreference));
  }

  updateMarketingPreference(preference: MarketingPreferenceUpdate) {
    this.store.dispatch(new UpdateMarketingPreference({ preference }));
  }
}
