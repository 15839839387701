import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements AfterViewInit {
  @Input() group: FormGroup;
  @Input() labelFor;
  @Input() rows;
  @Input() classes;
  @Input() control;
  @Input() showValidation;
  @Input() label;
  @Input() items;
  @Input() validationError;
  @Input() validators;
  @Input() placeholder;
  @Input() chrLimit;
  @Input() hint;

  defaultLimit = 200;

  theLength = 0;

  ngAfterViewInit() {
    if (Number(this.chrLimit) === 0 || this.chrLimit === null) {
      this.chrLimit = this.defaultLimit;
    } else {
      //this.chrLimit = this.chrLimit;
    }

    this.theLength =
      this.chrLimit - this.group.controls[this.control].value.length;

    if (this.theLength) {
      (this.group.controls[this.control] as FormControl).valueChanges.subscribe(
        (value) => {
          this.theLength = this.chrLimit - value.length;
        }
      );
    }
  }
}
