import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from './validation.service';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  FormControls = [];

  constructor(private validationService: ValidationService) {}
  create(someJson, someData) {
    if (typeof someJson === 'object') {
      // eslint-disable-next-line guard-for-in
      for (const prop in someJson) {
        if (prop === 'control') {
          if (someJson.radioGroup !== undefined) {
            if (Object.keys(someData).length !== 0) {
              for (const radioEl of someJson.radioGroup) {
                if (radioEl.value === someData[someJson.control]) {
                  this.FormControls[someJson.control] = new FormControl(
                    radioEl.value
                  );
                  break;
                } else {
                  this.createFormControl(
                    null,
                    someJson.control,
                    someJson.validators
                  );
                }
              }
            } else {
              this.createFormControl(
                null,
                someJson.control,
                someJson.validators
              );
            }
          } else {
            if (someData[someJson.control]) {
              this.FormControls[someJson.control] = new FormControl(
                someData[someJson.control]
              );
            } else {
              this.createFormControl(
                someJson.value,
                someJson.control,
                someJson.validators
              );
            }
          }
        }
        this.create(someJson[prop], someData);
      }
    }
  }

  createFormControl(initialValue: any, controlName: string, validators) {
    if (validators) {
      this.FormControls[controlName] = new FormControl(
        initialValue,
        this.validationService.addControlValidationFn(validators)
      );
      return;
    }
    this.FormControls[controlName] = new FormControl(initialValue);
  }

  defineformControls(someJson: any, someData: any): any {
    this.FormControls = [];
    this.create(someJson, someData);
    return this.FormControls;
  }
}
