import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ControlType,
  DynamicFormbuilderService,
  FieldDef,
  FormGroupTyped,
  SubmitDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable } from 'rxjs';
import { distinctUntilChanged, skipWhile, takeWhile } from 'rxjs/operators';
import { AuthLibFacade } from '../../+state/auth.facade';
import { ApiResponseTypeEnum } from '../../common/enums/api-response-type.enum';
import {
  AuthModuleConfigForms,
  AUTH_FORMS,
} from '../../common/tokens/auth-config-token';

interface LoginFormType {
  email: string;
  password: string;
}
@Component({
  selector: 'athome-myaccount-dfb-log-in',
  templateUrl: './dfb-log-in.component.html',
  styleUrls: ['./dfb-log-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DfbLoginComponent implements OnInit, OnDestroy {
  isComponentAlive = true;
  loginForm: FormGroup;
  loginResponse$: Observable<ApiResponseTypeEnum> = this.facade.logInResponse$;
  submitted = false;
  fieldTextType: boolean;

  @Output() forgotPasswordLinkClicked = new EventEmitter();
  @Output() loginResponseSuccess = new EventEmitter();
  @Output() loginResponseError = new EventEmitter();
  @Output() formValueChange = new EventEmitter<any>();

  myFormGroup!: FormGroupTyped<LoginFormType>;

  readonly submitDef: SubmitDef = {
    controlType: ControlType.SUBMIT,
    label: { text: 'Log in' },
    classes: 'btn btn--primary',
    excludeFromFormGroup: true, // required so Form Group Control is not created
    disabled: false,
  };

  readonly fieldset: FieldDef[] = [
    {
      controlType: ControlType.INPUT,
      controlName: 'email',
      label: { text: 'Email' },
      validators: [Validators.required, Validators.email],
      validationMessages: [
        { type: 'required', message: 'Email is required!' },
        { type: 'email', message: 'Not a valid email address!' },
      ],
      placeholder: 'Email',
    },
    {
      controlType: ControlType.PASSWORD,
      controlName: 'password',
      label: { text: 'Password' },
      validators: [Validators.required],
      validationMessages: [
        { type: 'required', message: 'Password is required!' },
      ],
      placeholder: 'Password',
    },
    {
      controlType: 'some-content',
      controlName: 'someCustomContentNotARealControl',
      customConfig: {},
    },
    this.submitDef,
  ];

  constructor(
    @Inject(AUTH_FORMS) public forms: AuthModuleConfigForms,
    private facade: AuthLibFacade,
    private formBuilder: FormBuilder,
    private dfb: DynamicFormbuilderService
  ) {}

  ngOnInit(): void {
    this.onLoginResponse();

    this.myFormGroup = <FormGroupTyped<LoginFormType>>(
      this.dfb.generateFormGroup(this.fieldset)
    );
  }

  handleValidFormSubmission(formData: LoginFormType) {
    console.log('formData', formData);
    this.facade.logInUser({
      email: formData.email,
      password: formData.password,
    });
  }

  onLoginResponse(): void {
    this.loginResponse$
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .pipe(
        skipWhile(
          (responseStatus: ApiResponseTypeEnum) =>
            responseStatus === ApiResponseTypeEnum.NOT_CALLED
        )
      )
      .subscribe((responseStatus: ApiResponseTypeEnum) => {
        if (responseStatus === ApiResponseTypeEnum.SUCCESS) {
          this.loginResponseSuccess.emit();
        }
        if (responseStatus === ApiResponseTypeEnum.ERROR) {
          this.loginResponseError.emit();
        }
      });
  }

  onForgotYourPasswordClick(): void {
    this.forgotPasswordLinkClicked.emit();
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  ngOnDestroy(): void {
    this.facade.resetApiResponseState();
    this.isComponentAlive = false;
  }
}
