export enum RegexFormValidationsEnum {
  PLAN_NUMBER_VALIDATION = '^[a-zA-Z0-9 ]{3}[0-9]{7}$',
  ONLY_LETTERS_HYPHEN_BRACKETS_ALLOWED = "^[A-Za-z]+([ A-Za-z'()-]+)*$",
  USER_PASSWORD_VALIDATION = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,128}$',
  OLD_ACCOUNTS_EMAIL_VALIDATION = '^.+@[^.].*.[A-Za-z0-9]{2,}$',
  EMAIL_VALIDATION = '^[a-zA-Z]{1}[a-zA-Z0-9.+-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$',
  MOBILE_NUMBER_VALIDATION = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
  LANDLINE_NUMBER_VALIDATION = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$',
}

export const whitespacesOnlyInMiddleAuthLib = /^[^\s]+(\s+[^\s]+)*$/;

export enum FormFieldTypeEnum {
  REQUIRED = 'required',
  PATTERN = 'pattern',
  MINLENGTH = 'minlength',
  MAXLENGTH = 'maxlength',
}

export const REQUIRED_FIELD_VALIDATION = (validationMessage: string) => {
  return {
    type: FormFieldTypeEnum.REQUIRED,
    message: validationMessage,
  };
};

export const OLD_ACCOUNTS_EMAIL_VALIDATION = (validationMessage: string) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: validationMessage,
    regexp: RegexFormValidationsEnum.OLD_ACCOUNTS_EMAIL_VALIDATION,
  };
};

export const EMAIL_VALIDATION = (validationMessage: string) => {
  return {
    type: FormFieldTypeEnum.PATTERN,
    message: validationMessage,
    regexp: RegexFormValidationsEnum.EMAIL_VALIDATION,
  };
};
