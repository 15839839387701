import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnChanges {
  displayFex = 'flex';
  displayInline = 'inline';

  @Input() overlayOnTopOfExistingContent = false;
  @Input() overlayFixedPositionMiddleOfPage = false;

  @HostBinding('style.display')
  display = this.displayFex;

  ngOnChanges(changes: SimpleChanges): void {
    const { overlayOnTopOfExistingContent, overlayFixedMiddleOfPage } = changes;
    if (
      overlayOnTopOfExistingContent &&
      overlayOnTopOfExistingContent.currentValue === true
    ) {
      this.display = this.displayInline;
    } else {
      this.display = this.displayFex;
    }
  }
}
