import {AfterViewInit,Component,Input,OnChanges} from '@angular/core';
import {whitespacesOnlyInMiddleAuthLib} from '@dgx-auth-lib';
@Component({
  selector: 'athome-myaccount-password-strength-checker',
  templateUrl: './password-strength-checker.component.html',
  styleUrls: ['./password-strength-checker.component.scss'],
})
export class PasswordStrengthCheckerComponent implements OnChanges, AfterViewInit {
  passwordValidator = {
    passwordLength: false,
    passwordUpperCase: false,
    passwordLowerCase: false,
    passwordNumber: false,
    passwordComplexCase: false,
  };

  @Input() value = '';

  @Input() showLength = false;
  @Input() showUppercase = false;
  @Input() showLowercase = false;
  @Input() showNumber = false;
  @Input() showComplex = false;

  @Input() lengthError = false;
  @Input() upperCaseError = false;
  @Input() lowerCaseError = false;
  @Input() numberError = false;
  @Input() showComplexError = false;
  @Input() submitted = false;

  public translations = {
    inputNumber: 'At least one number',
    inputLowerCase: 'At least one lower case letter',
    inputUpperCase: 'At least one upper case letter',
    inputLength: 'At least eight characters',
    inputComplex: 'Cannot start or end with whitespace',
  };

  ngAfterViewInit(): void {
    this.checkValue();
  }

  ngOnChanges() {
    this.checkValue();
  }

  checkValue() {
    const result = this.validatePassword(this.value);
    this.passwordValidator = {
      passwordLength: result.passwordLength,
      passwordLowerCase: result.passwordLowerCase,
      passwordUpperCase: result.passwordUpperCase,
      passwordNumber: result.passwordNumber,
      passwordComplexCase: result.passwordComplexCase,
    };
  }

  validatePassword(passwordInput: string) {
    return {
      passwordLength: passwordInput && passwordInput.length >= 8,
      passwordLowerCase: passwordInput && !!passwordInput.match('[a-z]'),
      passwordUpperCase: passwordInput && !!passwordInput.match('[A-Z]'),
      passwordNumber: passwordInput && !!passwordInput.match('[0-9]'),
      passwordComplexCase:
        passwordInput && whitespacesOnlyInMiddleAuthLib.test(passwordInput),
    };
  }
}
