import { FormGroup, ValidationErrors } from '@angular/forms';

export function getFormValidationErrors(form: FormGroup): string {
  const formFields = [];
  Object.keys(form.controls).forEach((key) => {
    const controlErrors: ValidationErrors = form.get(key).errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach((keyError) => {
        const space = ' ';
        const comma = ',';
        const formValue =
          'form field:' +
          space.concat(key) +
          comma +
          'error type:' +
          space.concat(keyError) +
          '|';
        formFields.push(formValue);
      });
    }
  });
  return formFields.join('');
}
