import {
  PlanDetailApiResponse,
  PlanDetail,
} from '../../../../util-account/src/index';

export const PLAN_DETAIL: PlanDetailApiResponse = {
  plan_number: '6GB0000102',
  plan_state: 'COVERED',
  plan_start: '2018-11-08',
  plan_contract_type: 'Continuous cover',
  plan_expiry: '2019-11-08',
  plan_owner_type: 'CUSTOMER',
  contact_details: {
    email: 'test@domesticandgeneral.com',
  },
  repair_payment: 'NO',
  repair_payment_type: 'CALLOUT',
  parent_plan_number: '6GB0000100',
  parent_plan_state: 'COVERED',
  repair_action: 'TRACK_REPAIR',
  service_action: 'AMEND_SERVICE',
  open_repair_id: '31769',
  open_service_id: '1004',
  in_warranty_contact_name: 'Warranty Name',
  in_warranty_contact_tel: '0333 000 4966',
  item: {
    manufacturer: 'LG',
    model: '',
    model_number: '',
    code: 'Z6',
    code_description: 'HOME CINEMA DVD',
  },
  plan_note: '',
  claims: [
    {
      id: '31769',
      status: 'OPEN',
      claim_type: 'REPAIR',
    },
    {
      id: '31636',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    {
      id: '15594',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    {
      id: '10163',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    {
      id: '7590',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    {
      id: '1004',
      status: 'OPEN',
      claim_type: 'SERVICE',
    },
    {
      id: '1001',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
    {
      id: '1002',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
    {
      id: '1003',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
  ],
  open_repair: {
    id: '31769',
    claim_status: 'Track your repair status',
    claim_type: 'REPAIR',
    rebookable: true,
    history: [
      {
        status: 'Track your repair',
        date: '2019-12-23 13:43:53',
      },
      {
        status: 'Form incomplete',
        date: '2019-12-23 12:23:44',
      },
    ],
    repairer: {
      logo: 'tumble-dryer.png',
      phone_number: '0208654933',
      name: 'Whirlpool',
      email: 'phil@pde.co.uk',
    },
  },
  open_service: {
    id: '1004',
    claim_status: 'Track your service status',
    claim_type: 'SERVICE',
    rebookable: true,
    history: [
      {
        status: 'Track your service',
        date: '2020-12-23 13:43:53',
      },
      {
        status: 'Form incomplete',
        date: '2020-12-23 12:23:44',
      },
    ],
    repairer: {
      logo: 'tumble-dryer.png',
      phone_number: '0208654933',
      name: 'Whirlpool',
      email: 'phil@pde.co.uk',
    },
  },
  payment: {
    method: 'DIRECT_DEBIT',
    direct_debit_detail: {
      account_holder_name: 'Test',
      masked_account_number: '90402340',
      sort_code: '600001',
      schedule: [
        {
          date: '2020-03-15',
          amount: '100.00',
        },
        {
          date: '2020-04-15',
          amount: '100.00',
        },
        {
          date: '2020-05-15',
          amount: '100.00',
        },
      ],
    },
  },
};

export const PLAN_DETAIL_STORE: PlanDetail = {
  plan_number: '6GB0000102',
  plan_state: 'COVERED',
  plan_start: '2018-11-08',
  plan_expiry: '2019-11-08',
  plan_owner_type: 'CUSTOMER',
  plan_contract_type: 'Continuous cover',
  contact_details: {
    email: 'test@domesticandgeneral.com',
  },
  openRepairCallbackData: null,
  repair_action: 'TRACK_REPAIR',
  service_action: 'AMEND_SERVICE',
  open_repair_id: '31769',
  open_service_id: '1004',
  open_service_status: 'Track your service status',
  open_repair_status: 'Track your repair status',
  open_service_rebookable: true,
  open_repair_rebookable: true,
  open_repair_cancelable: true,
  parent_plan_number: '6GB0000100',
  parent_plan_state: 'COVERED',
  in_warranty_contact_name: 'Warranty Name',
  in_warranty_contact_tel: '0333 000 4966',
  item: {
    manufacturer: 'LG',
    model: '',
    model_number: '',
    code: 'Z6',
    code_description: 'HOME CINEMA DVD',
  },
  plan_note: '',
  claims: {
    '31769': {
      id: '31769',
      status: 'OPEN',
      claim_type: 'REPAIR',
      history: [
        {
          status: 'Track your repair',
          date: '2019-12-23 13:43:53',
        },
        {
          status: 'Form incomplete',
          date: '2019-12-23 12:23:44',
        },
      ],
      repairer: {
        logo: 'tumble-dryer.png',
        phone_number: '0208654933',
        name: 'Whirlpool',
        email: 'phil@pde.co.uk',
      },
    },
    '31636': {
      id: '31636',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    '15594': {
      id: '15594',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    '10163': {
      id: '10163',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    '7590': {
      id: '7590',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    '1004': {
      id: '1004',
      status: 'OPEN',
      claim_type: 'SERVICE',
      history: [
        {
          status: 'Track your service',
          date: '2020-12-23 13:43:53',
        },
        {
          status: 'Form incomplete',
          date: '2020-12-23 12:23:44',
        },
      ],
      repairer: {
        logo: 'tumble-dryer.png',
        phone_number: '0208654933',
        name: 'Whirlpool',
        email: 'phil@pde.co.uk',
      },
    },
    '1001': {
      id: '1001',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
    '1002': {
      id: '1002',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
    '1003': {
      id: '1003',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
  },
  payment: {
    method: 'DIRECT_DEBIT',
    direct_debit_detail: {
      account_holder_name: 'Test',
      masked_account_number: '90402340',
      sort_code: '600001',
      schedule: [
        {
          date: '2020-03-15',
          amount: '100.00',
        },
        {
          date: '2020-04-15',
          amount: '100.00',
        },
        {
          date: '2020-05-15',
          amount: '100.00',
        },
      ],
    },
  },
  repair_payment: 'NO',
  repair_payment_type: 'CALLOUT',
  direct_referral: {
    company_name: 'Test Company',
    company_email: 'test@company.com',
    company_phone: '11223344',
    company_website: 'https://www.testcompany.com',
  },
};

export const PLAN_DETAIL_PLUMBING_AND_DRAINAGE_STORE: PlanDetail = {
  plan_number: '6GB0000102',
  plan_state: 'COVERED',
  plan_start: '2018-11-08',
  plan_expiry: '2019-11-08',
  plan_owner_type: 'CUSTOMER',
  plan_contract_type: 'Continuous cover',
  contact_details: {
    email: 'test@domesticandgeneral.com',
  },
  openRepairCallbackData: null,
  repair_action: 'TRACK_REPAIR',
  service_action: 'AMEND_SERVICE',
  open_repair_id: '31769',
  open_service_id: '1004',
  open_service_status: 'Track your service status',
  open_repair_status: 'Track your repair status',
  open_service_rebookable: true,
  open_repair_rebookable: true,
  open_repair_cancelable: true,
  parent_plan_number: '6GB0000100',
  parent_plan_state: 'COVERED',
  in_warranty_contact_name: 'Warranty Name',
  in_warranty_contact_tel: '0333 000 4966',
  item: {
    manufacturer: 'LG',
    model: '',
    model_number: '',
    code: 'X7',
    code_description: 'HOME CINEMA DVD',
  },
  plan_note: '',
  claims: {
    '31769': {
      id: '31769',
      status: 'OPEN',
      claim_type: 'REPAIR',
      history: [
        {
          status: 'Track your repair',
          date: '2019-12-23 13:43:53',
        },
        {
          status: 'Form incomplete',
          date: '2019-12-23 12:23:44',
        },
      ],
      repairer: {
        logo: 'tumble-dryer.png',
        phone_number: '0208654933',
        name: 'Whirlpool',
        email: 'phil@pde.co.uk',
      },
    },
    '31636': {
      id: '31636',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    '15594': {
      id: '15594',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    '10163': {
      id: '10163',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    '7590': {
      id: '7590',
      status: 'CLOSED',
      claim_type: 'REPAIR',
    },
    '1004': {
      id: '1004',
      status: 'OPEN',
      claim_type: 'SERVICE',
      history: [
        {
          status: 'Track your service',
          date: '2020-12-23 13:43:53',
        },
        {
          status: 'Form incomplete',
          date: '2020-12-23 12:23:44',
        },
      ],
      repairer: {
        logo: 'tumble-dryer.png',
        phone_number: '0208654933',
        name: 'Whirlpool',
        email: 'phil@pde.co.uk',
      },
    },
    '1001': {
      id: '1001',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
    '1002': {
      id: '1002',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
    '1003': {
      id: '1003',
      status: 'CLOSED',
      claim_type: 'SERVICE',
    },
  },
  payment: {
    method: 'DIRECT_DEBIT',
    direct_debit_detail: {
      account_holder_name: 'Test',
      masked_account_number: '90402340',
      sort_code: '600001',
      schedule: [
        {
          date: '2020-03-15',
          amount: '100.00',
        },
        {
          date: '2020-04-15',
          amount: '100.00',
        },
        {
          date: '2020-05-15',
          amount: '100.00',
        },
      ],
    },
  },
  repair_payment: 'NO',
  repair_payment_type: 'CALLOUT',
};
