import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';

import { UnHandledError } from './global-error-handler.actions';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  handleError(error: Error | HttpErrorResponse) {
    console.error(error);
    const store = this.injector.get(Store);
    store.dispatch(new UnHandledError());
  }
}
