<ng-container>
  <div *ngIf="group" class="dg-checkboxes__item" [formGroup]="group">
    <input
      class="dg-checkboxes__input"
      [formControlName]="items.control"
      id="{{ items.control }}"
      type="checkbox"
      [value]="items.value"
      (blur)="showValidation = true"
    />
    <app-label [class]="labelClasses" [forElement]="items.control">
      {{ items.text }}
      <i class="icon icon-tick"></i>
    </app-label>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <app-validation-error-formcontrol
      [group]="group"
      [validators]="validators"
      [control]="items.control"
    ></app-validation-error-formcontrol>
  </ng-container>
  <ng-content></ng-content>
</ng-container>
