import {
  Component,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  Inject,
  forwardRef,
  TemplateRef,
  Directive,
  AfterContentInit,
} from '@angular/core';
import { AccordionComponent } from '../accordion.component';
import { showHide } from '../../../animations/fade';

let idx = 0;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[accordionTemplate]',
})
export class AccordionTemplateDirective {
  @Input() type: string;

  @Input('accordionTemplate') name: string;

  constructor(public template: TemplateRef<any>) {}

  getType(): string {
    return this.name;
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'accordion-header',
  template: '<ng-content></ng-content>',
})
export class AccordionHeaderComponent {}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-accordionTab',
  templateUrl: './accordion-tab.component.html',
  animations: [showHide],
  styleUrls: ['./accordion-tab.component.scss'],
})
export class AccordionTabComponent implements AfterContentInit, OnDestroy {
  @Input() header: string;

  @Input() selected: boolean;

  @Input() disabled: boolean;

  @Input() showTop = true;

  @Input() cache = true;

  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  @Input() transitionOptions = '400ms cubic-bezier(0.86, 0, 0.07, 1)';

  @ContentChildren(AccordionHeaderComponent)
  headerFacet: QueryList<AccordionHeaderComponent>;

  @ContentChildren(AccordionTemplateDirective, { descendants: true })
  templates: QueryList<any>;

  animating: boolean;

  contentTemplate: TemplateRef<any>;

  id = `ui-accordiontab-${idx++}`;

  loaded: boolean;

  constructor(
    @Inject(forwardRef(() => AccordionComponent))
    public accordion: AccordionComponent
  ) {}

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;

        default:
          this.contentTemplate = item.template;
          break;
      }
    });
  }

  toggle(event) {
    if (this.disabled || this.animating) {
      return false;
    }

    this.animating = true;
    const index = this.findTabIndex();

    if (this.selected) {
      this.selected = false;
      this.accordion.onClose.emit({ originalEvent: event, index: index });
    } else {
      if (!this.accordion.multiple) {
        for (let i = 0; i < this.accordion.tabs.length; i++) {
          this.accordion.tabs[i].selected = false;
          this.accordion.tabs[i].selectedChange.emit(false);
        }
      }

      this.selected = true;
      this.loaded = true;
      this.accordion.onOpen.emit({ originalEvent: event, index: index });
    }

    this.selectedChange.emit(this.selected);

    event.preventDefault();
  }

  findTabIndex() {
    let index = -1;
    for (let i = 0; i < this.accordion.tabs.length; i++) {
      if (this.accordion.tabs[i] === this) {
        index = i;
        break;
      }
    }
    return index;
  }

  get hasHeaderFacet(): boolean {
    return this.headerFacet && this.headerFacet.length > 0;
  }

  onToggleDone(event: Event) {
    this.animating = false;
  }

  onKeydown(event: KeyboardEvent) {
    if (event.which === 32 || event.which === 13) {
      this.toggle(event);
      event.preventDefault();
    }
  }

  ngOnDestroy() {
    this.accordion.tabs.splice(this.findTabIndex(), 1);
  }
}
