import * as faker from 'faker';
import { ApiResponse } from '../models/appliance.model';
import { CallbackData, OpenRepair } from '../models/plan.detail.model';
import { ClaimHistory } from './claim-history.model';
import { Claim } from './claim.model';
import { ContactDetails } from './contact-details.model';
import { CreditCardPayment } from './credit-card-payment.model';
import { DirectDebitPayment } from './direct-debit-payment.model';
import { OpenClaim } from './open-claim.model';
import {
  PaymentMethod,
  PlanState,
  PlanType,
  RepairAction,
  ServiceAction,
  RepairPaymentType,
  RepairPayment,
} from './plan-detail.types';
import { PlanItem } from './plan-item.model';
import { RepairerDetail } from './repairer.model';
export interface PlanDetail {
  plan_number: string;
  plan_state: PlanState;
  plan_note?: string;
  plan_start: string;
  plan_expiry: string;
  plan_owner_type: PlanType;
  repair_action: RepairAction;
  service_action: ServiceAction;
  open_repair?: OpenRepair;
  openRepairCallbackData?: CallbackData;
  open_repair_id?: string;
  open_service_id?: string;
  open_service_status?: string;
  open_service_rebookable?: boolean;
  open_repair_rebookable?: boolean;
  open_repair_cancelable?: boolean;
  open_repair_status?: string;
  parent_plan_number?: string;
  parent_plan_state?: PlanState;
  plan_contract_type_code?: string;
  plan_contract_product_type?: string;
  item: PlanItem;
  plan_contract_type: string;
  in_warranty_contact_name?: string;
  in_warranty_contact_tel?: string;
  payment: {
    method: PaymentMethod;
    credit_card_detail?: CreditCardPayment;
    direct_debit_detail?: DirectDebitPayment;
  };
  claims: {
    [id: string]: Claim & {
      history?: ClaimHistory[];
      repairer?: RepairerDetail;
    };
  };
  contact_details: ContactDetails;
  repair_payment?: RepairPayment;
  repair_payment_type?: RepairPaymentType;
  direct_referral?: Referral;
}

export interface Referral {
  company_name: string;
  company_email: string;
  company_phone: string;
  company_website: string;
}
export interface PlanDetailApiResponse {
  plan_number: string;
  plan_state: PlanState;
  plan_note?: string;
  plan_start: string;
  plan_expiry: string;
  plan_contract_type: string;
  plan_owner_type: PlanType;
  repair_action: RepairAction;
  service_action: ServiceAction;
  openRepairCallbackData?: CallbackData;
  open_repair_id?: string;
  open_service_id?: string;
  parent_plan_number?: string;
  parent_plan_state?: PlanState;
  in_warranty_contact_name?: string;
  in_warranty_contact_tel?: string;
  item: PlanItem;
  open_repair?: OpenClaim;
  open_service?: OpenClaim;
  claims: Claim[];
  payment: {
    method: PaymentMethod;
    credit_card_detail?: CreditCardPayment;
    direct_debit_detail?: DirectDebitPayment;
  };
  contact_details: ContactDetails;
  repair_payment?: RepairPayment;
  repair_payment_type?: RepairPaymentType;
}

export interface PlanDetailApiResponsePlan {
  plan: PlanDetailApiResponse;
}

export const generateRandomPlanDetail =
  (): ApiResponse<PlanDetailApiResponsePlan> => {
    return {
      status: 'OK',
      result: {
        plan: {
          plan_number: faker.random.arrayElement(['', faker.finance.account()]),
          plan_state: faker.random.arrayElement(
            Object.values(['COVERED', 'LAPSED', 'CANCELLED', 'RENEWAL'])
          ),
          plan_start: faker.date.past(1).toISOString().split('T')[0],
          plan_expiry: faker.date.future(1).toISOString().split('T')[0],
          plan_contract_type: faker.random.arrayElement([
            '',
            faker.finance.account(),
          ]),
          plan_owner_type: faker.random.arrayElement(
            Object.values(['CUSTOMER', 'LANDLORD'])
          ),
          repair_action: faker.random.arrayElement(
            Object.values([
              'REQUEST_REPAIR',
              'BOOK_ENGINEER',
              'INCOMPLETE_FORM',
              'TRACK_REPAIR',
            ])
          ),
          service_action: faker.random.arrayElement(
            Object.values([
              'REQUEST_SERVICE',
              'INCOMPLETE_FORM',
              'AMEND_SERVICE',
              'BOOK_ENGINEER',
            ])
          ),
          item: {
            manufacturer: faker.random.arrayElement([
              'HOTPOINT',
              'BEKO',
              'APPLE',
            ]),
            model: faker.random.arrayElement(['', faker.finance.account()]),
            model_number: faker.random.arrayElement([
              '',
              faker.finance.account(),
            ]),
            code: faker.random.arrayElement(['', faker.finance.account()]),
            code_description: faker.random.arrayElement([
              '',
              faker.finance.account(),
            ]),
          },
          claims: [
            {
              id: faker.random.arrayElement(['', faker.finance.account()]),
              status: faker.random.arrayElement(
                Object.values(['OPEN', 'CLOSED'])
              ),
              claim_type: faker.random.arrayElement(
                Object.values(['SERVICE', 'REPAIR'])
              ),
            },
          ],
          payment: {
            method: faker.random.arrayElement(
              Object.values(['CREDIT_CARD', 'DIRECT_DEBIT'])
            ),
          },
          contact_details: {
            email: faker.random.arrayElement(['', faker.finance.account()]),
          },
          repair_payment: 'NO',
        },
      },
    };
  };
