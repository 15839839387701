import {
  RemoteData,
  Failure,
  Success,
  NotAsked,
  AnyRemoteData,
} from 'ngx-remotedata';
import { OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export function filterRemoteDataFailure<T>(): OperatorFunction<
  RemoteData<T, Error>,
  Failure<Error, T>
> {
  return (source$) =>
    source$.pipe(
      filter((remoteData) => remoteData instanceof Failure),
      map((remoteData) => remoteData as Failure<Error, T>)
    );
}

export function filterRemoteDataSuccess<T>(): OperatorFunction<
  RemoteData<T, Error>,
  Success<T>
> {
  return (source$) =>
    source$.pipe(
      filter((remoteData) => remoteData instanceof Success),
      map((remoteData) => remoteData as Success<T>)
    );
}

export function filterRemoteDataComplete<T>(): OperatorFunction<
  RemoteData<T, Error>,
  Success<T> | Failure<Error, T>
> {
  return (source$) =>
    source$.pipe(
      filter(
        (remoteData) =>
          remoteData instanceof Success || remoteData instanceof Failure
      ),
      map((remoteData) => remoteData as Success<T> | Failure<Error, T>)
    );
}

export function filterRemoteDataNotAsked(): OperatorFunction<
  AnyRemoteData,
  NotAsked
> {
  return (source$) =>
    source$.pipe(
      filter((remoteData) => remoteData instanceof NotAsked),
      map((remoteData) => remoteData as NotAsked)
    );
}
