import { InjectionToken } from '@angular/core';

export interface AuthModuleConfig {
  urlsConfigJsonPath: string;
  forms: AuthModuleConfigForms;
}

export interface AuthModuleConfigForms {
  loginForm: {
    emailLabel: string;
    requiredEmailValidation: string;
    incorrectEmailFormatValidation: string;
    passwordLabel: string;
    showPasswordLabel: string;
    hidePasswordLabel: string;
    requiredPasswordValidation?: string;
    logInButtonLabel: string;
    forgotPasswordLinkLabel: string;
  };
  forgotPasswordForm?: {
    emailLabel?: string;
    requiredEmailValidation?: string;
    incorrectEmailFormatValidation?: string;
    emailPlaceholderLabel?: string;
    submitButtonLabel?: string;
    showBackToLoginButton: boolean;
    backToLoginButtonLabel: string;
  };
}

export const AUTH_URLS_CONFIG_JSON = new InjectionToken<
  AuthModuleConfig['urlsConfigJsonPath']
>('Auth api related config json urls');
export const AUTH_FORMS = new InjectionToken<AuthModuleConfig['forms']>(
  'Auth forms related definitions'
);

// export const AUTH_LIB_TOKEN = new InjectionToken("AuthLibConfig", {
//   providedIn: "root",
//   factory: () => {},
// });
