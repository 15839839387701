export interface LoginPayload {
  email: string;
  password: string;
  client_name?: string;
}

export interface RequestPasswordPayload {
  email: string;
  is_sign_up: boolean;
}

export interface RequestVerificationCodePayload {
  email: string;
}
export interface VerificationCodePayload {
  email: string;
  confirmation_code: string;
  client_name?: string;
}
export interface RefreshUserTokenPayload {
  refresh_token: string;
}

export interface ResetPasswordPayload {
  email: string;
  confirmation_code: string;
  is_sign_up: boolean;
  password?: string;
}
export interface CreateAccountPayload {
  name: string;
  surname: string;
  email: string;
  password: string;
}
export interface UserMatchPayload {
  address_line_1: string;
  address_line_2: string;
  postcode: string;
  surname: string;
  plan_number?: string;
}
