import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import {
  ControlType,
  DynamicFormbuilderService,
  FieldDef,
  FormGroupTyped,
  SubmitDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable } from 'rxjs';
import { distinctUntilChanged, skipWhile, takeWhile } from 'rxjs/operators';
import { AuthLibFacade } from '../../+state/auth.facade';
import { ApiResponseTypeEnum } from '../../common/enums/api-response-type.enum';

interface ForgotFormType {
  email: string;
}

@Component({
  selector: 'athome-myaccount-dfb-forgot-password',
  templateUrl: './dfb-forgot-password.component.html',
  styleUrls: ['./dfb-forgot-password.component.scss'],
})
export class DfbForgotPasswordComponent implements OnInit, OnDestroy {
  isComponentAlive = true;
  forgotPasswordForm: FormGroup;
  forgotPasswordResponse$: Observable<ApiResponseTypeEnum> =
    this.facade.forgotPasswordResponse$;
  submitted = false;

  @Output() backToLoginButtonClicked = new EventEmitter();
  @Output() forgotPasswordResponseSuccess = new EventEmitter();
  @Output() forgotPasswordResponseError = new EventEmitter();
  @Output() formValueChange = new EventEmitter<any>();

  myFormGroup!: FormGroupTyped<ForgotFormType>;

  readonly submitDef: SubmitDef = {
    controlType: ControlType.SUBMIT,
    label: { text: 'Submit' },
    classes: 'btn btn--primary',
    excludeFromFormGroup: true, // required so Form Group Control is not created
    disabled: false,
  };

  readonly fieldset: FieldDef[] = [
    {
      controlType: ControlType.INPUT,
      controlName: 'email',
      label: { text: 'Email' },
      validators: [Validators.required, Validators.email],
      validationMessages: [
        { type: 'required', message: 'Email is required!' },
        { type: 'email', message: 'Not a valid email address!' },
      ],
      placeholder: 'Email',
    },
    this.submitDef,
  ];

  constructor(
    private facade: AuthLibFacade,
    private dfb: DynamicFormbuilderService
  ) {}

  ngOnInit(): void {
    this.onForgotPasswordResponse();

    this.myFormGroup = <FormGroupTyped<ForgotFormType>>(
      this.dfb.generateFormGroup(this.fieldset)
    );
  }

  handleValidFormSubmission(formData: ForgotFormType) {
    console.log('formData', formData);

    this.submitted = true;
    this.facade.requestForgotPasswordForUser({
      email: formData.email,
      is_sign_up: false,
    });
  }

  onForgotPasswordResponse(): void {
    this.forgotPasswordResponse$
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .pipe(
        skipWhile(
          (responseStatus: ApiResponseTypeEnum) =>
            responseStatus === ApiResponseTypeEnum.NOT_CALLED
        )
      )
      .subscribe((responseStatus: ApiResponseTypeEnum) => {
        if (responseStatus === ApiResponseTypeEnum.SUCCESS) {
          this.forgotPasswordResponseSuccess.emit();
        }
        if (responseStatus === ApiResponseTypeEnum.ERROR) {
          this.forgotPasswordResponseError.emit();
        }
      });
  }

  ngOnDestroy(): void {
    this.facade.resetApiResponseState();
    this.isComponentAlive = false;
  }
}
