import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AccountService,
  AnalyticsService,
} from '@athome-myaccount/my-account/data-access-shared';
import { ResetAccountState } from '@athome-myaccount/my-account/util-account';
import {
  AppConfig,
  CloseModal,
  CONFIG,
  ModalDialogService,
  OpenAlertModal,
  OpenConfirmModal,
} from '@athome-myaccount/my-account/util-core';
import { LoginRoutesEnum } from '@athome-myaccount/my-account/util-login';
import {
  AlertType,
  CreateAccountErrorResponse,
  LoginErrorResponse,
  ModalAlertData,
  ModalConfirmData,
  RequestPasswordErrorResponse,
  RequestVerificationCodeErrorResponse,
  ResetPasswordErrorResponse,
  User,
  UserMatchSuccessResponse,
  UserStateTypeEnum,
} from '@athome-myaccount/my-account/util-shared';
import { LocalStorageManagerService } from '@athome-myaccount/shared/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, pipe } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import * as userActions from '../state/user.actions';
import { selectApplicationUser } from './user.selectors';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private analyticsService: AnalyticsService,
    private loginService: LoginService,
    private router: Router,
    private cookieService: CookieService,
    private store: Store<any>,
    private modalDialogService: ModalDialogService,
    private localStorageManagerService: LocalStorageManagerService,
    @Inject(CONFIG) private readonly config: AppConfig
  ) {}

  userAuthentication$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.UserAuthentication>(
        userActions.UserActionTypes.UserAuthentication
      ),
      tap(() => {
        this.cookieService.delete('AWS_IDT', '/', '.domgen.cloud');
      }),
      switchMap((action: any) =>
        this.accountService.login(action.payload).pipe(
          map((response: any) => {
            const correlationId = response?.headers?.get('x-correlation-id');
            if (correlationId) {
              this.store.dispatch(
                new userActions.SetCorrelationId({ correlationId })
              );
              sessionStorage.removeItem('dg-x-correlation-id');
              sessionStorage.setItem('dg-x-correlation-id', correlationId);
            }
            return new userActions.UserAuthenticationSuccess({
              response,
              correlationId,
            });
          }),
          catchError(
            (error: LoginErrorResponse | RequestPasswordErrorResponse) =>
              of(
                new userActions.UserAuthenticationFailure({
                  error,
                  loginFormCredentials: action.payload,
                })
              )
          )
        )
      )
    )
  );

  userAuthenticationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.UserAuthenticationSuccess>(
          userActions.UserActionTypes.UserAuthenticationSuccess
        ),
        map(({ payload }) => {
          this.analyticsService.push({
            event: 'genericGAEvent',
            eventCategory: 'user-login-journey-success',
            eventAction: 'user-authentication-response-success',
            eventLabel: 'user-login-page',
          });
          this.loginService.processUserAuthorizationSuccessResponse(
            payload.response.body.result.state
          );
        })
      ),
    { dispatch: false }
  );

  userAuthenticationFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.UserAuthenticationFailure>(
          userActions.UserActionTypes.UserAuthenticationFailure
        ),
        pipe(
          map((action) => action.payload),
          tap((payload: any) => {
            this.analyticsService.push({
              event: 'genericGAEvent',
              eventCategory: 'user-login-journey-errors',
              eventAction: 'Your details are not recognized',
              eventLabel: 'user-login-page',
            });

            const state =
              payload.error.user_state ||
              payload.error.additionalInfo.userState;
            if (state === UserStateTypeEnum.CONFIRMATION_REQ) {
              this.router.navigateByUrl(LoginRoutesEnum.VERIFY_EMAIL_PAGE);
            } else if (state === UserStateTypeEnum.PASSWORD_RESET_REQ) {
              localStorage.setItem(
                'D&GLastUserType',
                JSON.stringify('migrated-user')
              );

              this.router.navigateByUrl(LoginRoutesEnum.MIGRATED_USER_PAGE);
            } else {
              const modalData: ModalAlertData = {
                title: this.loginService.getTitleErrorMessage(state),
                content: this.loginService.getContentErrorMessage(state),
                alertType: AlertType.ERROR,
                closeButtonLabel: 'Close',
              };
              this.modalDialogService.openModal(
                new OpenAlertModal(modalData, null, null, false)
              );
            }
          })
        )
      ),
    { dispatch: false }
  );

  verifyAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.VerifyAddress>(
        userActions.UserActionTypes.VerifyAddress
      ),
      switchMap((action) =>
        this.accountService.userMatch(action.payload.userMatch).pipe(
          map(
            (response: UserMatchSuccessResponse) =>
              new userActions.VerifyAddressSuccess({
                userMatchSuccessResponse: response,
              })
          ),
          catchError((error: any) => {
            return of(new userActions.VerifyAddressFailure({ error }));
          })
        )
      )
    )
  );

  verifyAddressSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.VerifyAddressSuccess>(
          userActions.UserActionTypes.VerifyAddressSuccess
        ),
        map(({ payload }) => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-address-successfuly-verified',
            event_category: 'user-registration-journey',
            event_action: 'address-verification-response-success',
            event_label: 'user-address-verification-page',
            user_registration_date: new Date().toLocaleDateString(),
          });
          this.loginService.processUserAuthorizationSuccessResponse(
            payload.userMatchSuccessResponse.state
          );
        })
      ),
    { dispatch: false }
  );

  verifyAddressFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.VerifyAddressFailure>(
          userActions.UserActionTypes.VerifyAddressFailure
        ),
        map(({ payload }) => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-address-not-verified-successfuly',
            event_category: 'user-registration-journey',
            event_action: payload.error.error_message,
            event_label: 'user-address-verification-page',
          });
          const state = payload.error;
          if (
            state &&
            state.additionalInfo.userState === UserStateTypeEnum.MATCH_REQ
          ) {
            this.router.navigateByUrl(LoginRoutesEnum.VERIFY_PLAN_NUMBER_PAGE);
          } else if (
            state?.additionalInfo?.userState === UserStateTypeEnum.LOCKED
          ) {
            this.router.navigateByUrl(LoginRoutesEnum.LOGIN_PAGE);
            const modalData: ModalAlertData = {
              title: 'Maximum attempts reached',
              content: {
                content:
                  // eslint-disable-next-line max-len
                  "<p>You've reached the maximum number of account creation attempts.</p> <p>For more guidance about My Account, you can visit our <a class='text-item' href='https://www.domesticandgeneral.com/content/contact-domestic-general'>help page.</a></p>",
                html: true,
              },
              alertType: AlertType.ERROR,
              closeButtonLabel: '',
            };
            this.modalDialogService.openModal(
              new OpenAlertModal(modalData, null, null, false)
            );
          } else {
            this.handleErrorResponse(state);
          }
        })
      ),
    { dispatch: false }
  );

  verifyPlanNumber$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.VerifyPlanNumber>(
        userActions.UserActionTypes.VerifyPlanNumber
      ),
      switchMap((action) => {
        return this.accountService.userMatch(action.payload.userMatch).pipe(
          map(
            (response: UserMatchSuccessResponse) =>
              new userActions.VerifyPlanNumberSuccess({
                userMatchSuccessResponse: response,
                planNumber: action.payload.userMatch.plan_number,
              })
          ),
          catchError((error: any) => {
            return of(
              new userActions.VerifyPlanNumberFailure({
                error,
                planNumber: action.payload.userMatch.plan_number,
              })
            );
          })
        );
      })
    )
  );

  verifyPlanNumberSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.VerifyPlanNumberSuccess>(
          userActions.UserActionTypes.VerifyPlanNumberSuccess
        ),
        withLatestFrom(this.store.pipe(select(selectApplicationUser))),
        map(([action, user]) => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'plan-number-verification-success',
            pagename: 'registration-completed',
            genericpagename: 'registration-completed',
            customerID: user && user.sub ? user.sub : '',
            DigitalID:
              user && user['custom:digital_id']
                ? user['custom:digital_id']
                : '',
            RegistrationVal: '1',
            PlanNumber: action.payload.planNumber,
            user_registration_date: new Date().toLocaleDateString(),
          });
          this.loginService.processUserAuthorizationSuccessResponse(
            action.payload.userMatchSuccessResponse.state
          );
        })
      ),
    { dispatch: false }
  );

  verifyPlanNumberFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.VerifyPlanNumberFailure>(
          userActions.UserActionTypes.VerifyPlanNumberFailure
        ),
        map(({ payload }) => {
          const state = payload.error;
          if (state?.additionalInfo?.userState === UserStateTypeEnum.LOCKED) {
            this.router.navigateByUrl(LoginRoutesEnum.LOGIN_PAGE);
            const modalData: ModalAlertData = {
              title: 'Maximum attempts reached',
              content: {
                content:
                  // eslint-disable-next-line max-len
                  "<p>You've reached the maximum number of account creation attempts.</p> <p>For more guidance about My Account, you can visit our <a class='text-item' href='https://www.domesticandgeneral.com/content/contact-domestic-general'>help page.</a></p>",
                html: true,
              },
              alertType: AlertType.ERROR,
              closeButtonLabel: '',
            };
            this.modalDialogService.openModal(
              new OpenAlertModal(modalData, null, null, false)
            );
          } else {
            this.analyticsService.push({
              event: 'laEvent',
              laEvent: 'plan-number-verification-failure',
              event_category: 'form-registration-journey-plan-errors',
              event_action: 'enter-plan-number-page',
              event_label: 'PlanNumber=' + payload.planNumber,
            });
            const modalData: ModalConfirmData = {
              title: 'Your address and plan details do not match',
              content:
                'To successfully set up your account, your address and plan details need to match. Therefore, you’ll need to check your details and try again.',
              confirmButtonLabel: 'Check address',
              closeButtonLabel: 'Check plan number',
            };

            this.modalDialogService.openModal(
              new OpenConfirmModal(
                (modalAnswer: boolean) =>
                  modalAnswer
                    ? this.loginService.processUserAuthorizationSuccessResponse(
                        UserStateTypeEnum.MATCH_REQ
                      )
                    : this.modalDialogService.openModal(new CloseModal()),
                modalData,
                'auto',
                '800px',
                true
              )
            );
          }
        })
      ),
    { dispatch: false }
  );

  createAccount$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.CreateAccount>(
        userActions.UserActionTypes.CreateAccount
      ),
      switchMap((action) => {
        return this.accountService
          .createAccount(action.payload.createAccountPayload)
          .pipe(
            map((response) => {
              return new userActions.CreateAccountSuccess(response);
            }),
            catchError((error: CreateAccountErrorResponse) =>
              of(new userActions.CreateAccountFailure({ error }))
            )
          );
      })
    )
  );

  createAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.CreateAccountSuccess>(
          userActions.UserActionTypes.CreateAccountSuccess
        ),
        map(() => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'new-user-account-created',
            event_category: 'user-registration-journey',
            event_action: 'user-requires-password-reset',
            event_label: 'user-login-page',
            user_type: this.loginService.getUserType(),
          });
          this.loginService.processUserAuthorizationSuccessResponse(
            UserStateTypeEnum.CREATE_ACCOUNT
          );
        })
      ),
    { dispatch: false }
  );

  createAccountFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.CreateAccountFailure>(
          userActions.UserActionTypes.CreateAccountFailure
        ),
        map((error: any | HttpErrorResponse) => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user_failed_to_register',
            event_category: 'user-registration-journey',
            event_action: 'create-new-account-response-failure',
            event_label: 'user-address-verification-page',
          });
          this.handleErrorResponse(
            this.getNestedObjectProperty(error, 'payload', 'error')
          );
        })
      ),
    { dispatch: false }
  );

  requestForgottenPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.RequestForgottenPassword>(
        userActions.UserActionTypes.RequestForgottenPassword
      ),
      switchMap((action) => {
        const { payload } = action;
        this.analyticsService.push({
          event: 'laEvent',
          event_action: 'user-requested-new-email-for-password-reset',
          event_category: 'user-registration-journey',
          event_label: 'forgot-password-page',
          laEvent: 'user-requested-new-password-success',
        });
        return this.accountService.requestPassword(action.payload).pipe(
          map(
            () =>
              new userActions.RequestForgottenPasswordSuccess(action.payload)
          ),
          catchError((error: RequestPasswordErrorResponse) =>
            of(
              new userActions.RequestForgottenPasswordFailure({
                error,
                payload,
              })
            )
          )
        );
      })
    )
  );

  requestForgottenPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.RequestForgottenPasswordSuccess>(
          userActions.UserActionTypes.RequestForgottenPasswordSuccess
        ),
        map(() => {
          const user: User = this.loginService.decodeUserFromJWTCookie();
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'forgot-password-page',
            pagename: 'password-reset',
            genericpagename: 'password-reset',
            category: 'my-account-dgx',
            customerID: user && user.sub ? user.sub : '',
            DigitalID:
              user && user['custom:digital_id']
                ? user['custom:digital_id']
                : '',
          });
          this.loginService.processUserAuthorizationSuccessResponse(
            UserStateTypeEnum.PASSWORD_RESET_REQ
          );
        })
      ),
    { dispatch: false }
  );

  requestForgottenPasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.RequestForgottenPasswordFailure>(
          userActions.UserActionTypes.RequestForgottenPasswordFailure
        ),
        map(({ payload }) => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-requested-new-password-failure',
            event_category: 'user-registration-journey',
            event_action: payload.error.message,
            event_label: 'forgot-password-page',
          });
          this.loginService.handleRequestPasswordError(
            payload.error,
            payload.payload
          );
          const modalData: ModalAlertData = {
            title: payload.error.additionalInfo.userState
              ? payload.error.additionalInfo.userState.toUpperCase()
              : 'UNKNOWN ERROR',
            content: payload.error.message
              ? payload.error.message.toUpperCase()
              : 'UNKNOWN ERROR MESSAGE',
            alertType: AlertType.ERROR,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', false)
          );
        })
      ),
    { dispatch: false }
  );

  verifyUserEmailInbox$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.VerifyUserEmailInbox>(
        userActions.UserActionTypes.VerifyUserEmailInbox
      ),
      switchMap((action: any) => {
        return this.accountService.verificationCode(action.payload).pipe(
          map(
            (response) => new userActions.VerifyUserEmailInboxSuccess(response)
          ),
          catchError((error: HttpErrorResponse) =>
            of(new userActions.VerifyUserEmailInboxFailure(error))
          )
        );
      })
    )
  );

  verifyUserEmailInboxSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.VerifyUserEmailInboxSuccess>(
          userActions.UserActionTypes.VerifyUserEmailInboxSuccess
        ),
        map((response: any) => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-email-verfied-successfully',
            event_category: 'user-registration-journey',
            event_action: 'new-user-email-verification-complete',
            event_label: 'user-email-verified',
            pagename: 'user-email-verified',
            genericpagename: 'user-email-verified',
          });
          this.loginService.processUserAuthorizationSuccessResponse(
            response.payload.state
          );
          const decodedUser: User = jwt_decode(
            this.cookieService.get('AWS_IDT')
          );
          this.store.dispatch(new userActions.LogIn({ decodedUser }));
        })
      ),
    { dispatch: false }
  );

  verifyUserEmailInboxFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.VerifyUserEmailInboxFailure>(
          userActions.UserActionTypes.VerifyUserEmailInboxFailure
        ),
        map((error: any) => {
          const state =
            error.payload.state || error.payload.additionalInfo.userState;
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-email-verfication-failure',
            event_category: 'user-registration-journey',
            event_action: this.loginService.getContentErrorMessage(state),
            event_label: 'forgot-password-page',
          });
          this.resendEmailModal(state);
        })
      ),
    { dispatch: false }
  );

  verifyConfirmationCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.VerifyConfirmationCode>(
        userActions.UserActionTypes.VerifyConfirmationCode
      ),
      tap((action) =>
        this.localStorageManagerService.setLastUsedEmailAddress(
          action.payload?.email
        )
      ),
      switchMap((action: any) => {
        return this.accountService.autoAccountCheck(action.payload).pipe(
          map(
            (response) =>
              new userActions.VerifyConfirmationCodeSuccess(response)
          ),
          catchError((error: any) =>
            of(new userActions.VerifyConfirmationCodeFailure(error))
          )
        );
      })
    )
  );

  verifyConfirmationCodeFailure$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.VerifyConfirmationCodeFailure>(
          userActions.UserActionTypes.VerifyConfirmationCodeFailure
        ),
        tap((error: any) => {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const { userState } = error?.payload?.additionalInfo;

          if (userState === UserStateTypeEnum.ALREADY_COMPLETED) {
            this.router.navigate(['my-account']);
          } else {
            const state =
              error.payload.state || error.payload.additionalInfo.userState;
            this.resendEmailModal(state);
            this.router.navigate(['my-account/verify-email']);
          }
        })
      ),
    { dispatch: false }
  );

  requestVerificationCode$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.RequestVerificationCode>(
        userActions.UserActionTypes.RequestVerificationCode
      ),
      switchMap((action: any) => {
        const user = this.loginService.decodeUserFromJWTCookie();
        if (user) {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'validate-registration-email-page',
            customerID: user && user.sub ? user.sub : '',
            DigitalID:
              user && user['custom:digital_id']
                ? user['custom:digital_id']
                : '',
          });
        }
        return this.accountService.requestVerificationCode(action.payload).pipe(
          map(
            (response) =>
              new userActions.RequestVerificationCodeSuccess(response)
          ),
          catchError((error: RequestVerificationCodeErrorResponse) =>
            of(new userActions.RequestVerificationCodeFailure({ error }))
          )
        );
      })
    )
  );

  requestVerificationCodeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.RequestVerificationCodeSuccess>(
          userActions.UserActionTypes.RequestVerificationCodeSuccess
        ),
        map(() => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-received-email-verfication-succesfully',
            event_category: 'user-registration-journey',
            event_action: 'email-sent-to-user-inbox',
            event_label: 'forgot-password-page',
            user_registration_date: new Date().toLocaleDateString(),
          });
          const modalData: ModalAlertData = {
            title: 'Request successful',
            content: { content: 'An email verification link has been resent.' },
            alertType: AlertType.SUCCESS,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  requestVerificationCodeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.RequestVerificationCodeFailure>(
          userActions.UserActionTypes.RequestVerificationCodeFailure
        ),
        map((action: any) => {
          let title = 'Something went wrong';
          let content = {
            content:
              'We weren’t able to send you a new validation link. Please try again shortly.',
          };
          const state =
            action.payload.error.state ||
            action.payload.error.additionalInfo.userState;
          if (state === UserStateTypeEnum.CONFIRMATION_NOT_REQUIRED) {
            title = this.loginService.getTitleErrorMessage(state);
            content = this.loginService.getContentErrorMessage(state);
            this.router.navigate([LoginRoutesEnum.LOGIN_PAGE], {
              state: { isFromAutoAccount: true },
            });

            const modalData: ModalAlertData = {
              title,
              content,
              alertType: AlertType.ERROR,
              closeButtonLabel: 'Close',
            };
            this.modalDialogService.openModal(
              new OpenAlertModal(modalData, '600px', '200px', true)
            );
          }

          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-failed-to-receive-new-verfication-email',
            event_category: 'user-registration-journey',
            event_action: content,
            event_label: 'forgot-password-page',
          });

          const modalData: ModalAlertData = {
            title,
            content,
            alertType: AlertType.ERROR,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.ResetPassword>(
        userActions.UserActionTypes.ResetPassword
      ),
      map((action: userActions.ResetPassword) => action.payload),
      switchMap((payload) => {
        const { resetPasswordPayload, loginPayload } = payload;
        return this.accountService.resetPassword(resetPasswordPayload).pipe(
          map(() => new userActions.ResetPasswordSuccess(loginPayload)),
          catchError(
            (error: ResetPasswordErrorResponse | LoginErrorResponse | any) =>
              of(
                new userActions.ResetPasswordFailure({
                  error,
                  payload: loginPayload,
                })
              )
          )
        );
      })
    )
  );

  resetPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.ResetPasswordSuccess>(
          userActions.UserActionTypes.ResetPasswordSuccess
        ),
        map(() => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-reset-password-response-success',
            pagename: 'password-sent',
            genericpagename: 'password--reset',
            category: 'my-account',
          });
        })
      ),
    { dispatch: false }
  );

  requestVerificationToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<userActions.RequestVerificationToken>(
        userActions.UserActionTypes.RequestVerificationToken
      ),
      map((action: userActions.RequestVerificationToken) => action.payload),
      switchMap((payload) => {
        return this.accountService.requestVerificationToken(payload).pipe(
          map(
            (response: any) =>
              new userActions.RequestVerificationTokenSuccess(response)
          ),
          catchError((error: HttpErrorResponse | any) =>
            of(
              new userActions.RequestVerificationTokenFailure({
                error,
              })
            )
          )
        );
      })
    )
  );

  requestVerificationTokenSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.RequestVerificationTokenSuccess>(
          userActions.UserActionTypes.RequestVerificationTokenSuccess
        ),
        map(() => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-request-verification-token-success',
            pagename: 'create-new-account',
            genericpagename: 'create-new-account',
            category: 'my-account',
          });
          this.router.navigateByUrl(LoginRoutesEnum.VERIFY_EMAIL_PAGE);
        })
      ),
    { dispatch: false }
  );

  requestVerificationTokenFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.RequestVerificationTokenFailure>(
          userActions.UserActionTypes.RequestVerificationTokenFailure
        ),
        map((errorResponse: any) => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-request-verification-token-failure',
            pagename: 'create-new-account',
            genericpagename: 'create-new-account',
            category: 'my-account',
          });

          errorResponse?.payload?.error?.additionalInfo?.userState ===
          'USERNAME_EXISTS'
            ? this.loginService.openUsernameAlreadyExistsErrorModal()
            : this.loginService.openUnexpectedErrorModal();
        })
      ),
    { dispatch: false }
  );

  resetPasswordFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.ResetPasswordFailure>(
          userActions.UserActionTypes.ResetPasswordFailure
        ),
        map(({ payload }: any) => {
          this.analyticsService.push({
            event: 'laEvent',
            laEvent: 'user-reset-password-response-failure',
            pagename: 'password-sent',
            genericpagename: 'password-reset',
            category: 'my-account',
            event_category: 'user-registration-journey-errors',
            event_action: payload.error.error_message,
            event_label: 'password-reset',
          });

          const modalData: ModalAlertData = {
            title: payload.error.additionalInfo.userState.toUpperCase(),
            content: payload.error.message.toUpperCase(),
            alertType: AlertType.ERROR,
            closeButtonLabel: 'Close',
          };
          this.modalDialogService.openModal(
            new OpenAlertModal(modalData, '600px', '200px', true)
          );
        })
      ),
    { dispatch: false }
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.LogOut>(userActions.UserActionTypes.LogOut),
        tap(() => {
          const isSessionActive = this.loginService.decodeUserFromJWTCookie();
          if (isSessionActive) {
            this.accountService
              .signout()
              .toPromise()
              .then(() => {
                this.store.dispatch(new ResetAccountState());
                this.store.dispatch(new userActions.ResetUserState());
                window.location.href =
                  this.config.homepageAppHost ||
                  this.config.domesticAndGeneralUrl;
              });
          } else {
            this.store.dispatch(new ResetAccountState());
            this.store.dispatch(new userActions.ResetUserState());
            window.location.href =
              this.config.homepageAppHost || this.config.domesticAndGeneralUrl;
          }
        })
      ),
    { dispatch: false }
  );

  logIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<userActions.LogIn>(userActions.UserActionTypes.LogIn)
      ),
    { dispatch: false }
  );

  private resendEmailModal(state: any) {
    const modalData: ModalAlertData = {
      title: this.loginService.getTitleErrorMessage(state),
      content: this.loginService.getContentErrorMessage(state),
      alertType: AlertType.ERROR,
      closeButtonLabel: 'Close',
    };
    this.modalDialogService.openModal(
      new OpenAlertModal(modalData, '600px', '200px', true)
    );
  }

  private handleErrorResponse(httpErrorResponse: any): void {
    httpErrorResponse && httpErrorResponse instanceof HttpErrorResponse
      ? this.handleHttpErrorResponse(httpErrorResponse)
      : this.handleUnknownError(httpErrorResponse);
  }

  private handleHttpErrorResponse(httpErrorResponse: HttpErrorResponse): void {
    const { message } = httpErrorResponse.error;
    const state =
      httpErrorResponse.error.state ||
      httpErrorResponse.error.additionalInfo.userState;
    let httpErrorDialog = {
      title: state ? state.toUpperCase() : 'UNKNOWN ERROR',
      content: {
        content: message ? message.toUpperCase() : 'UNKNOWN ERROR MESSAGE',
      },
      alertType: AlertType.ERROR,
      closeButtonLabel: 'Close',
    };

    if (state === 'USERNAME_EXISTS') {
      httpErrorDialog = {
        ...httpErrorDialog,
        title: 'Username error',
        content: {
          content:
            'This email address is already in use. Please try again using a different one.',
        },
      };
    }
    this.modalDialogService.openModal(
      new OpenAlertModal(httpErrorDialog, '600px', '200px', false)
    );
  }

  private handleUnknownError(error: any) {
    const httpErrorDialog = {
      title: 'SOMETHING WENT WRONG',
      content: {
        content: 'An unexpected error has occurred. Please try again later',
      },
      alertType: AlertType.ERROR,
      closeButtonLabel: 'Close',
    };
    this.modalDialogService.openModal(
      new OpenAlertModal(httpErrorDialog, '600px', '200px', true)
    );
  }

  private getNestedObjectProperty(obj, ...args) {
    return args.reduce((object, level) => object && object[level], obj);
  }
}
