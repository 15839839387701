<dgx-dfb-form
  *ngIf="myFormGroup"
  [form]="myFormGroup"
  [fieldset]="fieldset"
  [formGroup]="myFormGroup"
  (validatedSubmit)="handleValidFormSubmission($any($event))"
>
  <ng-template #projected let-field>
    <ng-container [ngSwitch]="field.controlType">
      <div *ngSwitchCase="'some-content'">
        <div
          (mousedown)="onForgotYourPasswordClick()"
          class="d-flex align-items-end flex-column forgot-password-container"
        >
          <a class="link link-underline forgot-password-link" tabindex="0">
            {{ forms.loginForm.forgotPasswordLinkLabel }}</a
          >
        </div>
      </div>
    </ng-container>
  </ng-template>
</dgx-dfb-form>
