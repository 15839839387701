import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// service
import { PcaService } from '../../../../services/pca.service';
import {
  FromBuilderInput,
  FormBuilderAddressLookup,
} from '../../interfaces/formData.interface';

@Component({
  selector: 'app-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrls: ['./address-lookup.component.scss'],
})
export class AddressLookupComponent {
  @Input() data: {
    input?: FromBuilderInput;
    addressOptions?: FormBuilderAddressLookup;
  }[];
  @Input() group: FormGroup;
  @Input() showValidation;

  public addressList: [] = [];
  public displayType: string | null;
  public hideManualAddress = true;

  constructor(private pcaService: PcaService) {}

  handleAddressList(event: any) {
    event ? (this.addressList = event.Items) : (this.addressList = []);
  }

  /**
   * handleAddressClick
   * check the type of action to determine whether
   * its a searched address(list) or selected the address.
   * 1. Make a deeper search to retrieve the address details
   * 2. Loop over the formData object looking for an addressLookup object
   * 3. Conditional populate the address field the used for searching - postcode or full
   * 4. Conditional populate the address fields specified in the formData object
   * 5. Clear the addressList (dropDown)
   *
   */
  handleAddressClick(event: any) {
    if (event.Type !== null && event.Type === 'Address') {
      // 1.
      this.pcaService.pcaRetrieve(event.Id).subscribe((res: any) => {
        const pcaAddress = res.Items[0];

        // 2.
        this.data.forEach((element) => {
          if (!element.addressOptions) {
            return false;
          }
          // 3.
          if (element.addressOptions.lookup) {
            if (element.addressOptions.displayType === 'full') {
              this.setAndMark('addressLookup', event, 'Text');
            }

            if (element.addressOptions.displayType === 'postcode') {
              this.setAndMark('addressLookup', pcaAddress, 'PostalCode');
            }
          }
          // 4.
          if (element.addressOptions.line1) {
            this.setAndMark('addressLine1', pcaAddress, 'Line1');
          }

          if (element.addressOptions.line2) {
            this.setAndMark('addressLine2', pcaAddress, 'Line2');
          }

          if (element.addressOptions.city) {
            this.setAndMark('city', pcaAddress, 'City');
          }

          if (element.addressOptions.postcode) {
            this.setAndMark('postcode', pcaAddress, 'PostalCode');
          }
        });
        // 5.
        this.addressList = [];
      });
    } else {
      this.pcaService.pcaFind(event.Text, event.Id).subscribe((res: any) => {
        this.addressList = res.Items;
      });
    }
  }

  setAndMark(
    controlItem: string,
    pcaAddress: Record<string, unknown>,
    value: string
  ) {
    const control = this.group.get(controlItem);
    if (control) {
      control.setValue(pcaAddress[value]);
      control.markAsTouched();
    }
  }

  handleExpandable(event: any): boolean {
    return (event.Type !== null && event.Type === 'Address') ||
      (event.Next !== null && event.Next === 'Retrieve')
      ? true
      : false;
  }

  /**
   * toggleManualAddress
   */
  public toggleManualAddress(event: boolean) {
    this.hideManualAddress = !this.hideManualAddress;
  }
}
