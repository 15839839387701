import { inject, InjectionToken } from '@angular/core';

import { USERAGENT } from './user-agent.token';

export const SAFARI: InjectionToken<boolean> = new InjectionToken(
  'Safari Browser flag',
  {
    factory: (): boolean =>
      /^((?!chrome|android).)*safari/i.test(inject(USERAGENT)),
    providedIn: 'root',
  }
);
