<ng-container *ngFor="let item of data">
  <app-inputs
    *ngIf="item.addressOptions.lookup === undefined"
    [item]="item.input"
    [showValidation]="showValidation"
    [validators]="item.input.validators"
    [group]="group"
    [hint]="item.input.hint"
    [label]="item.input.label"
    [labelFor]="item.input.control"
    [ngClass]="{
      hidden: item.addressOptions.hide && hideManualAddress
    }"
  ></app-inputs>
  <!-- Address loop up field -->
  <div
    *ngIf="item.addressOptions.lookup === true"
    [formGroup]="group"
    class="form-input legacy-form-builder"
    [ngClass]="{
      'is-invalid':
        !group.controls[item.input.control].valid &&
        group.controls[item.input.control]?.touched,
      'is-valid':
        group.controls[item.input.control]?.valid &&
        group.controls[item.input.control]?.touched,
      hidden: !item.addressOptions.hide && !hideManualAddress
    }"
  >
    <app-label
      *ngIf="item.input"
      [label]="item.input"
      [class]="item.input.classes"
      [forElement]="item.input.control"
      >{{ item.input.label.text }}
    </app-label>
    <app-hint *ngIf="hint" [hint]="hint"></app-hint>

    <div class="form-control">
      <input
        [class]="item.input.classes"
        [formControlName]="item.input.control"
        [placeholder]="item.input.placeholder"
        [id]="item.input.control"
        [name]="item.input.control"
        type="text"
        (blur)="showValidation = true"
        addressLookUp
        (addressList)="handleAddressList($event)"
      />
      <i class="icon icon-tick-circle"></i>
      <i class="icon icon-error-circle"></i>
      <ul
        id="addressList"
        class="address-look-up"
        [ngClass]="{
          hidden: addressList.length === 0
        }"
      >
        <li
          *ngFor="let address of addressList"
          (click)="handleAddressClick(address)"
        >
          {{ address.Text }}, {{ address.Description }}
          <span
            class="glyphicon glyphicon-chevron-right"
            [ngClass]="{
              hidden: handleExpandable(address)
            }"
            aria-hidden="true"
          ></span>
        </li>
      </ul>
      <div class="row row--spacer-top-small no-gutters">
        <div class="col">
          <p (click)="toggleManualAddress()" class="link link-underline">
            Enter Address Manually
          </p>
        </div>
      </div>
    </div>
    <ng-container *ngIf="item.input.validators && showValidation">
      <app-validation-error-formcontrol
        [group]="group"
        [validators]="item.input.validators"
        [control]="item.input.control"
      ></app-validation-error-formcontrol>
    </ng-container>
  </div>
</ng-container>
