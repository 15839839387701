<header [class]="themeClass" (mouseleave)="onMouseLeaveHeader()">
  <div class="container">
    <div class="d-flex header-wrapper">
      <a class="header__logo" data-automation="home" (click)="onDGLogoClick()">
        <img src="./assets/images/dg-logo.svg" alt="Domestic & General" />
      </a>

      <div class="header__account" *ngIf="isUserStatusComplete$ | async">
        <ul class="d-flex">
          <li>
            <a
              class="header-link"
              data-automation="person"
              [ngClass]="{ 'header-link--active': navOpen }"
              (click)="onNavOpenClick()"
            >
              <span class="">
                <svg class="icon icon-person">
                  <use xlink:href="#icon-person"></use>
                </svg>
              </span>
            </a>

            <nav
              class="header__account-nav"
              [ngClass]="{ 'header__account-nav--open': navOpen }"
            >
              <ul>
                <li>
                  <a class="text--center" (click)="onMyDetailsClick()">
                    <span>My details</span>
                  </a>
                </li>
                <li>
                  <a (click)="logout()" class="text--center">
                    <span>Log out</span>
                  </a>
                </li>
              </ul>
            </nav>
          </li>
          <li>
            <a
              class="header-link"
              [ngClass]="{ 'header-link--active': false }"
              (click)="onHomeClick()"
            >
              <span class="">
                <svg class="icon icon-home">
                  <use xlink:href="#icon-home"></use>
                </svg>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
