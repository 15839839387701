import { ModuleWithProviders, NgModule } from '@angular/core';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';
import { PcaService } from './pca.service';

@NgModule({
  declarations: [],
  imports: [],
  schemas: [],
  providers: [],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [AnalyticsService, PcaService],
    };
  }
}
