<label for="planType">{{ label }}</label>
<div class="select-wrapper">
  <select
    id="planType"
    (change)="inputChange.emit($event)"
    class="form-control"
  >
    <option *ngFor="let option of options" value="{{ option.value }}">
      {{ option.name }}
    </option>
  </select>
  <i class="icon icon-arrow-down"></i>
</div>
