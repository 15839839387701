import { HttpHeaders } from '@angular/common/http';

export const STANDARD_HTTP_API_CALL_HEADERS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    accept: 'application/json',
  }),
};

export const WITH_CREDENTIALS_HTTP_API_CALL_HEADERS = {
  STANDARD_HTTP_API_CALL_HEADERS,
  withCredentials: true,
};

export const OBSERVE_RESPONSE_HTTP_API_CALL_HEADERS = {
  STANDARD_HTTP_API_CALL_HEADERS,
  withCredentials: true,
  observe: 'response',
};
