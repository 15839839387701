import { Footer } from '@athome-myaccount/shared/util-models';
import { NavigationLinks } from '@domgen/dgx-fe-business-models';

export interface Environment {
  accountLoginUrl: string;
  apiVersion: string;
  cleanLastUsedEmail: boolean;
  claimRedirectQueryParams: string;
  configPath?: string;
  debugCookieName: string;
  externalMaintenanceUrl: string;
  footerLinksBuilder?: (basePath: string) => Footer;
  headerLinksBuilder?: (basePath: string) => NavigationLinks;
  production?: boolean;
  notAllowedUrlsForUnauthorizedRedirect: string[];
  requestSource?: string;
  requestAction?: string;
  vokeraBrands: string[];
  wlClient?: string;
}
