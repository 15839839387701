<button
  role="button"
  [class]="classes"
  tabindex="0"
  [type]="type ? type : 'button'"
  [attr.disabled]="disabled ? '' : null"
  [attr.data-automation]="text"
>
  <span>{{ text }}</span>
  <ng-content></ng-content>
</button>
