<div>
  <div
    class="error-info-container"
    fxLayout="column"
    fxLayoutAlign="stretch center"
    fxLayoutGap="40px"
  >
    <div class="typog-h3">
      {{ 'Unauthorized_Page.header' | translate }}
    </div>
    <div class="typog-body-large">
      {{ 'Unauthorized_Page.body' | translate }}
    </div>
    <app-error-cta
      (contactUsClicked)="handleHomeClick()"
      (homeClicked)="handleContactUsClick()"
    ></app-error-cta>
    <div class="typog-h3">Error code: 401/403</div>
  </div>
</div>
