import { Directive, ElementRef } from '@angular/core';
import { AnalyticsService } from '@athome-myaccount/my-account/data-access-shared';

@Directive({
  selector: '[appShowPassword]',
})
export class ShowPasswordDirective {
  private _shown = false;
  constructor(private el: ElementRef, private _analytics: AnalyticsService) {
    this.setup();
  }
  toggle(span: HTMLElement) {
    this._shown = !this._shown;

    this.el.nativeElement.setAttribute(
      'type',
      this._shown ? 'text' : 'password'
    );
    span.innerHTML = this._shown ? 'Hide' : 'Show';

    this._analytics.push({
      event: 'laEvent',
      laEvent: 'user_registration',
      event_category: 'User',
      event_action: 'userRegistration',
      event_label: this._shown ? 'Password Show' : 'Password Hide',
    });
  }
  setup() {
    if (this.el.nativeElement.parentNode) {
      this.el.nativeElement.setAttribute('type', 'password');

      const parent = this.el.nativeElement.parentNode;
      const div = document.createElement('a');
      const icon = document.createElement('span');
      const text = document.createElement('span');
      // TODO WL: need to review how to WL this, it is not compatible with themeing
      div.href = '';
      div.style.position = 'absolute';
      div.style.top = '-40px';
      div.style.right = '14px';
      div.classList.add('show-hide-icon');
      div.style.marginTop = '0px';

      icon.className = 'icon-dma-eye';
      icon.style.position = 'absolute';
      icon.style.top = '5px';
      icon.style.left = '-25px';
      icon.style.color = '#333';
      icon.style.marginRight = '5px';
      icon.style.fontSize = '16px';

      text.style.letterSpacing = '0.8px';
      text.style.display = 'inline-block';
      text.style.textAlign = 'left';
      text.style.left = '3px';
      text.style.position = 'relative';
      text.innerHTML = `Show`;
      text.classList.add('link');
      text.style.textDecoration = 'none';

      div.addEventListener('click', (event) => {
        event.preventDefault();
        this.toggle(text);
      });

      div.appendChild(icon);
      div.appendChild(text);

      parent.appendChild(div);
    }
  }
}
