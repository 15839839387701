import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { distinctUntilChanged, skipWhile, takeWhile } from 'rxjs/operators';
import { AuthLibFacade } from '../../+state/auth.facade';
import { ApiResponseTypeEnum } from '../../common/enums/api-response-type.enum';
import {
  AuthModuleConfigForms,
  AUTH_FORMS,
} from '../../common/tokens/auth-config-token';

@Component({
  selector: 'athome-myaccount-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  isComponentAlive = true;
  forgotPasswordForm: FormGroup;
  forgotPasswordResponse$: Observable<ApiResponseTypeEnum> =
    this.facade.forgotPasswordResponse$;
  submitted = false;

  @Output() backToLoginButtonClicked = new EventEmitter();
  @Output() forgotPasswordResponseSuccess = new EventEmitter();
  @Output() forgotPasswordResponseError = new EventEmitter();
  @Output() formValueChange = new EventEmitter<any>();

  constructor(
    @Inject(AUTH_FORMS) public forms: AuthModuleConfigForms,
    private facade: AuthLibFacade,
    private formBuilder: FormBuilder
  ) {}

  get f(): any {
    return this.forgotPasswordForm.controls;
  }

  ngOnInit(): void {
    this.initForm();
    this.onFormValueChange();
    this.onForgotPasswordResponse();
  }

  initForm(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit(): void {
    this.submitted = true;
    this.forgotPasswordForm.valid
      ? this.facade.requestForgotPasswordForUser({
          email: this.forgotPasswordForm.get('email').value,
          is_sign_up: false,
        })
      : this.forgotPasswordForm.markAllAsTouched();
  }

  onFormValueChange(): void {
    this.forgotPasswordForm.valueChanges
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .subscribe((data) => {
        this.formValueChange.emit(data);
      });
  }

  onForgotPasswordResponse(): void {
    this.forgotPasswordResponse$
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .pipe(
        skipWhile(
          (responseStatus: ApiResponseTypeEnum) =>
            responseStatus === ApiResponseTypeEnum.NOT_CALLED
        )
      )
      .subscribe((responseStatus: ApiResponseTypeEnum) => {
        if (responseStatus === ApiResponseTypeEnum.SUCCESS) {
          this.forgotPasswordResponseSuccess.emit();
        }
        if (responseStatus === ApiResponseTypeEnum.ERROR) {
          this.forgotPasswordResponseError.emit();
        }
      });
  }

  backToLoginClick(): void {
    this.backToLoginButtonClicked.emit();
  }

  ngOnDestroy(): void {
    this.facade.resetApiResponseState();
    this.isComponentAlive = false;
  }
}
