import {
  Failure,
  InProgress,
  NotAsked,
  RemoteData,
  Success,
} from 'ngx-remotedata';
import {
  GetMarketingPreferenceResponse,
  GetPersonalDetailsResponse,
} from '@athome-myaccount/my-account/util-login';
import {
  PersonalDetailsActions,
  PersonalDetailsActionTypes,
} from '../actions/personal-details.actions';

export interface PersonalDetailsState {
  getPersonDetailsResponse: RemoteData<GetPersonalDetailsResponse, Error>;
  updateContactDetailsResponse: RemoteData<any, Error>;
  updateCorrespondenceAddress: RemoteData<any, Error>;
  updatePassword: RemoteData<any, Error>;
  updateMarketingPreference: RemoteData<any, Error>;
  getMarketingPreferenceResponse: RemoteData<
    GetMarketingPreferenceResponse,
    Error
  >;
  updateDocPreferenceResponse: RemoteData<any, Error>;
}

const initialPersonalDetailsState: PersonalDetailsState = {
  getPersonDetailsResponse: NotAsked.of(),
  updateContactDetailsResponse: NotAsked.of(),
  updateCorrespondenceAddress: NotAsked.of(),
  updatePassword: NotAsked.of(),
  updateMarketingPreference: NotAsked.of(),
  getMarketingPreferenceResponse: NotAsked.of(),
  updateDocPreferenceResponse: NotAsked.of(),
};

export function personalDetailsReducer(
  state = initialPersonalDetailsState,
  action: PersonalDetailsActions
): PersonalDetailsState {
  switch (action.type) {
    case PersonalDetailsActionTypes.GetPersonDetails:
      return {
        ...state,
        getPersonDetailsResponse: InProgress.of(),
      };
    case PersonalDetailsActionTypes.GetPersonDetailsSuccess:
      return {
        ...state,
        getPersonDetailsResponse: Success.of(action.payload.response),
      };
    case PersonalDetailsActionTypes.GetPersonDetailsFailure:
      return {
        ...state,
        getPersonDetailsResponse: Failure.of(action.payload.error),
      };

    case PersonalDetailsActionTypes.UpdateContactDetails:
      return {
        ...state,
        updateContactDetailsResponse: InProgress.of(),
      };
    case PersonalDetailsActionTypes.UpdateContactDetailsSuccess:
      return {
        ...state,
        updateContactDetailsResponse: Success.of(action.payload),
      };
    case PersonalDetailsActionTypes.UpdateContactDetailsFailure:
      return {
        ...state,
        updateContactDetailsResponse: Failure.of(action.payload.error),
      };

    case PersonalDetailsActionTypes.UpdateCorrespondenceAddress:
      return {
        ...state,
        updateCorrespondenceAddress: InProgress.of(),
      };
    case PersonalDetailsActionTypes.UpdateCorrespondenceAddressSuccess:
      return {
        ...state,
        updateCorrespondenceAddress: Success.of(action.payload),
      };
    case PersonalDetailsActionTypes.UpdateCorrespondenceAddressFailure:
      return {
        ...state,
        updateCorrespondenceAddress: Failure.of(action.payload.error),
      };
    case PersonalDetailsActionTypes.ResetPersonalDetailsState:
      return { ...initialPersonalDetailsState };

    case PersonalDetailsActionTypes.UpdatePassword:
      return {
        ...state,
        updatePassword: InProgress.of(),
      };

    case PersonalDetailsActionTypes.UpdatePasswordSuccess:
      return {
        ...state,
        updatePassword: Success.of(null),
      };

    case PersonalDetailsActionTypes.UpdatePasswordFailure:
      return {
        ...state,
        updatePassword: Failure.of(action.payload),
      };
    case PersonalDetailsActionTypes.GetMarketingPreference:
      return {
        ...state,
        getMarketingPreferenceResponse: InProgress.of(),
      };
    case PersonalDetailsActionTypes.GetMarketingPreferenceSuccess:
      return {
        ...state,
        getMarketingPreferenceResponse: Success.of(action.payload.response),
      };
    case PersonalDetailsActionTypes.GetMarketingPreferenceFailure:
      return {
        ...state,
        getMarketingPreferenceResponse: Failure.of(action.payload),
      };
    case PersonalDetailsActionTypes.UpdateMarketingPreference:
      return {
        ...state,
        updateMarketingPreference: InProgress.of(),
      };

    case PersonalDetailsActionTypes.UpdateMarketingPreferenceSuccess:
      return {
        ...state,
        updateMarketingPreference: Success.of(null),
      };

    case PersonalDetailsActionTypes.UpdateMarketingPreferenceFailure:
      return {
        ...state,
        updateMarketingPreference: Failure.of(action.payload),
      };
    case PersonalDetailsActionTypes.UpdateDocPreference:
      return {
        ...state,
        updateDocPreferenceResponse: InProgress.of(),
      };
    case PersonalDetailsActionTypes.UpdateDocPreferenceSuccess:
      return {
        ...state,
        updateDocPreferenceResponse: Success.of(action.payload),
      };
    case PersonalDetailsActionTypes.UpdateDocPreferenceFailure:
      return {
        ...state,
        updateDocPreferenceResponse: Failure.of(action.payload.error),
      };
    default:
      return state;
  }
}
