import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-dialog-unsupported-browsers',
  templateUrl: './modal-dialog-unsupported-browsers.component.html',
  styleUrls: ['./modal-dialog-unsupported-browsers.component.scss'],
})
export class ModalDialogUnsupportedBrowsersComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalDialogUnsupportedBrowsersComponent>
  ) {}
}
