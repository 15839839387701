import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DfbPasswordResetComponent } from './dfb-password-reset.component';

import { SharedUiDynamicFormBuilderModule } from '@domgen/dgx-fe-dynamic-form-builder';
@NgModule({
  declarations: [DfbPasswordResetComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedUiDynamicFormBuilderModule.forRoot({
      postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
      postcodeServiceFind:
        'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
      postcodeServiceRetrieve:
        'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
    }),
  ],
  exports: [DfbPasswordResetComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DfbPasswordResetModule {}
