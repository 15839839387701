import {
  trigger,
  animate,
  transition,
  style,
  state,
} from '@angular/animations';

export const fadeInAnimation = trigger('fadeInAnimation', [
  // route 'enter' transition
  transition(':enter', [
    // styles at start of transition
    style({ opacity: 0 }),

    // animation and styles at end of transition
    animate('0.3s', style({ opacity: 1 })),
  ]),
]);

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  transition(':enter', [
    style({ opacity: 0, height: 0 }),
    animate('500ms', style({ opacity: 1, height: '*' })),
  ]),
  transition(':leave', [animate('500ms', style({ opacity: 0, height: 0 }))]),
]);

export const showHide = trigger('showHide', [
  state(
    'hidden',
    style({
      height: '0',
    })
  ),
  state(
    'visible',
    style({
      height: '*',
    })
  ),
  transition('visible <=> hidden', animate('{{transitionParams}}')),
]);
