export enum ApplianceTypeEnum {
  All = 'All',
  Protected = 'Protected',
  Expiring = 'Expiring',
  Expired = 'Expired',
  Cancelled = 'Cancelled',
  MultiplanProtected = 'MultiplanProtected',
  MultiplanExpiring = 'MultiplanExpiring',
  MultiplanExpired = 'MultiplanExpired',
  MultiplanCancelled = 'MultiplanCancelled',
}

export enum PlanStateTypeEnum {
  RENEWAL = 'RENEWAL',
  LAPSED = 'LAPSED',
  CANCELLED = 'CANCELLED',
  COVERED = 'COVERED',
  ALL = 'ALL',
}

export enum PlanTypeEnum {
  CUSTOMER_PLAN = 'CUSTOMER_PLAN',
  LANDLORD_PLAN = 'LANDLORD_PLAN',
}

export enum ClaimActionTypeEnum {
  REQUEST_REPAIR = 'REQUEST_REPAIR',
  BOOK_ENGINEER = 'BOOK_ENGINEER',
  INCOMPLETE_FORM = 'INCOMPLETE_FORM',
  TRACK_REPAIR = 'TRACK_REPAIR',
}

export enum PlanActionButtonTypeEnum {
  GetProtection = 'Get Protection',
  ReactivatePlan = 'Reactivate Plan',
  TryRepairAndCare = 'Try Repair And Care',
  Manage = 'Manage',
  BookRepair = 'Book Service',
  RenewPlan = 'Renew Plan',
  TrackRepair = 'Track Repair',
  ViewPlan = 'View plan',
  BookOrTrackRepair = 'Repairs and services',
  PlumbingAndDrainage = 'Make a claim',
}

export enum ClaimStatusTypeEnum {
  BookingInProgress = 'Booking in progress',
  RepairRequestCancelled = 'Repair request cancelled',
  RepairInProgress = 'Repair in progress',
  RepairBookingNeeded = 'Repair booking needed',
  ManualReferral = 'Manual Referral',
  ActiveRepair = 'Active Repair',
}
