<div class="hero bg--primary">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="hero_content">
          <ng-content select="img"></ng-content>
          <ng-content select="h1"></ng-content>
          <ng-content select="h2"></ng-content>

          <ng-content select="section"></ng-content>

          <ng-content select="app-button"></ng-content>
          <ng-content select="app-progress-bar"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
