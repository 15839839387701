import { ModuleWithProviders, NgModule } from '@angular/core';
import { PersonalDetailsHttpService } from './../containers/my-details/services/personal-details-http.service';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';
import { NotifyToastService } from './notify-toast.service';

@NgModule({
  declarations: [],
  imports: [],
  schemas: [],
  providers: [],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        NotifyToastService,
        LoginService,
        AuthService,
        PersonalDetailsHttpService,
      ],
    };
  }
}
