<div
  [formGroup]="group"
  class="form-input legacy-form-builder"
  [ngClass]="{
    'is-invalid':
      !item.readOnly &&
      !group.controls[item.control].valid &&
      group.controls[item.control]?.touched,
    'is-valid':
      !item.readOnly &&
      group.controls[item.control]?.valid &&
      group.controls[item.control]?.touched
  }"
>
  <app-label
    *ngIf="label"
    [label]="label"
    [class]="label.classes"
    [forElement]="labelFor"
    >{{ label.text }}
  </app-label>
  <div *ngIf="tooltip">
    <div class="tooltip"></div>
    <p [class]="tooltip.classes">{{ tooltip.text }}</p>
  </div>
  <app-hint *ngIf="hint" [classes]="hint.classes">
    {{ hint.text }}
  </app-hint>
  <div class="form-control" *ngIf="item.useShowPassword">
    <input
      [class]="item.classes"
      [formControlName]="item.control"
      [placeholder]="item.placeholder"
      [readonly]="item.readOnly"
      [id]="item.control"
      [name]="item.control"
      [maxlength]="item.maxLength"
      (focusout)="handleFocusOutEvent(item.control)"
      type="text"
      appShowPassword
      [dgxQmSanitise]="item.dgxQmSanitise"
    />
    <i
      class="icon icon-tick-circle"
      *ngIf="group.controls[item.control]?.value"
    ></i>
    <i class="icon icon-error-circle"></i>
  </div>
  <div class="form-control" *ngIf="!item.useShowPassword">
    <input
      *ngIf="isNameOrSurname(item.control)"
      [class]="item.classes"
      [formControlName]="item.control"
      [placeholder]="item.placeholder"
      [readonly]="item.readOnly"
      [id]="item.control"
      [name]="item.control"
      [maxlength]="item.maxLength"
      (focusout)="handleFocusOutEvent(item.control)"
      type="text"
      appForbiddenName="Resident"
      [dgxQmSanitise]="item.dgxQmSanitise"
    />
    <input
      *ngIf="!isNameOrSurname(item.control)"
      [class]="item.classes"
      [formControlName]="item.control"
      [placeholder]="item.placeholder"
      [readonly]="item.readOnly"
      [id]="item.control"
      [name]="item.control"
      [maxlength]="item.maxLength"
      [dgxQmSanitise]="item.dgxQmSanitise"
      (focusout)="handleFocusOutEvent(item.control)"
      type="text"
    />
    <i
      class="icon icon-tick-circle"
      *ngIf="group.controls[item.control]?.value"
    ></i>
    <i class="icon icon-error-circle"></i>
  </div>
  <ng-container *ngIf="validators && showValidation">
    <app-validation-error-formcontrol
      [group]="group"
      [validators]="validators"
      [control]="item.control"
    ></app-validation-error-formcontrol>
  </ng-container>
  <ng-container
    *ngIf="
      group.controls[item.control]?.hasError('forbiddenName') &&
      item.control === 'name'
    "
  >
    <span class="form-input-feedback">Please enter a valid first name</span>
  </ng-container>
  <ng-container
    *ngIf="
      group.controls[item.control]?.hasError('forbiddenName') &&
      item.control === 'surname'
    "
  >
    <span class="form-input-feedback">Please enter a valid last name</span>
  </ng-container>
</div>
<!-- TODO Move below into form-element component -->
<ng-container *ngIf="item.PasswordStrengthChecker">
  <app-password-strength-checker
    [value]="passwordValue"
    [submitted]="passwordError"
    [showLength]="item.PasswordStrengthChecker.showLength"
    [showUppercase]="item.PasswordStrengthChecker.showUppercase"
    [showLowercase]="item.PasswordStrengthChecker.showLowercase"
    [showNumber]="item.PasswordStrengthChecker.showNumber"
    [showComplex]="item.PasswordStrengthChecker.showComplex"
  ></app-password-strength-checker>
</ng-container>
