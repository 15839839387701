import {
  PlanDetailViewModel,
  RepairDetailViewModel,
  PaymentDetailViewModel,
  CardPaymentDetailViewModel,
  DirectDebitPaymentDetailViewModel,
  PaymentScheduleViewModel,
  RepairerDetailsViewModel,
  ItemViewModel,
  ActiveClaimDetailViewModel,
} from '../models/plan.detail.model';
import { PlanDetail } from '../state/plan.model';
import { PaymentSchedule } from '../../../../util-account/src/lib/state/payment-schedule.model';

export function mapToPlanDetailViewModel(
  apiResponse: PlanDetail,
  codeDescriptionToImageMapper: (codeDescription: string) => string
): PlanDetailViewModel {
  return {
    number: apiResponse.plan_number,
    state: apiResponse.plan_state,
    startDate: new Date(apiResponse.plan_start),
    renewalDate: new Date(apiResponse.plan_expiry),
    planOwnerType: apiResponse.plan_owner_type,
    multiPlanNumber: apiResponse.parent_plan_number,
    multiPlanState: apiResponse.parent_plan_state,
    openRepairCallbackData: apiResponse?.open_repair?.callbackData ?? null,
    openRepairId: apiResponse.open_repair_id,
    openServiceId: apiResponse.open_service_id,
    repairAction: apiResponse.repair_action,
    serviceAction: apiResponse.service_action,
    duration: apiResponse.plan_contract_type,
    email: apiResponse.contact_details.email,
    inWarrantyContactName: apiResponse.in_warranty_contact_name,
    inWarrantyContactTel: apiResponse.in_warranty_contact_tel,
    plan_contract_type_code: apiResponse?.plan_contract_type_code ?? null,
    repairHistory: mapToRepairDetailViewModel(apiResponse),
    activeRepair: mapToActiveRepairDetailViewModel(apiResponse),
    activeService: mapToActiveServiceDetailViewModel(apiResponse),
    paymentDetails: mapToPaymentDetailViewModel(apiResponse),
    repairPayment: apiResponse.repair_payment ?? null,
    repairPaymentType: apiResponse.repair_payment_type ?? null,
    directReferral: apiResponse.direct_referral ?? null,
    item: {
      ...apiResponse.item,
      image: codeDescriptionToImageMapper(apiResponse.item.code),
    },
    repairerDetails: mapToRepairerDetailsViewModel(apiResponse),
  } as PlanDetailViewModel;
}

export function mapToRepairDetailViewModel(
  apiResponse: PlanDetail
): RepairDetailViewModel[] {
  return Object.keys(apiResponse.claims)
    .map((claimId) => ({
      reference: claimId,
      ...apiResponse.claims[claimId],
    }))
    .filter((repairDetail) => repairDetail.status !== 'OPEN');
}

export function mapToPaymentDetailViewModel(
  apiResponse: PlanDetail
): PaymentDetailViewModel {
  return {
    method: apiResponse.payment.method,
    isMultiPlan: !!apiResponse.parent_plan_number,
    cardPaymentDetail:
      apiResponse.payment.method === 'CREDIT_CARD'
        ? mapToCardPaymentDetailViewModel(apiResponse)
        : null,
    directDebitDetail:
      apiResponse.payment.method === 'DIRECT_DEBIT'
        ? mapToDirectDebitPaymentDetailViewModel(apiResponse)
        : null,
  };
}

export function mapToCardPaymentDetailViewModel(
  apiResponse: PlanDetail
): CardPaymentDetailViewModel {
  return {
    fee: `£${apiResponse.payment.credit_card_detail.plan_fee}`,
  };
}

export function mapToDirectDebitPaymentDetailViewModel(
  apiResponse: PlanDetail
): DirectDebitPaymentDetailViewModel {
  return {
    accountHolderName:
      apiResponse.payment.direct_debit_detail.account_holder_name,
    accountNumber: formatAccountNumber(
      apiResponse.payment.direct_debit_detail.masked_account_number
    ),
    sortCode: formatSortCode(apiResponse.payment.direct_debit_detail.sort_code),
    paymentSchedule: mapToPaymentScheduleViewModel(
      apiResponse.payment.direct_debit_detail.schedule
    ),
  };
}

export function mapToPaymentScheduleViewModel(
  schedule: PaymentSchedule[]
): PaymentScheduleViewModel[] {
  if (!schedule) {
    return [];
  }

  return schedule.map((paymentSchedule) => ({
    date: new Date(paymentSchedule.date),
    amount: `£${paymentSchedule.amount}`,
  }));
}

export function mapToActiveRepairDetailViewModel(
  apiResponse: PlanDetail
): ActiveClaimDetailViewModel {
  if (
    apiResponse.open_repair_id &&
    apiResponse.claims[apiResponse.open_repair_id].history?.length
  ) {
    return apiResponse.claims[apiResponse.open_repair_id].history
      .map((activeClaimHistory) => ({
        reference: apiResponse.open_repair_id,
        date: formatDateToIsoFormat(activeClaimHistory.date),
        status: apiResponse.open_repair_status || activeClaimHistory.status,
        rebookable: apiResponse.open_repair_rebookable,
        cancelable: apiResponse.open_repair_cancelable,
      }))
      .sort((a, b) => {
        return <any>b.date - <any>a.date; // Needed because of Typescript compiler complaining
      })[0];
  }
  return null;
}

export function mapToActiveServiceDetailViewModel(
  apiResponse: PlanDetail
): ActiveClaimDetailViewModel {
  if (
    apiResponse.open_service_id &&
    apiResponse.claims[apiResponse.open_service_id].history?.length
  ) {
    return apiResponse.claims[apiResponse.open_service_id].history
      .map((activeClaimHistory) => ({
        reference: apiResponse.open_service_id,
        date: formatDateToIsoFormat(activeClaimHistory.date),
        status: apiResponse.open_service_status || activeClaimHistory.status,
        rebookable: apiResponse.open_service_rebookable,
        additionalInformation: null,
      }))
      .sort((a, b) => {
        return <any>b.date - <any>a.date; // Needed because of Typescript compiler complaining
      })[0];
  }
  return null;
}

function formatDateToIsoFormat(date: string): Date {
  // We are getting dates as "2020-06-08 18:55:03". This is causing issue in Safari, so we are changing it to
  // "2020-06-08T18:55:03"
  return new Date(date.replace(' ', 'T'));
}

export function mapToItemViewModel(apiResponse: PlanDetail): ItemViewModel {
  return apiResponse.item;
}

export function mapToRepairerDetailsViewModel(
  apiResponse: PlanDetail
): RepairerDetailsViewModel {
  if (
    apiResponse.open_repair_id &&
    apiResponse.claims[apiResponse.open_repair_id] &&
    apiResponse.claims[apiResponse.open_repair_id].repairer
  ) {
    return {
      repairer: apiResponse.claims[apiResponse.open_repair_id].repairer.name,
      logo: apiResponse.claims[apiResponse.open_repair_id].repairer.logo,
      phone:
        apiResponse.claims[apiResponse.open_repair_id].repairer.phone_number,
      email: apiResponse.claims[apiResponse.open_repair_id].repairer.email,
    };
  }
  return null;
}

export function formatSortCode(sortCode: string) {
  sortCode = (sortCode || '').trim();
  if (sortCode.length === 6) {
    return sortCode.trim().replace(/^(\d{2})(\d{2})(\d{2})$/, '$1-$2-$3');
  }

  return sortCode;
}

export function formatAccountNumber(accountNumber: string) {
  accountNumber = (accountNumber || '').trim();
  if (accountNumber.length > 3) {
    return accountNumber.replace(/^(.*?)(\d{3})$/, '*****$2');
  }
  return accountNumber;
}
