import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ErrorHandler,
  InjectionToken,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import {
  AppConfig,
  AuthHeadersInterceptor,
  CONFIG,
  CorrelationIdRequestTrackerInterceptor,
  Environment,
  ENVIRONMENT,
  GlobalErrorHandler,
  RuntimeConfig,
  RUNTIME_CONFIG,
} from '@athome-myaccount/my-account/util-core';

const configFactory = (
  runtimeConfig: RuntimeConfig,
  environment: Environment
): AppConfig => {
  return { ...environment, ...runtimeConfig };
};

const environmentFactory = (environment: Environment): Environment => {
  console.log('environment', environment);
  return environment;
};
@NgModule({
  imports: [CommonModule],
})
export class CoreModule {
  static forRoot(
    token: InjectionToken<Environment>
  ): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHeadersInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CorrelationIdRequestTrackerInterceptor,
          multi: true,
        },
        { provide: ENVIRONMENT, useFactory: environmentFactory, deps: [token] },
        {
          provide: CONFIG,
          useFactory: configFactory,
          deps: [RUNTIME_CONFIG, ENVIRONMENT],
        },
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
      ],
    };
  }
}
