import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VerificationCodeFailure } from '../+state/auth.actions';
import { WITH_CREDENTIALS_HTTP_API_CALL_HEADERS } from '../common/constants/http-headers.const';
import { AuthenticationApiEnum } from '../common/enums/authentication-api.enum';
import {
  CreateAccountPayload,
  LoginPayload,
  RequestPasswordPayload,
  ResetPasswordPayload,
  RequestVerificationCodePayload,
  VerificationCodePayload,
} from '../common/models/payload';
import {
  ResetPasswordErrorResponse,
  ResetPasswordSuccessResponse,
} from '../common/models/response';
import { AUTH_URLS_CONFIG_JSON } from '../common/tokens/auth-config-token';

@Injectable()
export class AuthenticationApiService {
  private rootUrl: string;
  private jsonConfig: any;

  // flag injection is mobile
  constructor(
    private http: HttpClient,
    @Inject(AUTH_URLS_CONFIG_JSON) private jsonConfigPath: string
  ) {
    this.getAuthenticationApiUrl().then((rootUrl: string) => {
      this.rootUrl = rootUrl;
    });
  }

  private async getAuthenticationApiUrl(): Promise<string> {
    const conf = await this.getConfig();
    const rootUrl = `${conf.identityApiUrl}/${conf.identityApiUrlVersion}`;
    return Promise.resolve(rootUrl);
  }

  private async getConfig(): Promise<any> {
    if (!this.jsonConfig) {
      this.jsonConfig = await this.http.get(this.jsonConfigPath).toPromise();
    }
    return Promise.resolve(this.jsonConfig);
  }

  public createAccount(payload: CreateAccountPayload): Observable<any> {
    return this.http
      .post(
        this.rootUrl + AuthenticationApiEnum.CREATE_ACCOUNT_URL,
        payload,
        WITH_CREDENTIALS_HTTP_API_CALL_HEADERS
      )
      .pipe(map((response: any) => response));
  }

  public login(payload: LoginPayload): Observable<any> {
    return this.http
      .post(this.rootUrl + AuthenticationApiEnum.LOGIN_USER_URL, payload, {
        observe: 'response',
        withCredentials: true,
      })
      .pipe(map((response: any) => response));
  }

  public logout(): Observable<any> {
    return this.http
      .post(
        this.rootUrl + AuthenticationApiEnum.SIGNOUT,
        null,
        WITH_CREDENTIALS_HTTP_API_CALL_HEADERS
      )
      .pipe(map((response: any) => response));
  }

  public requestNewPassword(payload: RequestPasswordPayload): Observable<any> {
    return this.http
      .post(
        this.rootUrl + AuthenticationApiEnum.REQUEST_PASSWORD_URL,
        payload,
        WITH_CREDENTIALS_HTTP_API_CALL_HEADERS
      )
      .pipe(map((response: any) => response));
  }

  public resetPassword(
    payload: ResetPasswordPayload
  ): Observable<ResetPasswordSuccessResponse | ResetPasswordErrorResponse> {
    return this.http
      .post(
        this.rootUrl + AuthenticationApiEnum.RESET_PASSWORD_URL,
        payload,
        WITH_CREDENTIALS_HTTP_API_CALL_HEADERS
      )
      .pipe(map((response: any) => response));
  }

  public requestVerificationCode(
    payload: RequestVerificationCodePayload
  ): Observable<any> {
    return this.http
      .post(
        this.rootUrl + AuthenticationApiEnum.REQUEST_VERIFICATION_CODE_URL,
        payload,
        WITH_CREDENTIALS_HTTP_API_CALL_HEADERS
      )
      .pipe(map((response: any) => response));
  }

  public processVerificationCode(
    payload: VerificationCodePayload
  ): Observable<any> {
    return this.http
      .post(
        this.rootUrl + AuthenticationApiEnum.VERIFICATION_CODE_URL,
        payload,
        {
          observe: 'response',
          withCredentials: true,
        }
      )
      .pipe(
        map((response: any) => response),
        catchError((error: HttpErrorResponse) =>
          of(new VerificationCodeFailure(error))
        )
      );
  }
}
