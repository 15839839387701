import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig, CONFIG, Environment, ENVIRONMENT } from '../../index';

enum HeaderNames {
  REQUEST_SOURCE = 'request-source',
  REQUEST_ACTION = 'request-action',
  CLIENT = 'wl-client',
}
@Injectable()
export class AuthHeadersInterceptor implements HttpInterceptor {
  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(CONFIG) private readonly config: AppConfig
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.startsWith(this.config.api) ||
      request.url.startsWith(this.config.commonPersonServiceUrl)
    ) {
      request = request.clone({
        setHeaders: {
          [HeaderNames.REQUEST_SOURCE]:
            this.environment.requestSource || 'DandGUK',
          [HeaderNames.REQUEST_ACTION]:
            this.environment.requestAction || 'MyAccount',
          [HeaderNames.CLIENT]: this.environment.wlClient || 'DandGUK',
        },
      });
    }

    return next.handle(request); // TODO: Error handling? Skipping some URLs?
  }
}
