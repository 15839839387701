export const APPLIANCES_ICONS = [
  '3D Plasma TV icon outlined-01.png',
  '3D TV icon outlined-01.png',
  'Air con unit icon outlined-01.png',
  'American fridge freezer icon outlined-01.png',
  'Blu-ray player icon outlined-01.png',
  'Boiler control icon outlined-01.png',
  'Boiler icon outlined-01.png',
  'Camcorder icon outlined-01.png',
  'Camera icon outlined-01.png',
  'Camera lense icon outlined-01.png',
  'Camera SLR icon outlined-01.png',
  'Car radio icon outlined-01.png',
  'CD-DVD disk icon outlined-01.png',
  'CD-DVD writer icon outlined-01.png',
  'Ceramic hob icon outlined-01.png',
  'Coffee maker icon outlined-01.png',
  'Combi microwave icon outlined-01.png',
  'Cooker hood 01 icon outlined-01.png',
  'Cooker hood 02 icon outlined-01.png',
  'Cooker icon outlined-01.png',
  'Digital Catalogue outlined-01.png',
  'Dishwasher icon outlined-01.png',
  'Double oven icon outlined-01.png',
  'Drill icon outlined-01.png',
  'Dryer icon outlined-01.png',
  'DVD player icon outlined-01.png',
  'E-Reader icon outlined-01.png',
  'Fax machine icon outlined-01.png',
  'Food mixer icon outlined-01.png',
  'Freezer icon outlined-01.png',
  'Fridge icon outlined-01.png',
  'Fridge-freezer icon outlined-01.png',
  'Frost-free freezer icon outlined-01.png',
  'Frost-free fridge-freezer icon outlined-01.png',
  'Games console 02 icon outlined-01.png',
  'Games console 03 icon outlined-01.png',
  'Games console icon outlined-01.png',
  'Games console portable icon outlined-01.png',
  'Gas hob icon outlined-01.png',
  'Hairdryer icon outlined-01.png',
  'Headphones console icon outlined-01.png',
  'Headphones icon outlined-01.png',
  'Hi-fi components icon outlined-01.png',
  'Hi-fi icon outlined-01.png',
  'Hob+hood icon outlined-01.png',
  'Home theatre blu-ray icon outlined-01.png',
  'Home theatre DVD icon outlined-01.png',
  'Home theatre system icon-01.png',
  'IPod-MP3 icon outlined-01.png',
  'Iron icon outlined-01.png',
  'Laptop icon outlined-01.png',
  'Lawnmower icon outlined-01.png',
  'LDC TV icon outlined-01.png',
  'LED TV icon outlined-01.png',
  'Microwave icon outlined-01.png',
  'Mobile phone icon outlined-01.png',
  'Mobile phone ringing icon outlined-01.png',
  'Monitor icon outlined-01.png',
  'Netbook icon outlined-01.png',
  'Oven icon outlined-01.png',
  'Oven+hob+hood icon outlined-01.png',
  'PC accessories icon outlined-01.png',
  'PC icon outlined-01.png',
  'Pipework icon outlined_85-01.png',
  'Plasma TV icon outlined-01.png',
  'Plumbing and drainage cover.png',
  'Portable audio player icon outlined-01.png',
  'Printer icon outlined-01.png',
  'Projection TV icon outlined-01.png',
  'Projector icon outlined-01.png',
  'Radiator icon outlined-01.png',
  'Range cooker icon outlined-01.png',
  'Sat nav icon outlined-01.png',
  'Satellite dish icon outlined-01.png',
  'Satellite receiver icon outlined-01.png',
  'Scanner icon outlined-01.png',
  'Set top box HD icon outlined-01.png',
  'Set top box icon outlined-01.png',
  'Shaver icon outlined-01.png',
  'Shower head icon outlined-01.png',
  'Shredder icon outlined-01.png',
  'Slide projector icon outlined-01.png',
  'Solar panel icon outlined-01.png',
  'Spin dryer icon outlined-01.png',
  'Tablet icon outlined-01.png',
  'Tablet-SIM icon outlined-01.png',
  'Telephone handset icon outlined-01.png',
  'TV icon outlined-01.png',
  'TV-video recorder icon outlined-01.png',
  'USB pen icon outlined-01.png',
  'Vacuum cleaner icon outlined-01.png',
  'Video recorder icon outlined-01.png',
  'Washer dryer icon outlined-01.png',
  'Washing machine icon outlined-01.png',
  'Washing machine top loader icon-01.png',
  'Wine cooler icon outlined-01.png',
  'SKY icon outlined-01.png',
];

export const APPLIANCES_CODE_IMAGES: Record<string, string> = {
  AW: 'AW_Washing-machine.png',
  LY: 'LY_Led-Television.png',
  FF: 'FF_Fridge-freezer.png',
  CN: 'CN_American-fridge-freezer.png',
  DW: 'DW_Dishwasher.png',
  DO: 'DO_Double-oven.png',
  TD: 'TD_Tumble-dryer.png',
  CK: 'CK_Cooker.png',
  OV: 'OV_Oven.png',
  HB: 'HB_Hob.png',
  HE: 'HE_Ceramic-hob.png',
  WD: 'WD_Washer-Dryer.png',
  FZ: 'FZ_Freezer.png',
  UT: 'UT_Lcd-Television.png',
  LT: 'LT_Laptop.png',
  FR: 'FR_Fridge.png',
  CL: 'CL_Vacuum-Cleaner.png',
  GK: 'GK_Games-Console.png',
  S8: 'S8_Smart-Tv.png',
  CP: 'CP_Mobile-Phone.png',
  MW: 'MW_Microwave-Oven.png',
  CH: 'CH_Cooker-Hood.png',
  I4: 'I4_iPad.png',
  T5: 'T5_Tablet-Computer.png',
  E3: 'E3_Conventional-Boiler-platinum.png',
  DI: 'DI_Digital-Camera.png',
  LL: 'LL_Earphones-Headphones.png',
  QA: 'QA_Digital-Satellite-System.png',
  RG: 'RG_Plasma-Flat-Screen-Television.png',
  CT: 'CT_Television.png',
  AP: 'AP_Audio-System.png',
  RM: 'RM_Dab-radio.png',
  F0: 'F0_Food-Preparation.png',
  ES: 'ES_Electric-Shower.png',
  HC: 'HC_Personal-Computer.png',
  ME: 'ME_Coffee-Machine.png',
  DM: 'DM_Dehumidifier-Fan.png',
  'F&': 'F&_Fitness-Tracker.png',
  VL: 'VL_Digital-Camcorder.png',
  QP: 'QP_Printer.png',
  '3D': '3D_3d-Television.png',
  AV: 'AV_Home-Entertainment-System.png',
  LE: 'LE_Camera-Lens.png',
  WQ: 'WQ_Wine-Cooler.png',
  GF: 'GF_Gas-Fire.png',
  IR: 'IR_Iron.png',
  LA: 'LA_Lawnmower.png',
  NS: 'NS_In-Car-Navigation-System.png',
  AC: 'AC_Portable-Air-Conditioner.png',
  SM: 'SM_Sewing-Machine.png',
  IO: 'IO_iPod.png',
  QM: 'QM_Computer-Monitor.png',
  ND: 'ND_Dvd-And-Video-Recorder-System.png',
  WR: 'WR_Pressure-Washer.png',
  YX: 'YX_Blu-ray-Player-Recorder.png',
  RL: 'RL_Hairdryer.png',
  QK: 'QK-Sky-Q.png',
  QU: 'QU-Sky-HD.png',
  'C>': 'CP_Mobile-Phone.png',
  U2: 'U2_PlumbingAndDrainage.png',
  X7: 'U2_PlumbingAndDrainage.png',
};
