<div
  class="wrapper"
  *ngIf="showLength"
  [ngClass]="{
    valid: passwordValidator.passwordLength,
    invalid: !passwordValidator.passwordLength,
    submitted: submitted
  }"
>
  <span>
    <i
      class="icon"
      [ngClass]="{
        'icon-tick-circle': passwordValidator.passwordLength,
        'icon-error-circle': !passwordValidator.passwordLength && submitted
      }"
    ></i>
  </span>
  <small> {{ translations.inputLength }} </small>
</div>

<div
  class="wrapper"
  *ngIf="showUppercase"
  [ngClass]="{
    valid: passwordValidator.passwordUpperCase,
    invalid: !passwordValidator.passwordUpperCase,
    submitted: submitted
  }"
>
  <span>
    <i
      class="icon"
      [ngClass]="{
        'icon-tick-circle': passwordValidator.passwordUpperCase,
        'icon-error-circle': !passwordValidator.passwordUpperCase && submitted
      }"
    ></i>
  </span>
  <small> {{ translations.inputUpperCase }}</small>
</div>

<div
  class="wrapper"
  *ngIf="showLowercase"
  [ngClass]="{
    valid: passwordValidator.passwordLowerCase,
    invalid: !passwordValidator.passwordLowerCase,
    submitted: submitted
  }"
>
  <span>
    <i
      class="icon"
      [ngClass]="{
        'icon-tick-circle': passwordValidator.passwordLowerCase,
        'icon-error-circle': !passwordValidator.passwordLowerCase && submitted
      }"
    ></i>
  </span>
  <small> {{ translations.inputLowerCase }}</small>
</div>

<div
  class="wrapper"
  *ngIf="showNumber"
  [ngClass]="{
    valid: passwordValidator.passwordNumber,
    invalid: !passwordValidator.passwordNumber,
    submitted: submitted
  }"
>
  <span>
    <i
      class="icon"
      [ngClass]="{
        'icon-tick-circle': passwordValidator.passwordNumber,
        'icon-error-circle': !passwordValidator.passwordNumber && submitted
      }"
    ></i>
  </span>
  <small> {{ translations.inputNumber }}</small>
</div>

<div
  class="wrapper"
  *ngIf="showComplex"
  [ngClass]="{
    valid: passwordValidator.passwordComplexCase,
    invalid: !passwordValidator.passwordComplexCase,
    submitted: submitted
  }"
>
  <span>
    <i
      class="icon"
      [ngClass]="{
        'icon-tick-circle': passwordValidator.passwordComplexCase,
        'icon-error-circle': !passwordValidator.passwordComplexCase && submitted
      }"
    ></i>
  </span>
  <small> {{ translations.inputComplex }}</small>
</div>
