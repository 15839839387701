import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, skipWhile, takeWhile } from 'rxjs/operators';
import { AuthLibFacade } from '../../+state/auth.facade';
import { ApiResponseTypeEnum } from '../../common/enums/api-response-type.enum';
import { ResetPasswordPayload } from '../../common/models/payload';
@Component({
  selector: 'athome-myaccount-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  showValidation = false;
  isComponentAlive = true;
  passwordResetForm: FormGroup;
  fieldTextType: boolean;
  submitted = false;
  public confirmationCode: string;
  public customerEmail: string;
  public payload: ResetPasswordPayload;
  public resetPasswordResponse$: Observable<any> =
    this.facade.resetPasswordResponse$;
  loginResponse$: Observable<ApiResponseTypeEnum> = this.facade.logInResponse$;
  @Output() resetPasswordResponseSuccess = new EventEmitter();
  @Output() resetPasswordResponseError = new EventEmitter<any>();
  @Output() formValueChange = new EventEmitter<any>();
  passwordError: any;
  passwordValue: string;
  passwordStrengthChecker = {
    showLength: true,
    showUppercase: true,
    showLowercase: true,
    showNumber: true,
    showComplex: true,
  };

  constructor(
    public fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private facade: AuthLibFacade
  ) {}

  ngOnInit() {
    this.onQueryParams();
    this.onLoginResponse();

    this.initForm();
    this.onFormValueChange();
    this.onResetPasswordResponse();
  }

  initForm(): void {
    this.passwordResetForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,128}$'),
        ],
      ],
    });

    this.passwordResetForm.controls['password'].valueChanges.subscribe(
      (v: string) => {
        this.passwordError = this.passwordResetForm.controls['password'].valid;
        this.passwordValue = v;
      }
    );
  }

  handleFocusOutEvent(controlName): void {
    if (
      this.passwordResetForm.get(controlName).invalid ||
      this.passwordResetForm.get(controlName).touched ||
      this.passwordResetForm.get(controlName).dirty
    ) {
      this.showValidation = true;
      this.passwordResetForm.get(controlName).markAsTouched();
    } else {
      this.showValidation = false;
    }
  }

  onFormValueChange(): void {
    this.passwordResetForm.valueChanges
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .subscribe((data) => {
        this.formValueChange.emit(data);
      });
  }

  get f(): any {
    return this.passwordResetForm.controls;
  }

  toggleFieldTextType(): void {
    this.fieldTextType = !this.fieldTextType;
  }

  onResetPasswordResponse(): void {
    combineLatest([this.resetPasswordResponse$, this.facade.error$])
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .pipe(
        skipWhile(
          ([responseStatus, error]: [ApiResponseTypeEnum, Error]) =>
            responseStatus === ApiResponseTypeEnum.NOT_CALLED
        )
      )
      .subscribe(([responseStatus, error]: [ApiResponseTypeEnum, Error]) => {
        if (responseStatus === ApiResponseTypeEnum.SUCCESS) {
          this.facade.resetAuthState();
          this.facade.logInUser({
            email: this.payload.email,
            password: this.payload.password,
          });
        }
        if (responseStatus === ApiResponseTypeEnum.ERROR && error) {
          this.resetPasswordResponseError.emit(error);
        }
      });
  }

  onLoginResponse(): void {
    combineLatest([this.loginResponse$, this.facade.error$])
      .pipe(takeWhile(() => this.isComponentAlive))
      .pipe(distinctUntilChanged())
      .pipe(
        skipWhile(
          ([responseStatus, error]: [ApiResponseTypeEnum, Error]) =>
            responseStatus === ApiResponseTypeEnum.NOT_CALLED
        )
      )
      .subscribe(([responseStatus, error]: [ApiResponseTypeEnum, Error]) => {
        if (responseStatus === ApiResponseTypeEnum.SUCCESS) {
          this.resetPasswordResponseSuccess.emit();
        }
        if (responseStatus === ApiResponseTypeEnum.ERROR && error) {
          this.resetPasswordResponseError.emit(error);
        }
      });
  }

  public onSubmit() {
    this.submitted = true;

    if (this.passwordResetForm.valid) {
      const resetPasswordPayload: ResetPasswordPayload = (this.payload = {
        password: this.passwordResetForm.get('password').value,
        ...this.payload,
      });

      this.facade.requestResetPasswordForUser({
        ...resetPasswordPayload,
      });
    } else {
      this.passwordResetForm.markAllAsTouched();
    }
  }
  onQueryParams(): void {
    this._route.queryParams
      .pipe(takeWhile(() => this.isComponentAlive))
      .subscribe((params) => {
        this.payload = {
          email: params.email,
          confirmation_code: params.confirmation_code,
          is_sign_up: params.is_sign_up?.toLowerCase() === 'true',
        };
      });
  }

  ngOnDestroy(): void {
    this.isComponentAlive = false;
  }
}
