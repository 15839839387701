<app-svg></app-svg>
<div class="main-container" fxLayout="column">
  <dgx-ui-wl-header
    *ngIf="(isFromMobile$ | async) === false"
    [navigationLinks]="config.headerLinksBuilder(config.homepageAppHost)"
    [isMyAccountLayout]="true"
    [isLoggedIn]="isUserStatusComplete()"
    (logout)="logout()"
    clientLogoPath="assets/images/ao-logo.svg"
  ></dgx-ui-wl-header>

  <app-loader *ngIf="loading"></app-loader>
  <router-outlet></router-outlet>

  <app-ui-modal></app-ui-modal>

  <dgx-ui-wl-ao-footer
    *ngIf="(isFromMobile$ | async) === false"
    [protectLinks]="footerLinks.protectLinks"
    [legalLinks]="footerLinks.legalLinks"
    [usefulLinks]="footerLinks.usefulLinks"
  >
  </dgx-ui-wl-ao-footer>
</div>
