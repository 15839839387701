export enum LoginRoutesEnum {
  LOGIN_PAGE = '/my-account',
  VERIFY_EMAIL_PAGE = '/my-account/verify-email',
  VERIFY_EMAIL_REGISTRATION = 'my-account/verify-email?registration',
  VERIFY_PLAN_NUMBER_PAGE = 'my-account/verification/plan-number',
  MIGRATED_USER_PAGE = 'my-account/migrated-user',
  CREATE_ACCOUNT_PAGE = '/my-account/create-account',
  FORGOT_PASSWORD_PAGE = '/my-account/forgot-password',
  CREATE_NEW_ACCOUNT_PAGE = '/my-account/new-account',
  CAPTURE_PERSONAL_DETAILS = '/my-account/capture-personal-details',
}

export enum AccountRoutesEnum {
  DASHBOARD = '/my-plans',
}
