import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ForgotPasswordComponent } from './forgot-password.component';

@NgModule({
  declarations: [ForgotPasswordComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [ForgotPasswordComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ForgotPasswordModule {}
